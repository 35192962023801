<template>
  <BasicMenu position="left">
    <template #icon>
      <EllipsisVerticalIcon class="h-8 w-8" aria-hidden="true" />
    </template>
    <template #content="{ item }">
      <component :is="item" v-slot="{ active }" @click="isAddOrganizationModalOpen = true">
        <div
          :class="[
            active ? 'bg-gray-100 text-red-600' : 'text-gray-700',
            'px-4 py-2 cursor-pointer whitespace-nowrap flex items-center',
          ]"
        >
          <PlusIcon class="h-4 w-4 mr-1" />
          <span>
            {{ t("organizations.add_organization") }}
          </span>
        </div>
      </component>
      <component
        :is="item"
        v-slot="{ active }"
        v-if="selectedOrganization"
        @click="handleDeleteOrganizationClick"
      >
        <div
          :class="[
            active ? 'bg-gray-100 text-red-600' : 'text-gray-700',
            'px-4 py-2 cursor-pointer whitespace-nowrap flex items-center',
          ]"
        >
          <TrashIcon class="h-4 w-4 mr-1" />
          <span>
            {{ t("organizations.delete_organization") }}
          </span>
        </div>
      </component>
    </template>
  </BasicMenu>
  <AddOrganizationModal
    v-if="isAddOrganizationModalOpen"
    :organization="selectedOrganization"
    @close="isAddOrganizationModalOpen = false"
    @selectOrganization="emit('selectOrganization', $event)"
  />
</template>

<script setup lang="ts">
import { PlusIcon, TrashIcon } from "@heroicons/vue/24/outline";
import { EllipsisVerticalIcon } from "@heroicons/vue/24/solid";
import { defineProps, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useDeleteOrganization } from "shared/composables/organization";
import { useConfirmationModal } from "shared/composables/toast";
import { Organization } from "shared/types/Organization";
import BasicMenu from "@/components/other/BasicMenu.vue";
import AddOrganizationModal from "@/views/organizations/components/AddOrganizationModal.vue";

const props = defineProps<{
  selectedOrganization: Organization | undefined;
}>();

const emit = defineEmits<{
  (eventName: "selectOrganization", organizationId: string): void;
}>();

const { t } = useI18n();

const isAddOrganizationModalOpen = ref(false);

const { deleteOrganization } = useDeleteOrganization();
const showConfirmationModal = useConfirmationModal();

const handleDeleteOrganizationClick = () => {
  if (!props.selectedOrganization) {
    return;
  }
  showConfirmationModal({
    confirmAction: t("organizations.delete_confirmation.confirm"),
    cancelAction: t("organizations.delete_confirmation.cancel"),
    message: t("organizations.delete_confirmation.message", {
      name: props.selectedOrganization.name,
    }),
    header: t("organizations.delete_confirmation.header"),
  }).then((confirmed) => {
    if (confirmed && props.selectedOrganization) {
      deleteOrganization(props.selectedOrganization._id).catch(() => undefined);
    }
  });
};
</script>
