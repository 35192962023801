<template>
  <div
    ref="containerRef"
    class="relative flex flex-col"
    :style="{
      height: `${height}px`,
      padding: `${spacingY}px ${spacingX}px`,
      backgroundColor: plotColor?.backgroundColor,
    }"
  >
    <div
      class="text-center pl-6 pr-8 truncate shrink-0"
      :class="{ 'mb-1': !isOnTheDashboard }"
      :style="{ color: plotColor?.headerTextColor || '#111827' }"
    >
      <RouterLink
        class="text-lg font-semibold"
        :to="{
          name: 'Reports',
          query: {
            report: reportId,
          },
        }"
        @click.prevent="emit('widgetRouteClicked')"
      >
        {{ title }}
      </RouterLink>
    </div>
    <ReportFilterBadges
      type="query_value"
      :filters="filters"
      :aggregationLabel="
        !hideAggregationForMetric.includes(config.metric)
          ? t(`analytics.reports.query_value_aggregations.${aggregation}`)
          : undefined
      "
      :reportSummaryContext="reportSummaryContextOrFromSingleValueData"
      :class="{ oaiQueryValuePlotReportFilterBadgesOnTheDashboard: isOnTheDashboard }"
      :style="{ zIndex: 1 }"
      :hideDateFilter="!filterKeysByMetric[config.metric].includes('daterange')"
      :hideNonEmptyFilters="config.metric === 'unit_value'"
      :showPreviousPeriodBadge="config.show_previous_period"
    >
      <template #badges>
        <OaiTooltip v-if="unitValueContextLocation?.location">
          <div
            class="bg-gray-200 rounded-md px-2 py-0.5 whitespace-nowrap truncate"
            :style="{ fontSize: '11px' }"
          >
            {{ unitValueContextLocation.location }}
          </div>
          <template #tooltip>
            <div>
              {{ unitValueContextLocation.location }}
            </div>
          </template>
        </OaiTooltip>
      </template>
      <ArrowTopRightOnSquareIcon
        v-if="config.metric in linkCreators"
        class="w-5 h-5 cursor-pointer text-gray-600 shrink-0 mt-[1px]"
        @click="handleOpenInNewTabClick"
      />
      <MagnifyingGlassIcon
        v-if="!(config.metric in linkCreators) && processDetailMetrics.includes(config.metric)"
        class="w-5 h-5 cursor-pointer text-gray-600 shrink-0"
        :style="{ color: plotColor?.headerTextColor }"
        @click="
          isWorkingDaysCalendarModalOpen = true;
          trackEvent('activity-calendar_view', { origin: 'query_value_plot' });
        "
      />
      <MagnifyingGlassIcon
        v-if="
          !(config.metric in linkCreators) &&
          (config.metric === 'project_progress' || config.metric === 'velocity')
        "
        class="w-5 h-5 cursor-pointer text-gray-600 shrink-0"
        :style="{ color: plotColor?.headerTextColor }"
        @click="
          isProjectProgressModalOpen = true;
          trackEvent('progress_detail_click', { origin: 'query_value_progress' });
        "
      />
      <MagnifyingGlassIcon
        v-if="!(config.metric in linkCreators) && config.metric === 'delta_planner'"
        class="w-5 h-5 cursor-pointer text-gray-600 shrink-0"
        :style="{ color: plotColor?.headerTextColor }"
        @click="
          isCriticalPathModalOpen = true;
          trackEvent('progress_detail_click', { origin: 'query_value_delta' });
        "
      />
    </ReportFilterBadges>
    <QueryValuePlotValue
      :key="valueRefKey"
      v-if="singleValueData && !isLoading"
      :data="singleValueData"
      :filters="filters"
      :config="config"
      :textColor="plotColor?.textColor"
      :showFullUnit="!width || width > 450"
      :isOnTheDashboard="isOnTheDashboard"
      class="flex-1"
    />
    <Modal
      v-if="isWorkingDaysCalendarModalOpen"
      :open="true"
      @close="isWorkingDaysCalendarModalOpen = false"
      custom-cls="overflow-hidden"
    >
      <template #content>
        <WorkingDaysCalendar v-if="processes" :processes="processes" :interval="dateInterval" />
      </template>
    </Modal>
    <ProjectProgressModal
      v-if="isProjectProgressModalOpen && queryValueData.planConfig && processes"
      :planConfig="queryValueData.planConfig"
      :processes="processes"
      :initialLocationFilters="filters.location"
      @close="isProjectProgressModalOpen = false"
    />
    <CriticalPathModal
      v-if="isCriticalPathModalOpen"
      @close="isCriticalPathModalOpen = false"
      origin="query_value_widget"
    />
    <LoadingStateEmitter :isLoading="isLoading" />
  </div>
</template>

<script setup lang="ts">
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";
import { ArrowTopRightOnSquareIcon } from "@heroicons/vue/24/solid";
import { parse } from "date-fns";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import LoadingStateEmitter from "shared/components/loading_state/LoadingStateEmitter.vue";
import Modal from "shared/components/modals/Modal.vue";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { useDebouncedBoundingClientRect } from "shared/composables/screen";
import { useTrackEvent } from "shared/composables/tracking";
import CriticalPathModal from "shared/views/critical_path/components/CriticalPathModal.vue";
import { createHierarchyTagContext } from "shared/views/planner/hierarchyTags";
import WorkingDaysCalendar from "@/components/working_days/WorkingDaysCalendar.vue";
import { useOutagesByRange } from "@/composables/outages";
import { useProcessClasses } from "@/composables/process";
import { useHasProjectFeature } from "@/composables/project";
import { DashboardGridSize } from "@/types/Dashboard";
import { ReportSummaryContext } from "@/types/Report";
import {
  QueryValueAggregation,
  QueryValueContext,
  QueryValueFormattingRuleAction,
  QueryValueMetric,
  QueryValueReportConfig,
  QueryValueReportFilters,
  QueryValueSingleValue,
} from "@/types/reports/PlotQueryValue";
import ReportFilterBadges from "@/views/reports/filters/ReportFilterBadges.vue";
import ProjectProgressModal from "@/views/reports/plots/query_value/ProjectProgressModal.vue";
import QueryValuePlotValue from "@/views/reports/plots/query_value/QueryValuePlotValue.vue";
import { useQueryValueData } from "@/views/reports/plots/query_value/composables";
import { getUnitValueContextLocation } from "@/views/reports/plots/query_value/metrics/unitValueMetric";
import {
  createProcessTableLinkQuery,
  filterKeysByMetric,
  findMatchingFormattingRule,
  getFilteredAndSortedProcesses,
  getPreviousPeriodProcesses,
  getReportSummaryContextFromFilter,
  hideAggregationForMetric,
  processDetailMetrics,
} from "@/views/reports/plots/query_value/queryValue";
import calculateMetric from "./metrics/metrics";

const defaultFullHeight = 500;

const props = withDefaults(
  defineProps<{
    reportId: string;
    filters: QueryValueReportFilters;
    config: QueryValueReportConfig;
    aggregation: QueryValueAggregation;
    title: string;
    hidePopover?: boolean;
    width?: number;
    height?: number;
    hideLegend?: boolean;
    spacingX?: number;
    spacingY?: number;
    dashboardGridSize?: DashboardGridSize;
    isOnTheDashboard?: boolean;
  }>(),
  { height: defaultFullHeight, spacingX: 0, spacingY: 0 },
);

const emit = defineEmits<{
  (eventName: "updateUseWhiteIcons", payload: boolean): void;
  (eventName: "widgetRouteClicked"): void;
}>();

const { t } = useI18n();

const containerRef = ref<HTMLDivElement | null>(null);
const isWorkingDaysCalendarModalOpen = ref(false);
const isProjectProgressModalOpen = ref(false);
const isCriticalPathModalOpen = ref(false);
const fakeTimer = ref<ReturnType<typeof setTimeout> | null>(null);

const rect = useDebouncedBoundingClientRect(containerRef, 16);
const trackEvent = useTrackEvent();
const { queryValueData, isLoading: isQueryValueDataLoading } = useQueryValueData(
  computed(() => props.config.metric),
);
const router = useRouter();

const isLoading = computed(() => isQueryValueDataLoading.value || !!fakeTimer.value);

const hierarchyTagContext = computed(() =>
  createHierarchyTagContext(queryValueData.value.hierarchyTags),
);

const processes = computed(() =>
  getFilteredAndSortedProcesses(queryValueData.value, props.filters, props.config),
);

const reportSummaryContext = computed<ReportSummaryContext>(() => {
  if (props.filters.daterange) {
    return getReportSummaryContextFromFilter(
      props.filters.daterange,
      props.config,
      processes.value,
    );
  }
  return {
    start_date: null,
    end_date: null,
    previous_period_start_date: null,
    previous_period_end_date: null,
  };
});

const reportSummaryContextOrFromSingleValueData = computed(() => {
  if (singleValueData.value.reportSummaryContext) {
    return singleValueData.value.reportSummaryContext;
  }
  return reportSummaryContext.value;
});

const previousPeriodProcesses = computed(() =>
  getPreviousPeriodProcesses(
    queryValueData.value,
    props.filters,
    props.config,
    reportSummaryContext.value,
  ),
);

const processClasses = useProcessClasses();

const hasWorkingHoursFeature = useHasProjectFeature("working_hours");

const outagesByRangeInterval = computed(() => {
  if (processes.value) {
    const start =
      reportSummaryContext.value.previous_period_start_date ||
      reportSummaryContext.value.start_date;
    if (start && reportSummaryContext.value.end_date) {
      return {
        start,
        end: reportSummaryContext.value.end_date,
      };
    }
    const date = [...new Set(processes.value.map((process) => process.date))].sort()[0];
    if (date) {
      return {
        start: parse(date, "yyyy-MM-dd", new Date()),
        end: new Date(),
      };
    }
  }
  return null;
});

const { outagesByRange, isFetching: isOutagesByRangeFetching } =
  useOutagesByRange(outagesByRangeInterval);

const context = computed<QueryValueContext>(() => ({
  ...queryValueData.value,
  reportSummaryContext: reportSummaryContext.value,
  filters: props.filters,
  config: props.config,
  aggregation: props.aggregation,
  processes: processes.value,
  previousPeriodProcesses: previousPeriodProcesses.value,
  outagesByRange: isOutagesByRangeFetching.value ? undefined : outagesByRange.value,
  processClasses: processClasses.value,
  hasWorkingHoursFeature,
}));

const singleValueData = computed<QueryValueSingleValue>(() => {
  const result = calculateMetric(props.config.metric, context.value);
  if (!result) {
    return {
      value: null,
    };
  }
  return result;
});

const dateInterval: Ref<[Date, Date] | undefined> = computed(() => {
  if (!reportSummaryContext.value.start_date || !reportSummaryContext.value.end_date) {
    return undefined;
  }
  return [reportSummaryContext.value.start_date, reportSummaryContext.value.end_date];
});

const valueRefKey = computed(() =>
  JSON.stringify({
    rect: rect.value,
    data: singleValueData.value,
    config: props.config,
  }),
);

watch([() => props.filters, () => props.config, () => props.aggregation], () => {
  if (fakeTimer.value) {
    clearTimeout(fakeTimer.value);
  }
  fakeTimer.value = setTimeout(() => {
    fakeTimer.value = null;
  }, 200);
});

const formattingRuleActions: Record<
  QueryValueFormattingRuleAction,
  { backgroundColor: string; textColor: string; headerTextColor: string | undefined }
> = {
  red_background: { backgroundColor: "#F87171", textColor: "#fff", headerTextColor: "#fff" },
  yellow_background: { backgroundColor: "#E7BC66", textColor: "#fff", headerTextColor: "#fff" },
  green_background: { backgroundColor: "#56AF9C", textColor: "#fff", headerTextColor: "#fff" },
  red_foreground: { backgroundColor: "#fff", textColor: "#F87171", headerTextColor: undefined },
  yellow_foreground: { backgroundColor: "#fff", textColor: "#E7BC66", headerTextColor: undefined },
  green_foreground: { backgroundColor: "#fff", textColor: "#438F7F", headerTextColor: undefined },
};

const plotColor = computed(() => {
  if (!singleValueData.value) {
    return undefined;
  }
  const matchingRule = findMatchingFormattingRule(
    props.config.formatting_rules,
    singleValueData.value,
  );
  if (!matchingRule) {
    return undefined;
  }
  return formattingRuleActions[matchingRule.action];
});

const unitValueContextLocation = computed(() => {
  if (props.config.metric === "unit_value") {
    return getUnitValueContextLocation(singleValueData.value, hierarchyTagContext.value);
  }
  return null;
});

const updateUseWhiteIcons = () => {
  emit("updateUseWhiteIcons", !!plotColor?.value?.headerTextColor);
};

const linkCreators: Partial<Record<QueryValueMetric, () => string | null>> = {
  working_hours: () =>
    router.resolve({
      name: "ProcessesTable",
      query: createProcessTableLinkQuery(props.filters, reportSummaryContext.value),
    }).href,
  unit_value: () => router.resolve({ name: "UnitValuesEditor" }).href,
};

const handleOpenInNewTabClick = () => {
  const linkCreator = linkCreators[props.config.metric];
  const link = linkCreator && linkCreator();
  if (link) {
    window.open(link, "_blank");
  }
};

watch(plotColor, () => {
  updateUseWhiteIcons();
});

onMounted(() => {
  updateUseWhiteIcons();
});
</script>

<style>
.oaiQueryValuePlotReportFilterBadgesOnTheDashboard {
  display: none !important;
  position: absolute;
  top: 45px;
  left: 18px;
  right: 18px;
}

.vgl-item--resizable:hover .oaiQueryValuePlotReportFilterBadgesOnTheDashboard {
  display: flex !important;
}
</style>
