<template>
  <div ref="playerWrapper">
    <OaiVideoPlayer v-bind="playerProps" />
  </div>
</template>

<script setup lang="ts">
import * as fabric from "fabric";
import { v4 as uuid } from "uuid";
import { ref, onUnmounted, computed, watch } from "vue";
import OaiVideoPlayer from "shared/components/camera/OaiVideoPlayer.vue";
import { useSectionMasks } from "shared/composables/sectionMasks";
import { OaiVideoPlayerProps } from "shared/types/VideoPlayer";
import { drawMaskOnCanvas } from "@/services/sectionMaskCanvasService";

const props = defineProps<
  {
    sectionMaskId: string;
    processName: string;
  } & OaiVideoPlayerProps
>();

const playerProps = computed(() => {
  const { sectionMaskId, processName, ...rest } = props;

  return rest;
});

const { sectionMasks, error: sectionMaskError } = useSectionMasks();
const playerWrapper = ref<HTMLElement>();
const canvasInstance = ref<fabric.StaticCanvas>();
const componentId = `process-video-${uuid()}`;
let resizeObserver: ResizeObserver;
let interval: number;

const sectionMask = computed(() =>
  sectionMasks.value?.find((mask) => mask._id === props.sectionMaskId),
);
onUnmounted(() => clearSectionMaskOverlay());

const clearSectionMaskOverlay = () => {
  clearInterval(interval);

  if (canvasInstance.value) {
    canvasInstance.value.dispose();
  }

  if (resizeObserver) {
    resizeObserver.disconnect();
  }
};

const initSectionMaskCanvas = (videoElement: HTMLVideoElement) => {
  if (!sectionMask.value) {
    return;
  }

  canvasInstance.value = new fabric.StaticCanvas(componentId, {
    width: videoElement.clientWidth,
    height: videoElement.clientHeight,
  });

  drawMaskOnCanvas(canvasInstance.value, sectionMask.value, props.processName);
  resizeObserver = new ResizeObserver(handleResize);
  resizeObserver.observe(videoElement);
};

const handleResize = (entries: ResizeObserverEntry[]) => {
  const entry = entries[0];
  if (!canvasInstance.value) {
    return;
  }

  const objects = canvasInstance.value.getObjects();
  const videoElement = entry.target as HTMLVideoElement;
  const videoRatio = Math.round((videoElement.videoWidth / videoElement.videoHeight) * 100) / 100;
  const canvasRatio = Math.round((entry.contentRect.width / entry.contentRect.height) * 100) / 100;
  let videoElementWidth = entry.contentRect.width;
  let videoElementHeight = entry.contentRect.height;
  const oldWidth = canvasInstance.value.width;
  const oldHeight = canvasInstance.value.height;

  if (videoRatio > canvasRatio) {
    videoElementHeight = entry.contentRect.width / videoRatio;
  } else if (videoRatio < canvasRatio) {
    videoElementWidth = entry.contentRect.height * videoRatio;
  }

  const scaleX = videoElementWidth / oldWidth;
  const scaleY = videoElementHeight / oldHeight;

  objects.forEach((object) => {
    if (object instanceof fabric.Text) {
      object.set({
        left: videoElementWidth - object.width - 10,
      });

      return;
    }

    object.set({
      left: object.left * scaleX,
      top: object.top * scaleY,
      scaleX: object.scaleX * scaleX,
      scaleY: object.scaleY * scaleY,
    });
  });

  canvasInstance.value.setDimensions({
    width: videoElementWidth,
    height: videoElementHeight,
  });

  canvasInstance.value.renderAll();
};

watch(
  () => [props.src, props.loading],
  () => {
    clearSectionMaskOverlay();
    if (!props.src || props.loading) {
      return;
    }

    interval = setInterval(() => {
      const videoElement = playerWrapper.value?.querySelector("video");

      if (sectionMaskError.value) {
        clearSectionMaskOverlay();
        return;
      }

      if (!videoElement || !sectionMask.value) {
        return;
      }

      clearInterval(interval);

      const canvasElement = document.createElement("canvas");
      canvasElement.id = componentId;
      canvasElement.classList.add(
        "absolute",
        "pointer-events-none",
        "w-full",
        "h-full",
        "top-1/2",
        "left-1/2",
        "transform",
        "-translate-x-1/2",
        "-translate-y-1/2",
      );

      videoElement.parentElement?.insertBefore(
        canvasElement,
        videoElement.parentElement?.children[1],
      );

      videoElement.onloadeddata = () => initSectionMaskCanvas(videoElement);
    }, 100) as unknown as number;
  },
  { immediate: true },
);
</script>
