<template>
  <div
    :class="isFakeFullScreen ? 'fixed inset-0 bg-oaiGray-900' : ''"
    @click="isFakeFullScreen = false"
    :style="isFakeFullScreen ? 'z-index: 2147483004' : ''"
  >
    <div
      class="bg-oaiGray-900 video-js vjs-live vjs-4-3"
      :class="{
        'vjs-waiting': loading,
        'vjs-user-active': isUserActive,
        'vjs-user-inactive': !isUserActive,
        'vjs-has-started': mostRecentCameraImageUrl,
        'vjs-fullscreen': isFullScreen || isFakeFullScreen,
        'vjs-controls-enabled': showControls,
      }"
      @mouseenter="
        stopUserActiveInitialTimer();
        isUserActive = true;
      "
      @mouseleave="isUserActive = false"
      @mousemove="isUserActive = true"
      ref="containerRef"
    >
      <div class="image-content select-none" :style="full4to3RatioDimensionStyle">
        <img
          v-if="!hideImage"
          :src="mostRecentCameraImageUrl"
          alt="live image"
          class="h-full"
          @click="handleImageClick"
          @dblclick="handleFullScreenClick"
          @click.stop
          @error="hideImage = true"
        />
      </div>
      <div
        class="error-content"
        :style="`${full4to3RatioDimensionStyle}; height: auto;`"
        v-if="!isFakeFullScreen && !isFullScreen"
      >
        <div
          class="rounded-md bg-yellow-400/80 p-4 text-center text-xs sm:text-sm text-gray-50 flex-1 m-5"
        >
          {{ noSrcMessageText }}
        </div>
      </div>
      <div class="vjs-loading-spinner" v-if="loading" />
      <div
        class="vjs-control-bar pr-5 flex justify-end"
        v-if="showControls && !hideImage && isUserActive"
        @click.stop
      >
        <button
          class="vjs-fullscreen-control vjs-control vjs-button"
          type="button"
          :title="isFullScreen ? 'Non-Fullscreen' : 'Fullscreen'"
          aria-disabled="false"
          @click="handleFullScreenClick"
        >
          <span class="vjs-icon-placeholder" aria-hidden="true"></span
          ><span class="vjs-control-text" aria-live="polite">Fullscreen</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import screenfull from "screenfull";
import { computed, onMounted, onUnmounted, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useMostRecentCameraImageUrl } from "shared/composables/camera";
import {
  useFullScreen4to3RatioDimensionStyle,
  useFullWindow4to3RatioDimensionStyle,
} from "shared/composables/screen";

const loading = ref(false);
const isUserActive = ref(true);
const isFullScreen = ref(false);
const isFakeFullScreen = ref(false);
const containerRef = ref<HTMLDivElement | null>(null);
const userActiveInitialTimer = ref<ReturnType<typeof setTimeout> | null>(null);

const fullScreen4to3RatioDimensionStyle = useFullScreen4to3RatioDimensionStyle();
const fullWindow4to3RatioDimensionStyle = useFullWindow4to3RatioDimensionStyle();
const { t } = useI18n();

const props = defineProps({
  cameraId: {
    type: String,
    required: true,
  },
  showControls: {
    type: Boolean,
    required: false,
    default: true,
  },
  noSrcMessage: {
    type: String as PropType<string | null>,
    required: false,
    default: "",
  },
});

const camera = computed(() => props.cameraId);
const { mostRecentCameraImageUrl } = useMostRecentCameraImageUrl(camera);

const noSrcMessageText = computed(() => {
  return props.noSrcMessage ? props.noSrcMessage : t("video_player.unable_to_play_message");
});
const full4to3RatioDimensionStyle = computed(() => {
  if (isFakeFullScreen.value) {
    return fullWindow4to3RatioDimensionStyle.value;
  }
  if (isFullScreen.value) {
    return fullScreen4to3RatioDimensionStyle.value;
  }
  return "";
});

const handleImageClick = () => {
  if (!props.showControls) {
    return;
  }
};

const handleFullScreenClick = () => {
  if (!screenfull.isEnabled) {
    isFakeFullScreen.value = !isFakeFullScreen.value;
    return;
  }
  if (screenfull.isFullscreen) {
    screenfull.exit();
  } else if (containerRef.value) {
    screenfull.request(containerRef.value, { navigationUI: "hide" });
  }
};

const handleFullscreenChange = () => {
  isFullScreen.value = screenfull.isFullscreen;
};

const stopUserActiveInitialTimer = () => {
  if (userActiveInitialTimer.value !== null) {
    clearTimeout(userActiveInitialTimer.value);
  }
};

const hideImage = ref(false);

onMounted(() => {
  if (screenfull.isEnabled) {
    screenfull.on("change", handleFullscreenChange);
  }
});

onUnmounted(() => {
  if (screenfull.isEnabled) {
    screenfull.off("change", handleFullscreenChange);
  }
});
</script>

<style>
.image-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  aspect-ratio: 4/3;
}

.error-content {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  width: 100%;
}
</style>
