<template>
  <Modal :open="open" @close="!loading && $emit('canceled')" customCls="sm:w-1/4">
    <template #title>
      <p class="flex items-center">PDF Export</p>
      <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
    </template>
    <template #content>
      <div v-if="loading && open" class="h-64 flex items-center justify-center">
        <LoadingSpinner size="w-6 h-6" />
      </div>

      <div v-else-if="open">
        <div class="flex flex-col gap-3">
          <div class="w-full mb-3 text-center">
            <h3 class="pb-4 text-lg font-medium leading-6 text-gray-900">
              {{ $t("analytics.planner.events") }}
            </h3>
            <div class="flex justify-center">
              <RadioGroup v-model="rowsRange" class="mt-2">
                <div class="grid grid-cols-2 gap-8">
                  <RadioGroupOption
                    as="template"
                    v-for="option in data.rowsRangeList"
                    :key="option"
                    :value="option"
                    v-slot="{ checked }"
                  >
                    <div
                      :class="[
                        checked
                          ? 'relative border-yellow-600 text-yellow-600 hover:bg-yellow-600 hover:text-white'
                          : 'border-gray-200 bg-white text-gray-900 hover:bg-gray-50',
                        'focus:outline-non flex cursor-pointer items-center justify-center rounded-md border px-8 py-3 text-sm sm:flex-1',
                      ]"
                    >
                      <CheckCircleIcon
                        :class="[
                          !checked ? 'invisible' : '',
                          'absolute left-0 top-0 h-5 w-5 text-yellow-600',
                        ]"
                        aria-hidden="true"
                      />
                      <RadioGroupLabel as="span"
                        >{{ $t(`analytics.planner.${option}`) }}
                      </RadioGroupLabel>
                    </div>
                  </RadioGroupOption>
                </div>
              </RadioGroup>
            </div>
          </div>

          <div class="w-full border-t py-5 text-center">
            <h3 class="pb-4 text-lg font-medium leading-6 text-gray-900">
              {{ $t("analytics.planner.items") }}
            </h3>
            <div class="flex justify-center">
              <RadioGroup v-model="scheduleRange" class="mt-2">
                <div class="grid grid-cols-2 gap-8">
                  <RadioGroupOption
                    as="template"
                    v-for="option in data.scheduleRangeList"
                    :key="option"
                    :value="option"
                    v-slot="{ checked }"
                  >
                    <div
                      :class="[
                        checked
                          ? 'relative border-yellow-600 text-yellow-600 hover:bg-yellow-600 hover:text-white'
                          : 'border-gray-200 bg-white text-gray-900 hover:bg-gray-50',
                        'focus:outline-non flex cursor-pointer items-center justify-center rounded-md border px-8 py-3 text-sm sm:flex-1',
                      ]"
                    >
                      <CheckCircleIcon
                        :class="[
                          !checked ? 'invisible' : '',
                          'absolute left-0 top-0 h-5 w-5 text-yellow-600',
                        ]"
                        aria-hidden="true"
                      />
                      <RadioGroupLabel as="span"
                        >{{ $t(`analytics.planner.${option}`) }}
                      </RadioGroupLabel>
                    </div>
                  </RadioGroupOption>
                </div>
              </RadioGroup>
            </div>
          </div>
        </div>
        <div class="flex pt-4 gap-2 justify-end">
          <mainButton
            class="sm:-mr-0"
            :label="$t('buttons.cancel')"
            type="button"
            bordered="true"
            @clicked="$emit('canceled')"
          />

          <mainButton
            class="hover:bg-orange sm:-mr-0"
            :label="$t('buttons.export_plain')"
            type="button"
            color="yellow"
            @click="confirm"
          />
        </div>
      </div>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
import { CheckCircleIcon } from "@heroicons/vue/20/solid";
import { PropType, ref, watch } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import MainButton from "@/components/other/MainButton.vue";
import { SchedulerProPdfExportConfig } from "@/types/SchedulerPro";

const props = defineProps({
  open: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  loading: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const rowsRange = ref();
const scheduleRange = ref();

type FieldOptions = {
  rowsRangeList: ["all", "visible"];
  scheduleRangeList: ["completeview", "currentview"];
};

const data: FieldOptions = {
  rowsRangeList: ["all", "visible"],
  scheduleRangeList: ["completeview", "currentview"],
};

const emits = defineEmits(["canceled", "confirmed"]);

watch(
  () => props.open,
  (newValue) => {
    if (newValue) {
      rowsRange.value = "all";
      scheduleRange.value = "completeview";
    }
  },
);

const confirm = () => {
  emits("confirmed", {
    rowsRange: rowsRange.value,
    scheduleRange: scheduleRange.value,
  } as SchedulerProPdfExportConfig);
};
</script>

<style scoped>
label {
  width: 140px;
  text-align: left;
}
</style>
