<template>
  <Disclosure v-slot="{ open }" :defaultOpen="true">
    <DisclosureButton
      class="flex items-center py-1 pl-2 pr-3.5 h-[50px] bg-gray-100 border-b"
      :class="hideFirstTopBorder ? '' : 'border-t'"
      :style="{ paddingLeft: `${calculateIndentation(indentation)}px` }"
    >
      <ChevronRightIcon :class="{ 'rotate-90': open }" class="w-5 h-5 shrink-0" />
      <div class="font-bold text-gray-900 px-1 truncate">
        {{ organization.name }} ({{ organization.projects.length }})
      </div>
      <div class="flex-1" />
      <OrganizationLogo
        :organizationId="organization._id"
        cls="h-full border bg-white rounded p-[6px] min-w-0 shadow-md"
      />
    </DisclosureButton>
    <DisclosurePanel>
      <OrganizationTabProjectsList
        :organization="organization"
        :organizationsByParentId="organizationsByParentId"
        :projects="projects"
        :indentation="indentation + 1"
      />
      <div class="flex flex-col" v-if="children.length > 0">
        <OrganizationTabProjectsHierarchyItem
          v-for="childOrganization in children"
          :key="childOrganization._id"
          :organization="childOrganization"
          :organizationsByParentId="organizationsByParentId"
          :projectsByCustomerNameSiteId="projectsByCustomerNameSiteId"
          :indentation="indentation + 1"
        />
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { computed, defineProps } from "vue";
import { Organization } from "shared/types/Organization";
import { Project } from "shared/types/Project";
import OrganizationLogo from "@/views/organizations/components/OrganizationLogo.vue";
import OrganizationTabProjectsHierarchyItem from "@/views/organizations/components/OrganizationTabProjectsHierarchyItem.vue";
import OrganizationTabProjectsList from "@/views/organizations/components/OrganizationTabProjectsList.vue";
import { calculateIndentation } from "@/views/organizations/organizations";

const props = withDefaults(
  defineProps<{
    organization: Organization;
    organizationsByParentId: Record<string, Organization[] | undefined>;
    projectsByCustomerNameSiteId: Record<string, Project | undefined>;
    indentation?: number;
    hideFirstTopBorder?: boolean;
  }>(),
  { indentation: 0 },
);

const children = computed(() => {
  const items = props.organizationsByParentId[props.organization._id] || [];
  return items.slice().sort((a, b) => a.name.localeCompare(b.name));
});

const projects = props.organization.explicit_projects
  .map(
    (project) => props.projectsByCustomerNameSiteId[`${project.customer_name}_${project.site_id}`],
  )
  .filter((project) => project) as Project[];
</script>
