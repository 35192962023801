import {
  QueryValueContext,
  QueryValueMetric,
  QueryValueSingleValue,
} from "@/types/reports/PlotQueryValue";
import calculateActiveDaysMetric from "./activeDaysMetric";
import calculateDeltaPlannerMetric from "./deltaPlannerMetric";
import calculateInactiveDaysMetric from "./inactiveDaysMetric";
import calculateProductiveDaysMetric from "./productiveDaysMetric";
import calculateProjectProgressMetric from "./projectProgressMetric";
import calculateUnitValueMetric from "./unitValueMetric";
import calculateUtilizationMetric from "./utilizationMetric";
import calculateVelocityMetric from "./velocityMetric";
import calculateWorkingHoursMetric from "./workingHoursMetric";

const metricCalculators: Record<
  QueryValueMetric,
  (context: QueryValueContext) => QueryValueSingleValue | null
> = {
  active_days: calculateActiveDaysMetric,
  delta_planner: calculateDeltaPlannerMetric,
  inactive_days: calculateInactiveDaysMetric,
  productive_days: calculateProductiveDaysMetric,
  project_progress: calculateProjectProgressMetric,
  unit_value: calculateUnitValueMetric,
  utilization: calculateUtilizationMetric,
  working_hours: calculateWorkingHoursMetric,
  velocity: calculateVelocityMetric,
};

const calculateMetric = (metric: QueryValueMetric, context: QueryValueContext) =>
  metricCalculators[metric](context);

export default calculateMetric;
