<template>
  <div class="flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" :src="logoMultipleNoPaddingSrc" alt="Workflow" />
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
        <h2 class="text-center text-xl font-bold text-gray-700">
          {{ t("authentication.forgot_password.forgot_password") }}
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
          {{ t("authentication.forgot_password.forgot_text") }}
        </p>

        <form @submit.prevent="sendConfirmationCode" class="space-y-6 pt-6">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              {{ t("authentication.forgot_password.email") }}
            </label>
            <div class="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autocomplete="email"
                required
                v-model="email"
                class="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-oaiGray-100 focus:ring-oaiGray-100 sm:text-sm"
              />
            </div>
          </div>

          <div class="rounded-md bg-green-50 p-4 my-2 opacity-80" v-if="success">
            <div class="flex">
              <div class="shrink-0">
                <CheckCircleIcon class="h-5 w-5 text-green-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-green-800">
                  {{ t("authentication.forgot_password.success_message") }}
                </h3>
              </div>
            </div>
          </div>

          <div class="pt-2">
            <button
              type="submit"
              class="focus:outline-none flex w-full justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
            >
              {{ t("authentication.forgot_password.reset_password") }}
            </button>
          </div>
        </form>
        <div class="pt-4 text-center text-sm text-yellow-900">
          <router-link to="/log-in" class="font-sm hover:text-yellow-700">
            {{ t("authentication.forgot_password.back_to_login") }}
          </router-link>
        </div>
        <div class="pt-6" v-show="emailAlert">
          <div class="rounded-md bg-red-50 p-4">
            <div class="flex">
              <div class="shrink-0">
                <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">
                  {{ t("authentication.forgot_password.user_not_found") }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!props.hideFooter">
      <Footer />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/24/solid";
import { Auth } from "aws-amplify";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import logoMultipleNoPaddingSrc from "shared/assets/imgs/logo/logo-multiple-no-padding.svg";
import logger from "shared/services/logger";
import Footer from "shared/views/auth/components/Footer.vue";

const { t } = useI18n();

const props = defineProps<{
  hideFooter?: boolean;
}>();

const email = ref("");
const success = ref(false);
const emailAlert = ref(false);

const sendConfirmationCode = async () => {
  try {
    success.value = false;
    await Auth.forgotPassword(email.value, { "custom:origin": window.location.origin });
    success.value = true;
  } catch (error) {
    logger.error("Unable to call forgot password", error);
    emailAlert.value = !emailAlert.value;
  }
};
</script>
