<template>
  <OaiLiveVideoPlayer
    v-if="!useLiveImage && !videoError"
    :cameraId="props.cameraId"
    :addRightSpaceToControlBar="addRightSpaceToControlBar"
    @videoUrlError="videoError = true"
  />
  <OaiLiveImagePlayer
    :cameraId="props.cameraId"
    :noSrcMessage="routerConnectionLostMessage"
    v-else-if="useLiveImage && !videoError"
    :addRightSpaceToControlBar="addRightSpaceToControlBar"
    @lowBandWidthError="videoError = true"
  />
  <LiveConnectionLost v-else :cameraId="camera" :noSrcMessage="routerConnectionLostMessage" />
</template>

<script lang="ts" setup>
import { format, isBefore, subHours } from "date-fns";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import OaiLiveImagePlayer from "shared/components/camera/OaiLiveImagePlayer.vue";
import OaiLiveVideoPlayer from "shared/components/camera/OaiLiveVideoPlayer.vue";
import LiveConnectionLost from "@/components/live_video/LiveConnectionLost.vue";
import { useLiveImageSetting, useStreams } from "@/composables/stream";

const { useLiveImage } = useLiveImageSetting();
const camera = computed(() => props.cameraId);

const { streams } = useStreams();
const stream = computed(() => {
  return streams.value ? streams.value.find(({ camera_id }) => camera_id === camera.value) : null;
});

const props = defineProps({
  cameraId: {
    type: String,
    required: true,
  },
  addRightSpaceToControlBar: {
    type: Boolean,
    required: false,
  },
});

const videoError = ref(false);

const { t } = useI18n();
const emits = defineEmits(["videoHasError"]);

watch(videoError, () => {
  if (videoError.value) {
    emits("videoHasError");
  }
});

const routerConnectionLostMessage = computed(() => {
  if (
    stream.value &&
    stream.value.status === "offline" &&
    stream.value.status_updated_at &&
    isBefore(stream.value.status_updated_at, subHours(new Date(), 1))
  ) {
    if (videoError.value) {
      emits("videoHasError");
    }
    return `${t("dashboard.project.stream_card.connection_lost", {
      updatedAt: format(stream.value.status_updated_at, "dd.MM.yyyy HH:mm"),
    })}`;
  }
  return null;
});
</script>
