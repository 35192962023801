<template>
  <Sidebar>
    <LoadingOverlay v-if="exportLoading" />
    <div
      class="md:py-3 py-1 flex flex-col flex-wrap xl:flex-nowrap justify-center sm:items-center md:justify-between md:flex-row headroom header md:sticky md:top-0 md:z-10 px-6 border-b border-gray-100 bg-gray-50 h-max-screen"
    >
      <div class="md:w-1/3 flex items-center justify-center sm:justify-start gap-2">
        <router-link
          :to="{
            name: 'DailyReportOverview',
            params: {
              customer_name: $route.params.customer_name,
              site_id: $route.params.site_id,
            },
            query: {
              year: currentYear,
              month: currentMonth,
            },
          }"
          class="inline-flex text-orange items-center md:px-4 md:py-1.5 py-1 px-1.5 rounded-md font-bold border border-orange hover:bg-orange hover:text-white bg-gray-50 shadow"
        >
          <ArrowLongLeftIcon class="shrink-0 h-6" />
        </router-link>
        <button
          type="button"
          @click="exportPdf"
          class="animated-button border-orange text-orange hover:bg-orange hover:text-white rounded-md shadow-sm text-sm"
        >
          <ArrowDownTrayIcon class="w-full h-6 block m-0 box-border shrink-0" />
          <span>{{ $t("buttons.export_report") }}</span>
        </button>
        <OaiTooltip v-if="reportMeta && reportMeta.status !== 'NO_EDIT'" position="right">
          <InformationCircleIcon class="h-5 text-orange-500" />
          <template #tooltip>
            <div class="text-xs">
              <p>
                {{ $t("report.general.last_update") }}: {{ formatLastUpdated(reportMeta.updated) }}
              </p>
              <p>{{ $t("report.general.by") }}: {{ reportMeta.updated_by }}</p>
            </div>
          </template>
        </OaiTooltip>
      </div>
      <div id="dayPagination" class="md:w-1/3 flex justify-center items-center pt-2 pb-2 shrink-0">
        <div id="loadPriorDay">
          <button
            @click="reportPagination?.prior ? paginate(reportPagination.prior) : ''"
            :class="[
              'border-transparent inline-flex items-center px-4 py-2 text-sm font-medium text-oaiGray-300',
              { 'text-transparent': !reportPagination?.prior },
            ]"
          >
            <ChevronLeftIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <h2
          class="font-bold leading-7 text-gray-900 xl:text-3xl sm:text-lg sm:tracking-tight sm:truncate"
        >
          {{ formattedDate }}
        </h2>
        <div id="loadNextDay">
          <button
            :disabled="!reportPagination?.next"
            @click="reportPagination?.next ? paginate(reportPagination.next) : ''"
            :class="[
              'border-transparent inline-flex items-center px-4 py-2 text-sm font-medium text-oaiGray-300',
              { 'text-transparent': !reportPagination?.next },
            ]"
          >
            <ChevronRightIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div class="xl:w-1/3 xl:ml-4 justify-self-center order-2 sm:order-3 grow-1 shrink-1">
        <div class="flex items-center justify-center md:justify-end gap-2">
          <button
            type="button"
            class="animated-button hover:bg-oaiGray hover:text-white border-oaiGray text-oaiGray"
            v-if="!editMode"
            @click="edit()"
          >
            <PencilIcon v-if="!isApproved" class="w-full h-6 block px-2 m-0 box-border shrink-0" />
            <LockOpenIcon v-if="isApproved" class="w-full h-6 block px-2 m-0 box-border shrink-0" />
            <span>{{ $t("buttons.edit") }}</span>
          </button>
          <MainButton
            v-if="editMode"
            :label="$t('buttons.cancel')"
            class="sm:mr-0 mt-0 py-1"
            type="button"
            :bordered="true"
            @clicked="cancelEdit"
          />
          <MainButton
            :label="$t('buttons.save')"
            type="button"
            color="yellow"
            class="mx-0 sm:mr-0 mt-0"
            v-if="editMode"
            :isDisabled="!canSaveReport"
            @clicked="
              save();
              trackEvent('dcr_single_save_click');
            "
          >
            <LoadingSpinner v-if="isSubmitting" size="w-5 h-5" color="white" class="mr-2" />
          </MainButton>
          <button
            type="button"
            v-if="!isApproved && !loading"
            @click="approve()"
            :disabled="loading"
            class="animated-button hover:bg-green hover:text-white bg-green text-white"
          >
            <CheckIcon class="w-full h-6 block m-0 box-border shrink-0" />
            <span>{{ $t("buttons.approve") }}</span>
          </button>
        </div>
      </div>
    </div>
    <div :class="loading && 'hidden'">
      <div
        class="flex justify-between items-center sm:rounded-lg px-6 pt-2 max-w-screen-2xl mx-auto"
        v-if="projectMeta"
      >
        <div class="md:w-1/3 py-2 text-left">
          <h3 class="lg:text-lg text-sm leading-4 font-medium text-black">
            {{ projectMeta.name }}
          </h3>
          <p class="text-sm mt-0.5">
            {{ projectMeta.street }}<span v-show="loadedReport">,</span>
            {{ projectMeta.zip_code }}
          </p>
        </div>
        <div class="md:w-1/3 flex justify-center md:justify-end">
          <img v-if="logoUrl !== ''" class="h-10" :src="logoUrl" alt="Workflow" />
        </div>
      </div>
      <div class="p-2 sm:pb-5 sm:px-6 flex flex-col max-w-screen-2xl mx-auto">
        <div v-if="isApproved" class="text-sm mb-2 flex justify-end">
          <div class="bg-green-50/40 px-2 py-1 rounded-md" v-if="reportMeta">
            <CheckCircleIcon class="w-5 mr-1 text-green-400 inline-flex" />
            {{ $t("report.general.approved_by") }}: {{ reportMeta.updated_by }} ({{
              formatLastUpdated(reportMeta.updated)
            }})
          </div>
        </div>
        <Header
          v-if="reportData"
          ref="header"
          :date="$route.params.date"
          :siteDuration="reportData.site_duration"
          :editMode="editMode"
          :approved="isApproved"
          :highlightSection="highlightedSection"
          :validationError="validationError"
          @addHighlight="highlightedSection = 'header'"
          @activeEditMode="editMode = true"
          @updateSiteDuration="updateSiteDuration($event)"
        />

        <div id="sections-container">
          <div
            v-for="(value, section) in sectionsConfig"
            :key="section"
            :id="section"
            v-show="reportSettings && reportSettings.sections[section].visible"
          >
            <BaseSection
              v-if="section !== 'processes' && reportData && reportCustomFields"
              :ref="section"
              :customFields="reportCustomFields[section]"
              :sectionConfig="sectionsConfig[section]"
              :editMode="editMode"
              :highlightSection="highlightedSection"
              :entries="reportData[section]"
              :section="section"
              :approved="isApproved"
              :validationError="validationError"
              @activeEditMode="editMode = true"
              @addHighlight="highlightedSection = section"
              @addEntry="addSectionRowEntry"
              @removeEntry="removeSectionRowEntry"
              @updateComboBox="updateSectionComboBox"
              @updateFieldValue="updateSectionFieldValue"
              @addCustomField="handleAddCustomField"
              @deleteCustomField="handleDeleteCustomField"
              @updateSectionData="updateSectionData($event, section)"
            />
          </div>
          <div id="processes" v-show="reportSettings && reportSettings.sections.processes.visible">
            <GroupedProcesses
              ref="processes"
              v-if="reportData && reportCustomFields && reportSettings"
              :editMode="editMode"
              :highlightSection="highlightedSection"
              :customFields="reportCustomFields.processes"
              :processes="reportData.processes"
              :siteDuration="reportData.site_duration"
              :settings="reportSettings"
              :approved="isApproved"
              :oaiEntries="oaiProcesses"
              :validationError="validationError"
              :sectionConfig="sectionsConfig?.processes"
              @activeEditMode="editMode = true"
              @addHighlight="highlightedSection = 'processes'"
              @addProcessEntry="addSectionRowEntry"
              @removeProcessEntry="removeSectionRowEntry"
              @updateFieldValue="updateProcessFieldValue"
              @updateComboBox="updateProcessComboBox"
              @addCustomField="handleAddCustomField"
              @deleteCustomField="handleDeleteCustomField"
              @updateSectionData="updateSectionData($event, 'processes')"
            />
          </div>
          <div id="gallery" v-show="reportSettings && reportSettings.sections.gallery?.visible">
            <CustomGallery
              v-if="reportData"
              ref="gallery"
              :images="reportData.images"
              :editMode="editMode"
              :highlightSection="highlightedSection"
              @activeEditMode="editMode = true"
              @addHighlight="highlightedSection = 'gallery'"
              @updateGalleryImages="updateGalleryImages"
              @removeGalleryImage="removeGalleryImage"
              :approved="isApproved"
            />
          </div>
          <div id="notes" v-show="reportSettings && reportSettings.sections.notes.visible">
            <NotesSection
              ref="notes"
              v-if="reportData"
              :editMode="editMode"
              :highlightSection="highlightedSection"
              :note="reportData.notes"
              :approved="isApproved"
              :validationError="validationError"
              @activeEditMode="editMode = true"
              @addHighlight="highlightedSection = 'notes'"
              @updateSectionData="updateSectionData($event, 'notes')"
            />
          </div>
          <div
            id="disturbances"
            v-show="reportSettings && reportSettings.sections.disturbances.visible"
          >
            <DisturbancesSection
              ref="disturbances"
              :key="disturbancesSectionKey"
              v-if="reportData"
              :date="new Date($route.params.date as string)"
              :editMode="editMode"
              :highlightSection="highlightedSection"
              :nonWorkingDays="projectDurationSettings?.non_working_days || []"
              :approved="isApproved"
              :validationError="validationError"
              @activeEditMode="editMode = true"
              @addHighlight="highlightedSection = 'disturbances'"
            />
          </div>
        </div>
      </div>
    </div>
    <LoadingSection v-if="isLoadingReport" :loading="isLoadingReport" />
  </Sidebar>
</template>

<script lang="ts">
import {
  ArrowDownTrayIcon,
  ArrowLongLeftIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  InformationCircleIcon,
  LockOpenIcon,
  PencilIcon,
} from "@heroicons/vue/24/solid";
import { format, parseISO, getYear, getMonth, parse } from "date-fns";
import deepEqual from "deep-equal";
import { v4 as uuidv4 } from "uuid";
import { defineComponent } from "vue";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import LoadingSection from "shared/components/loading_state/LoadingSection.vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import {
  useProjectDurationSettings,
  useUpdateProjectDurationSettings,
} from "shared/composables/projectDurationSettings";
import {
  useConfirmationModal,
  useSaveBeforeLeaveConfirmationModal,
} from "shared/composables/toast";
import { useTrackEvent } from "shared/composables/tracking";
import logger from "shared/services/logger";
import { NonWorkingDay } from "shared/types/ProjectDurationSettings";
import Sidebar from "@/components/layout/Sidebar.vue";
import MainButton from "@/components/other/MainButton.vue";
import DailyReportRepository from "@/repositories/DailyReportRepository";
import textService from "@/services/textService";
import {
  ReportPagination,
  ReportMeta,
  ReportProjectMeta,
  ReportData,
  ReportCustomFields,
  ReportProcessEntry,
  ReportSettings,
  ReportSection,
  ReportImageEntry,
  ReportImageEntrySelected,
  ReportComboValues,
  ReportListBasedEntry,
  ReportListBasedSection,
  ReportSectionsConfig,
} from "@/types/DailyReport";
import DisturbancesSection from "@/views/daily_report/DisturbancesSection.vue";
import CustomGallery from "@/views/daily_report/components/GalleryBase.vue";
import GroupedProcesses from "@/views/daily_report/components/GroupedProcesses.vue";
import Header from "@/views/daily_report/components/HeaderBase.vue";
import LoadingOverlay from "@/views/daily_report/components/LoadingOverlay.vue";
import NotesSection from "@/views/daily_report/components/NotesSection.vue";
import BaseSection from "@/views/daily_report/components/SectionBase.vue";
import { sectionsConfig } from "@/views/daily_report/config";

type DisturbancesExposes = {
  isValid: () => boolean;
  isDirty: boolean;
  getData: () => NonWorkingDay[];
};

export default defineComponent({
  name: "Single Report",
  components: {
    LoadingSpinner,
    Sidebar,
    InformationCircleIcon,
    OaiTooltip,
    ArrowLongLeftIcon,
    ArrowDownTrayIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    CheckIcon,
    PencilIcon,
    LockOpenIcon,
    CheckCircleIcon,
    MainButton,
    BaseSection,
    NotesSection,
    Header,
    LoadingOverlay,
    CustomGallery,
    GroupedProcesses,
    LoadingSection,
    DisturbancesSection,
  },
  data() {
    return {
      sectionsConfig: sectionsConfig as ReportSectionsConfig,
      editMode: false,
      loading: true,
      isSubmitting: false,
      showStayOnPageModal: false,
      message: {
        title: "",
        content: "",
      },
      to: "",
      leavePageAction: "",
      cancel: "",
      logoUrl: "" as string,
      reportPagination: null as ReportPagination | null,
      reportMeta: null as ReportMeta | null,
      projectMeta: null as ReportProjectMeta | null,
      reportData: null as ReportData | null,
      reportDataInitial: null as ReportData | null,
      reportCustomFields: null as ReportCustomFields | null,
      reportSettings: null as ReportSettings | null,
      oaiProcesses: [] as ReportProcessEntry[],
      loadedReport: false,
      highlightedSection: "" as ReportSection | "header" | "gallery" | "",
      validationError: false,
      isLoadingReport: false,
      exportLoading: false,
      disturbancesSectionKey: 0,
    };
  },
  mounted() {
    this.trackEvent("dcr_single_view");
    this.getSingleReport();
  },

  beforeRouteLeave(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    if (!this.editMode) {
      next();
    } else {
      this.showSaveBeforeLeaveConfirmationModal().then((confirmed) => {
        if (confirmed) {
          next();
        }
      });
    }
  },
  computed: {
    isApproved() {
      return this.reportMeta?.status === "APPROVED";
    },
    currentYear() {
      return getYear(parse(this.$route.params.date as string, "yyyy-MM-dd", new Date()));
    },
    currentMonth() {
      return getMonth(parse(this.$route.params.date as string, "yyyy-MM-dd", new Date())) + 1;
    },
    canSaveReport() {
      return (
        this.reportMeta?.status === "NO_EDIT" ||
        (this.reportData && !deepEqual(this.reportData, this.reportDataInitial)) ||
        (this.$refs.disturbances as DisturbancesExposes)?.isDirty
      );
    },
    formattedDate() {
      return format(new Date(this.$route.params.date as string), "dd.MM.yyyy");
    },
  },
  methods: {
    getSingleReport() {
      this.isLoadingReport = true;

      const { customer_name, site_id, date } = this.$route.params;

      return DailyReportRepository.getDailyReport(
        customer_name as string,
        site_id as string,
        date as string,
      )
        .then((response) => {
          this.reportPagination = response.pagination;
          this.reportMeta = response.report_meta;
          this.projectMeta = response.project_meta;
          this.reportData = response.data;
          this.reportDataInitial = structuredClone(response.data);
          this.reportSettings = response.settings;
          this.reportCustomFields = response.custom_fields;
          this.oaiProcesses = response.oai_processes;
          this.logoUrl = response.logo_url;
          this.loading = false;

          this.sortSections();
        })
        .catch((error) => {
          logger.error(error);
        })
        .finally(() => {
          this.isLoadingReport = false;
        });
    },
    edit() {
      this.validationError = false;
      if (this.isApproved) {
        this.updateReportStatus("CUSTOMER_EDIT");
      }
      this.editMode = true;
    },
    cancelEdit() {
      this.editMode = false;
      this.highlightedSection = "";
      this.reportData = structuredClone(this.reportDataInitial);
      this.disturbancesSectionKey += 1;
      this.validationError = false;
    },
    async approve() {
      await this.save();
      this.trackEvent("dcr_single_approve_click");
      if (!this.validationError && this.reportData) {
        this.updateReportStatus("APPROVED");
      }
    },
    formatTimeToIsoTimestamp(date: string, time: string) {
      return `${date}T${time}:00.000+00:00`;
    },
    isValidData() {
      if (!this.reportData) {
        return false;
      }
      let isValid = true;

      const validateCombobox = (fieldType: string, value: ReportComboValues): boolean => {
        if (fieldType === "combobox") {
          return !!value.value;
        }
        return true;
      };

      const validateNumber = (fieldType: string, value: unknown): boolean => {
        if (fieldType === "number" && typeof value === "number") {
          return value >= 0;
        }
        return true;
      };
      const siteDuration = this.reportData.site_duration;
      if (
        !siteDuration.start_time ||
        !siteDuration.end_time ||
        siteDuration.start_time.localeCompare(siteDuration.end_time) > 0
      ) {
        isValid = false;
      }

      Object.keys(this.reportData).forEach((key) => {
        if (!this.reportData) {
          return;
        }
        const sectionData = this.reportData[key as keyof ReportData];

        if (Array.isArray(sectionData)) {
          sectionData.forEach((item) => {
            for (const [itemKey, value] of Object.entries(item)) {
              if (key in this.sectionsConfig) {
                const section = this.sectionsConfig[key as keyof ReportSectionsConfig] as Record<
                  string,
                  unknown
                >;
                if (itemKey in section) {
                  const field = section[itemKey] as Record<string, unknown>;
                  const field_type = field["field_type"] as string;

                  if (!validateCombobox(field_type, value) || !validateNumber(field_type, value)) {
                    isValid = false;
                  }
                }
              }
            }
          });
        }
      });

      if (!(this.$refs.disturbances as DisturbancesExposes)?.isValid()) {
        isValid = false;
      }

      return isValid;
    },
    getSiteDurationChange(
      siteDuration: { start_time: string | null; end_time: string | null },
      date: string,
    ) {
      return {
        key: "site_duration",
        data: {
          start_time: siteDuration.start_time
            ? this.formatTimeToIsoTimestamp(date as string, siteDuration.start_time as string)
            : null,
          end_time: siteDuration.start_time
            ? this.formatTimeToIsoTimestamp(date as string, siteDuration.end_time as string)
            : null,
        },
      };
    },
    getProcessesChange(processes: ReportProcessEntry[], date: string) {
      return {
        key: "processes",
        data: processes.map((process) => ({
          process_type: process.process_type,
          location: process.location,
          note: process.note,
          start_time: process.start_time
            ? this.formatTimeToIsoTimestamp(date as string, process.start_time as string)
            : null,
          end_time: process.end_time
            ? this.formatTimeToIsoTimestamp(date as string, process.end_time as string)
            : null,
        })),
      };
    },
    getImagesChange(images: ReportImageEntry[]) {
      return {
        key: "images",
        data: images.map(({ id, source, camera_id, name, timestamp, width, height }) => ({
          id,
          source,
          camera_id,
          name,
          timestamp,
          width,
          height,
        })),
      };
    },

    async save() {
      this.isSubmitting = true;

      if (!this.isValidData()) {
        this.validationError = true;
        this.isSubmitting = false;
        this.showToast("error", this.$t("err.empty_values"));
        return;
      }

      const { customer_name, site_id, date } = this.$route.params;
      const changes = [] as Record<string, unknown>[];

      if (!this.reportData) {
        return;
      }

      Object.keys(this.reportData).forEach((k) => {
        const key = k as keyof ReportData;
        if (
          this.reportData &&
          this.reportDataInitial &&
          (!deepEqual(this.reportData[key], this.reportDataInitial[key]) ||
            (this.reportMeta?.status === "NO_EDIT" &&
              (key === "processes" ||
                (key === "site_duration" &&
                  this.reportData.site_duration.start_time &&
                  this.reportData.site_duration.end_time))))
        ) {
          if (key === "site_duration") {
            changes.push(this.getSiteDurationChange(this.reportData.site_duration, date as string));
          } else if (key === "processes") {
            changes.push(this.getProcessesChange(this.reportData.processes, date as string));
          } else if (key === "images" && this.reportData.images) {
            changes.push(this.getImagesChange(this.reportData.images));
          } else {
            changes.push({
              key: key,
              data: this.reportData[key],
            });
          }
        }
      });

      if (changes.length > 0) {
        await DailyReportRepository.updateDailyReport(
          customer_name as string,
          site_id as string,
          date as string,
          { changes },
        ).then(async () => {
          await this.getSingleReport();
        });
      }

      await this.saveDisturbances();

      this.validationError = false;
      this.editMode = false;
      this.highlightedSection = "";
      this.isSubmitting = false;
    },

    async saveDisturbances() {
      const disturbances = (this.$refs.disturbances as DisturbancesExposes)?.getData();

      if (!deepEqual(disturbances, this.projectDurationSettings?.non_working_days)) {
        await this.updateProjectDurationSettings({
          non_working_days: disturbances,
        });
      }
    },

    handleAddCustomField(
      customFieldAdded: ReportComboValues,
      sectionKey: keyof ReportCustomFields,
      fieldKey: keyof ReportCustomFields[typeof sectionKey],
    ) {
      if (this.reportCustomFields) {
        const section = this.reportCustomFields[sectionKey];
        if (section && section[fieldKey]) {
          (section[fieldKey] as ReportComboValues[]).push(customFieldAdded);
        }
      }
    },

    handleDeleteCustomField(
      customFieldToDelete: ReportComboValues,
      sectionKey: keyof ReportCustomFields,
      fieldKey: keyof ReportCustomFields[typeof sectionKey],
    ) {
      if (this.reportCustomFields && this.reportData) {
        const customFieldList = this.reportCustomFields[sectionKey][
          fieldKey
        ] as ReportComboValues[];

        for (let i = 0; i < customFieldList.length; i++) {
          if (deepEqual(customFieldToDelete, customFieldList[i])) {
            customFieldList.splice(i, 1);
            for (let i = 0; i < this.reportData[sectionKey].length; i++) {
              const valueToClear = this.reportData[sectionKey][i][fieldKey] as ReportComboValues;
              if (deepEqual(valueToClear, customFieldToDelete)) {
                valueToClear.mode = "custom";
                valueToClear.value = "";
              }
            }
            break;
          }
        }
      }
    },
    updateReportStatus(status: string) {
      const payload = { status };
      const { customer_name, site_id, date } = this.$route.params;
      return DailyReportRepository.updateDailyReportStatus(
        customer_name as string,
        site_id as string,
        date as string,
        payload,
      )
        .then((response) => {
          this.reportMeta = response;
        })
        .catch((error) => {
          logger.error(error);
        });
    },
    updateSiteDuration(event: { target: HTMLInputElement }) {
      if (this.reportData && event.target.name in this.reportData.site_duration) {
        this.reportData.site_duration[event.target.name as "start_time" | "end_time"] =
          event.target.value !== "" ? event.target.value : null;
      }
    },
    updateGalleryImages(data: {
      galleryMap: Record<string, ReportImageEntrySelected[]>;
      customImages: ReportImageEntry[];
    }) {
      for (const key in data.galleryMap) {
        data.galleryMap[key].forEach((img) => {
          if (
            this.reportData &&
            img.selected &&
            !this.reportData?.images.some(
              (item) => item.camera_id === img.camera_id && item.timestamp === img.timestamp,
            )
          ) {
            this.reportData.images = [
              ...this.reportData.images,
              {
                url: img.url,
                id: uuidv4(),
                source: "gallery",
                camera_id: img.camera_id,
                name: img.name,
                timestamp: img.timestamp,
                width: 500,
                height: 500,
              },
            ];
          } else if (
            !img.selected &&
            this.reportData?.images.some(
              (item) => item.camera_id === img.camera_id && item.timestamp === img.timestamp,
            )
          ) {
            this.reportData.images = this.reportData?.images.filter(
              (item) => !(item.camera_id === img.camera_id && item.timestamp === img.timestamp),
            );
          }
        });
      }
      if (this.reportData) {
        this.reportData.images = [...this.reportData.images, ...data.customImages];
      }
    },
    removeGalleryImage(idToRemove: string) {
      if (this.reportData) {
        this.reportData.images = this.reportData.images.filter((item) => item.id !== idToRemove);
      }
    },

    addSectionRowEntry<T extends ReportListBasedSection>(
      sectionEntry: Extract<ReportListBasedEntry, { section: T }>,
      section: T,
    ) {
      if (this.reportData) {
        this.reportData[section].push(sectionEntry);
      }
    },
    removeSectionRowEntry<T extends ReportListBasedSection>(
      sectionEntry: Extract<ReportListBasedEntry, { section: T }>,
      section: T,
    ) {
      if (this.reportData) {
        const index = this.reportData[section].findIndex((e) => e === sectionEntry);

        if (index > -1) {
          this.reportData[section].splice(index, 1);
        }
      }
    },
    updateProcessFieldValue(event: { target: HTMLInputElement }, entryId: string) {
      if (this.reportData) {
        const processEntry = this.reportData.processes.find((item) => item.id === entryId);
        const key = event.target.name as "start_time" | "end_time" | "note";
        if (processEntry) {
          processEntry[key] = event.target.value;
        }
      }
    },
    updateProcessComboBox(
      field: "process_type" | "location",
      value: ReportComboValues,
      entryId: string,
    ) {
      if (this.reportData) {
        const processEntry = this.reportData.processes.find((item) => item.id === entryId);
        if (processEntry) {
          processEntry[field] = value;
        }
      }
    },
    updateSectionFieldValue(
      event: { target: HTMLInputElement },
      section: ReportListBasedSection,
      rowIdx: number,
    ) {
      if (this.reportData) {
        const entry = this.reportData[section][rowIdx] as Record<string, unknown>;
        const key = event.target.name;
        if (entry) {
          entry[key] =
            event.target.type === "number" ? Number(event.target.value) : event.target.value;
        }
      }
    },
    updateSectionComboBox(
      section: keyof ReportCustomFields,
      field: keyof ReportCustomFields[typeof section],
      value: ReportComboValues,
      rowIdx: number,
    ) {
      if (this.reportData?.[section]?.[rowIdx]?.[field]) {
        const dataToUpdate = this.reportData[section][rowIdx][field] as ReportComboValues;
        dataToUpdate.value = value.value;
        dataToUpdate.mode = value.mode;
      }
    },
    updateSectionData<T extends keyof ReportData>(sectionData: ReportData[T], key: T) {
      if (this.reportData) {
        this.reportData[key] = sectionData;
      }
    },
    checkElements(element: unknown, position: number) {
      const sectionContainers = document.querySelectorAll("#sections-container > div");
      const lastElement =
        sectionContainers.length >= 2
          ? sectionContainers[sectionContainers.length - position]
          : null;

      return element === lastElement;
    },
    formatLastUpdated(timeDate: string) {
      return format(parseISO(timeDate), "dd.MM.yyyy HH:mm:ss");
    },

    paginate(value: string) {
      if (!this.editMode) {
        this.$router.push({ name: "SingleDailyReport", params: { date: value } });
      } else {
        this.showSaveBeforeLeaveConfirmationModal().then((confirmed) => {
          if (confirmed) {
            this.$router.push({ name: "SingleDailyReport", params: { date: value } });
          }
        });
      }
    },

    exportPdf() {
      if (!this.editMode) {
        this.trackEvent("dcr_export-single_click");
        this.exportLoading = true;
        const lang = localStorage.getItem("lang") || "de";

        const { customer_name, site_id, date } = this.$route.params;

        return DailyReportRepository.exportDailyReportSingle(
          customer_name as string,
          site_id as string,
          date as string,
          lang,
          "2",
        )
          .then((response) => {
            const a = document.createElement("a"); //Create <a>
            a.href = "data:application/pdf;base64," + response.report;
            a.download = `oculai_${textService.toNormalizedFileName(
              `${this.$t("report.title")}-${date}`,
            )}.pdf`;
            a.click(); //Downloaded file
          })
          .catch((error) => {
            logger.error(error);
          })
          .finally(() => {
            this.exportLoading = false;
          });
      } else {
        this.showStayOnPageModal = true;
        this.message.content = this.$t("err.save_before_export");
        this.message = {
          title: this.$t("err.save_before_leave_title"),
          content: this.$t("err.save_before_export"),
        };
        this.leavePageAction = this.$t("buttons.export");
        this.cancel = this.$t("buttons.stay_page");
      }
    },
    sortSections() {
      const container = document.getElementById("sections-container");
      if (!container) {
        return;
      }
      const divs: HTMLDivElement[] = Array.from(container.children) as HTMLDivElement[];

      divs.forEach((div: HTMLDivElement) => {
        const id = div.getAttribute("id");
        if (id && this.reportSettings?.sections[id]?.order !== undefined) {
          const orderValue = String(this.reportSettings.sections[id].order);
          div.setAttribute("data-order", orderValue);
        }
      });

      divs.sort((a, b) => {
        const orderA = Number(a.getAttribute("data-order"));
        const orderB = Number(b.getAttribute("data-order"));
        return orderA - orderB;
      });

      divs.forEach((div) => container.appendChild(div));
    },
  },
  setup() {
    const showSaveBeforeLeaveConfirmationModal = useSaveBeforeLeaveConfirmationModal();
    const showConfirmationModal = useConfirmationModal();
    const { projectDurationSettings } = useProjectDurationSettings();
    const { updateProjectDurationSettings } = useUpdateProjectDurationSettings();
    const trackEvent = useTrackEvent();
    return {
      showSaveBeforeLeaveConfirmationModal,
      showConfirmationModal,
      projectDurationSettings,
      updateProjectDurationSettings,
      trackEvent,
    };
  },
});
</script>
