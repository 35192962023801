<template>
  <Sidebar :pageHeader="t('analytics.site_activity.title')">
    <NoDataYet v-if="noData" />
    <div class="grid grid-cols-5 lg:grid-cols-10 md:px-6 px-4" v-else>
      <div class="col-span-6">
        <div class="mx-auto max-w-full pb-6">
          <div v-if="loading" :class="{ 'mt-12': loading }">
            <div
              class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-3 lg:grid-cols-3 xl:gap-x-8 pb-8"
            >
              <div class="loadingCard is-loading" v-for="i in 3" :key="i">
                <div
                  class="image group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-50 overflow-hidden"
                />
                <h2 class="block h-xs font-medium text-gray-900 rounded-xs w-10" />
              </div>
            </div>
          </div>
          <div v-if="!loading && !data" class="pb-4 pt-2">
            <div>
              <div
                class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 xl:gap-x-8 pb-8"
              >
                <div v-for="i in 4" :key="i">
                  <img
                    :src="defaultProjectThumbnailUrl"
                    alt="default image"
                    class="group block w-full rounded-lg bg-gray-50 overflow-hidden"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-show="!loading && data"
            :id="scatterViewerId"
            :class="[hasLessThanTwo ? 'grid-cols-4' : 'grid-cols-6', 'grid gap-x-4 gap-y-4']"
          >
            <div class="col-span-6 mt-2">
              <h3 class="text-xl font-bold text-gray-700">
                {{ $t("analytics.site_activity.tab_walking_paths") }}
              </h3>
            </div>
            <div v-for="(item, index) in data?.items" :key="index" class="col-span-6 md:col-span-2">
              <a
                :href="item.url_scatter_original"
                :data-pswp-width="(item.width || 0) * 5"
                :data-pswp-height="(item.height || 0) * 5"
                target="_blank"
                rel="noreferrer"
              >
                <div
                  class="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100"
                >
                  <img
                    :src="item.url_scatter_thumbnail"
                    alt=""
                    class="pointer-events-none object-cover group-hover:opacity-75"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div id="datePagination" class="flex items-center justify-center pb-8" v-if="!loading">
          <button
            v-if="data && data.previous"
            @click="loadSiteActivity(data?.previous ? data.previous : '')"
            :disabled="!(data && data.previous)"
            type="button"
            class="inline-flex items-center border-transparent px-4 py-2 text-sm font-medium"
          >
            <ChevronLeftIcon class="h-6 w-6 text-oaiGray-300" aria-hidden="true" />
          </button>

          <h2
            class="pl-8 pr-8 text-sm font-extrabold text-oaiGray-300 2xl:pl-12 2xl:pr-12 2xl:text-lg"
            v-if="date"
          >
            {{ formatDate(date) }}
          </h2>
          <button
            v-if="data && data.next"
            :disabled="!(data && data.next)"
            @click="loadSiteActivity(data?.next ? data.next : '')"
            type="button"
            class="inline-flex items-center border-transparent px-4 py-2 text-sm font-medium"
          >
            <ChevronRightIcon class="h-6 w-6 text-oaiGray-300" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div class="col-span-5 lg:col-span-4">
        <div class="lg:pl-10 space-y-4 divide-y divide-gray-200">
          <h3 class="text-md 2xl:text-lg leading-6 font-medium text-gray-900">
            {{ $t("analytics.site_activity.pick_a_date") }}
          </h3>
          <Form
            @submit="onSubmit"
            :validationSchema="schema"
            class="space-y-3 sm:space-y-5 pt-3 sm:pt-0"
          >
            <div class="mt-1 pt-4">
              <DateField
                name="scatterDate"
                :label="$t('analytics.site_activity.date')"
                :maxDate="maxDate"
                :inlineField="true"
                :date="date"
              />
            </div>
            <div class="flex sm:pt-5">
              <MainButton
                :label="$t('analytics.site_activity.search')"
                @click="buttonLoad = true"
                type="submit"
                color="yellow"
                class="mx-0 sm:mr-0 mt-0"
              >
                <LoadingSpinner v-if="buttonLoad" size="w-5 h-5" color="white" class="mr-2" />
              </MainButton>
            </div>
          </Form>
          <WeatherContainer
            ref="weatherContainerComponent"
            :date="dateToString"
            :loadMain="loading"
            v-if="data"
          />
        </div>
      </div>
    </div>
  </Sidebar>
</template>

<script setup lang="ts">
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/solid";
import axios from "axios";
import { format, getDay, subDays } from "date-fns";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import { Form } from "vee-validate";
import { computed, onMounted, ref, Ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import * as yup from "yup";
import defaultProjectThumbnailUrl from "shared/assets/imgs/default-project-thumbnail.jpg";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { useCustomToast } from "shared/composables/toast";
import { useTrackEvent } from "shared/composables/tracking";
import DateField from "@/components/forms/DateField.vue";
import NoDataYet from "@/components/layout/NoDataYet.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import MainButton from "@/components/other/MainButton.vue";
import SiteActivityRepository from "@/repositories/SiteActivityRepository";
import { SiteActivityDateResponse, ScatterItem } from "@/types/SiteActivity";
import WeatherContainer from "@/views/camera/components/WeatherContainer.vue";

const data = ref(null) as Ref<SiteActivityDateResponse | null>;
const date = ref(null) as Ref<Date | null>;
const loading = ref(true) as Ref<boolean>;
const buttonLoad = ref(false) as Ref<boolean>;
const photoswipeMounted = ref(false) as Ref<boolean>;
const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();
const route = useRoute();
const { t } = useI18n();
const toast = useCustomToast();
const noData = ref(false) as Ref<boolean>;
const schema = computed(() =>
  yup.object({
    scatterDate: yup
      .date()
      .required(t("err.required"))
      .max(
        maxDate.value,
        t("err.err_future_date", { maxDate: format(maxDate.value, "yyyy-MM-dd") }),
      ),
  }),
);

const hasLessThanTwo = computed(() => {
  return data.value?.items && data.value.items.length <= 2;
});

const scatterViewerId = computed(() => {
  return `scatter-viewer-${currentCustomerName}-${currentSiteId}`;
});
const dateToString = computed(() => {
  return date.value ? format(date.value, "yyyy-MM-dd") : "";
});

const maxDate = computed(() => {
  const today = new Date();
  if (getDay(today) === 1) {
    return subDays(today, 2);
  } else {
    return subDays(today, 1);
  }
});

const trackEvent = useTrackEvent();
onMounted(() => {
  trackEvent("site-activity_view");
  loadSiteActivity(dateToString.value);
});

watch(loading, (newLoadingValue) => {
  if (!newLoadingValue) {
    buttonLoad.value = newLoadingValue;
  }
});

watch(loading, (newLoadingValue) => {
  if (!newLoadingValue) {
    buttonLoad.value = newLoadingValue;
  }
});

const mountPhotoSwipe = () => {
  if (!photoswipeMounted.value) {
    const lightbox_HM = new PhotoSwipeLightbox({
      gallery: `#${scatterViewerId.value}`,
      children: "a",
      pswpModule: () => import("photoswipe"),
      wheelToZoom: true,
      padding: { top: 10, bottom: 10, left: 0, right: 0 },
    });
    lightbox_HM.init();
    photoswipeMounted.value = true;
  }
};
const loadSiteActivity = async (value: string | null) => {
  loading.value = true;
  const { customer_name, site_id } = route.params;
  try {
    data.value = await SiteActivityRepository.loadSiteActivityDate(
      customer_name as string,
      site_id as string,
      value,
    );
    data.value.items = data.value.items.sort((a, b) =>
      a.displayed_name.localeCompare(b.displayed_name),
    );
    data.value.items.forEach((item: ScatterItem) => {
      const imgElement = new Image();
      imgElement.src = item.url_scatter_original;
      imgElement.addEventListener("load", () => {
        item.width = imgElement.naturalWidth;
        item.height = imgElement.naturalHeight;
      });
    });
    date.value = new Date(data.value.date);

    loading.value = false;
    mountPhotoSwipe();
  } catch (error) {
    if (axios.isAxiosError(error)) {
      data.value = null;
      if (error?.response?.data?.code === "NO_DATA_YET") {
        noData.value = true;
      } else {
        toast.error(t("err.no_data_found"));
      }
    }
  } finally {
    loading.value = false;
    if (buttonLoad.value) buttonLoad.value = false;
  }
};
const formatDate = (dateString: Date) => {
  return format(dateString, "dd.MM.yyyy");
};

const onSubmit = async (values: Record<string, number | Date>) => {
  const formattedValue = format(values.scatterDate, "yyyy-MM-dd");
  await loadSiteActivity(formattedValue);
};
</script>
