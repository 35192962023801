<template>
  <Modal :open="true" @close="emit('close')" custom-cls="sm:w-full sm:max-w-lg">
    <template #title>
      <div>
        <h3 class="pt-2 text-lg font-medium leading-6 text-gray-900">
          {{ user.name }}
        </h3>
        <small class="truncate text-gray-400"> {{ user.email }} </small>
      </div>
    </template>
    <template #content>
      <Form
        @submit="handleSubmit"
        :initialValues="initialValues"
        :validationSchema="schema"
        class="flex flex-col gap-6 w-full"
        v-slot="{ isSubmitting, values }"
      >
        <SelectListField
          name="company_type"
          :label="t('admin.project_participants.company_type')"
          :options="companyTypesList"
          :minWidth="250"
        />
        <div>
          <SelectListField
            name="group"
            :label="t('organizations.permission')"
            :options="rolesList"
            :minWidth="250"
          />
          <div class="mt-2 text-gray-600 text-sm ml-1">
            {{ t(`organizations.permission_descriptions.${values.group}`) }}
          </div>
        </div>
        <div class="pt-4 flex items-center justify-center">
          <button
            type="submit"
            class="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-yellow-600 px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            :disabled="isSubmitting"
          >
            <LoadingSpinner v-if="isSubmitting" size="w-5 h-5" color="white" class="-ml-1 mr-3" />
            <ArrowPathIcon v-if="!isSubmitting" class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
            {{ t("admin.project_participants.modal_permission.update") }}
          </button>
        </div>
      </Form>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ArrowPathIcon } from "@heroicons/vue/24/solid";
import { Form, SubmissionHandler } from "vee-validate";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import SelectListField from "shared/components/forms/SelectListField.vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { companyTypes } from "shared/constants/user";
import { User, UserCompanyType, UserOrganizationPermissionGroup } from "shared/types/User";
import { useUpdateUserOrganizationPermission } from "@/composables/user";

type EditOrganizationPermissionFormValues = {
  company_type: UserCompanyType | null;
  group: UserOrganizationPermissionGroup;
};

const props = defineProps<{ user: User; organizationId: string }>();
const emit = defineEmits<{ (eventName: "close"): void }>();

const { t } = useI18n();

const rolesList = [
  {
    value: "organization_base",
    name: t("permissions.organization_base"),
  },
  {
    value: "organization_admin",
    name: t("permissions.organization_admin"),
  },
];

const { updateUserOrganizationPermission } = useUpdateUserOrganizationPermission();

const schema = yup.object().shape({
  groups: yup.array().of(
    yup.object().shape({
      enabled: yup.boolean().required(),
    }),
  ),
});

const initialValues = computed<EditOrganizationPermissionFormValues>(() => {
  const userOrganization = props.user.organizations.find(
    (item) => item.organization_id === props.organizationId,
  );
  return {
    company_type: userOrganization?.company_type ?? null,
    group:
      userOrganization && userOrganization.explicit_groups.includes("organization_admin")
        ? "organization_admin"
        : "organization_base",
  };
});

const companyTypesList = companyTypes.map((type) => ({
  value: type,
  name: t(`admin.project_participants.company_types.${type}`),
}));

const handleSubmit: SubmissionHandler = async (genericObject) => {
  const values = genericObject as EditOrganizationPermissionFormValues;
  await updateUserOrganizationPermission({
    username: props.user.username,
    organizationId: props.organizationId,
    organizationPermission: {
      company_type: values.company_type,
      explicit_groups:
        values.group === "organization_admin"
          ? ["organization_base", "organization_admin"]
          : ["organization_base"],
    },
  })
    .then(() => {
      emit("close");
    })
    .catch(() => undefined);
};
</script>
