<template>
  <Popover class="relative" v-slot="{ open }">
    <label class="text-sm" v-if="!hideLabel">
      {{ t("public_tokens.token_expiration") }}
    </label>
    <slot>
      <PopoverButton
        class="relative rounded-md py-1 pl-2 pr-10 text-xs text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:leading-6 w-full"
        :class="[open ? 'ring-2 ring-yellow-500' : '']"
      >
        {{
          selectedOption === "custom" && expirationDate
            ? format(expirationDate, "dd.MM.yyyy")
            : t(`public_tokens.token_expiration_options.${selectedOption}`)
        }}
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </span>
      </PopoverButton>
    </slot>
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        class="absolute z-50 rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-yellow-500/5 focus:outline-none sm:text-sm"
        :class="popoverClass"
        v-slot="{ close }"
      >
        <div
          :key="option.value"
          v-for="option in expirationOptions"
          @click="
            handleOptionClick(option.value);
            close();
          "
          class="relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 text-xs hover:bg-yellow-200 truncate"
          :class="selectedOption === option.value && !dontHighlightSelected ? 'bg-yellow-200' : ''"
        >
          {{ option.name }}
        </div>
        <VueDatePicker
          :modelValue="expirationDate"
          :columns="4"
          :enable-time-picker="false"
          :placeholder="`${t('report.bulk_export.main_label')}`"
          :locale="locale"
          inputClassName="dp-custom-input"
          menuClassName="dp-custom-menu"
          format="dd.MM.yyyy"
          auto-apply
          :minDate="new Date()"
          @update:modelValue="
            handleDateChange($event);
            close();
          "
        >
          <template #trigger>
            <div
              class="font-sans relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 text-xs hover:bg-yellow-200 truncate"
              :class="selectedOption === 'custom' && !dontHighlightSelected ? 'bg-yellow-200' : ''"
            >
              {{ t("public_tokens.token_expiration_options.custom") }}
            </div>
          </template>
        </VueDatePicker>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script lang="ts" setup>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { ChevronUpDownIcon } from "@heroicons/vue/24/outline";
import VueDatePicker from "@vuepic/vue-datepicker";
import { addDays, addHours, addWeeks, endOfDay, format } from "date-fns";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";

type ExpirationOption = "none" | "one_hour" | "one_day" | "one_week" | "custom";

const props = withDefaults(
  defineProps<{
    expirationDate: Date | null;
    hideLabel?: boolean;
    popoverClass?: string;
    dontHighlightSelected?: boolean;
  }>(),
  {
    popoverClass: "top-[62px] w-full",
  },
);

const emit = defineEmits<{ (eventName: "change", payload: Date | null): void }>();

const { t, locale } = useI18n();

const expirationOptionList: ExpirationOption[] = ["none", "one_hour", "one_day", "one_week"];

const expirationOptions: { name: string; value: ExpirationOption }[] = expirationOptionList.map(
  (option) => ({
    name: t(`public_tokens.token_expiration_options.${option}`),
    value: option,
  }),
);

const selectedOption = ref<ExpirationOption>("none");

const getDateFromOption = (option: ExpirationOption) => {
  if (option === "one_day") {
    return addDays(new Date(), 1);
  }
  if (option === "one_hour") {
    return addHours(new Date(), 1);
  }
  if (option === "one_week") {
    return addWeeks(new Date(), 1);
  }
  return null;
};

const handleOptionClick = (option: ExpirationOption) => {
  selectedOption.value = option;
  emit("change", getDateFromOption(option));
};

const handleDateChange = (newDate: Date) => {
  selectedOption.value = "custom";
  emit("change", endOfDay(newDate));
};

watch(
  () => props.expirationDate,
  () => {
    if (!props.expirationDate) {
      selectedOption.value = "none";
    }
  },
);
</script>
