<template>
  <div class="flex flex-col gap-3 w-full">
    <h1 class="text-xl font-bold text-gray-900 px-1">
      {{ t("admin.durations_project_settings.holidays_header") }}
    </h1>

    <Form
      @submit="handleSubmit"
      :initialValues="initialValues"
      :validationSchema="schema"
      class="flex flex-col gap-3"
      v-slot="{ isSubmitting, values, meta, setFieldValue }"
      ref="formRef"
    >
      <div class="settingsCard p-0">
        <div class="text-center text-gray-600 m-8" v-if="values.non_working_days.length === 0">
          {{ t("admin.durations_project_settings.no_holidays_message") }}
        </div>
        <div v-else class="overflow-y-auto border" style="height: 600px">
          <table class="w-full divide-y divide-gray-200 text-left hidden lg:table relative">
            <thead class="bg-gray-50 w-full sticky top-0 z-50">
              <tr class="w-full">
                <th
                  class="w-1/12 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  #
                </th>
                <th
                  class="w-3/12 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  {{ t("admin.durations_project_settings.holidays_field_name") }}
                </th>
                <th
                  class="w-3/12 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  {{ t("admin.durations_project_settings.holidays_field_date") }}
                </th>
                <th
                  class="w-3/12 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  {{ t("admin.durations_project_settings.holidays_field_type") }}
                </th>
                <th
                  class="w-3/12 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  {{ t("admin.durations_project_settings.holidays_field_duration") }}
                </th>
                <th
                  class="w-2/12 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                ></th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr
                v-for="(nonWorkingDay, index) in values.non_working_days"
                :key="index"
                class="text-gray-700"
                :class="hasChangeInYear(values, index, nonWorkingDay) ? '!border-gray-400' : ''"
              >
                <td class="px-6 py-3 text-sm text-gray-500 w-1/12 relative">
                  <div>
                    {{ index + 1 }}
                  </div>
                  <div
                    class="absolute bg-gray-50 p-0.5 text-xs"
                    style="top: -11px; left: 45px"
                    v-if="hasChangeInYear(values, index, nonWorkingDay)"
                  >
                    {{ getYear(nonWorkingDay.start_date) }}
                  </div>
                </td>
                <td
                  class="px-6 py-3 text-sm text-gray-500 truncate w-3/12"
                  :title="nonWorkingDay.name"
                >
                  {{ nonWorkingDay.name }}
                </td>
                <td class="px-6 py-3 text-sm text-gray-500 w-3/12">
                  {{ formatDate(nonWorkingDay) }}
                </td>
                <td class="px-6 py-3 text-sm text-gray-500 w-3/12 whitespace-nowrap">
                  <span
                    class="text-white px-1.5 py-1 rounded text-xs leading-normal"
                    :style="
                      nonWorkingDay.type === 'disturbance'
                        ? 'background-color: #f5e3be; color: #9c7018'
                        : 'background-color: #bfdbfe4c; color: #1d4ed8'
                    "
                  >
                    {{
                      t(
                        `admin.durations_project_settings.non_working_day_type_labels.${nonWorkingDay.type}`,
                      )
                    }}
                  </span>
                </td>
                <td
                  class="px-6 py-3 text-sm text-gray-500"
                  :class="
                    nonWorkingDay.type === 'disturbance' && nonWorkingDay.is_critical
                      ? ''
                      : 'w-3/12'
                  "
                >
                  <div class="flex items-center gap-2">
                    <span>
                      {{
                        nonWorkingDay.start_date &&
                        nonWorkingDay.end_date &&
                        differenceInDays(nonWorkingDay.end_date, nonWorkingDay.start_date) + 1
                      }}
                    </span>

                    <OaiTooltip
                      v-if="nonWorkingDay.type === 'disturbance' && nonWorkingDay.is_critical"
                    >
                      <p class="px-1.5 py-0.5 rounded bg-red-200 text-red-900 max-w-40 truncate">
                        <span class="text-xs leading-normal">
                          {{ t("working_day.inactive_days") }}
                        </span>
                      </p>
                      <template #tooltip>
                        <div class="text-sm">
                          {{ t("working_day.inactive_days") }}
                        </div>
                      </template>
                    </OaiTooltip>
                  </div>
                </td>

                <td class="px-6 py-3 text-sm text-gray-500 w-2/12">
                  <div class="flex justify-end gap-3">
                    <PencilIcon
                      class="w-5 h-5 text-gray-600 cursor-pointer"
                      @click="editedNonWorkingDay = nonWorkingDay"
                    />
                    <TrashIcon
                      class="lg:h-5 h-4 text-gray-600 cursor-pointer"
                      @click="
                        setFieldValue(
                          'non_working_days',
                          values.non_working_days.filter(
                            (nwd: NonWorkingDay) => nwd !== nonWorkingDay,
                          ),
                        )
                      "
                    />
                    <NonWorkingDaysEditor
                      v-if="editedNonWorkingDay === nonWorkingDay"
                      :nonWorkingDay="nonWorkingDay"
                      @close="editedNonWorkingDay = null"
                      @commit="
                        editedNonWorkingDay = null;
                        setFieldValue(`non_working_days.${index}`, $event);
                      "
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="flex flex-col lg:hidden">
            <div
              v-for="(nonWorkingDay, index) in values.non_working_days"
              :key="index"
              class="shadow mb-2"
            >
              <div class="flex justify-between p-3">
                <h3 class="text-gray-500 font-medium">#{{ index + 1 }}</h3>
                <div class="flex gap-2">
                  <PencilIcon
                    class="w-5 h-4 text-gray-600 cursor-pointer"
                    @click="editedNonWorkingDay = nonWorkingDay"
                  />
                  <TrashIcon
                    class="lg:h-5 h-4 text-gray-600 cursor-pointer"
                    @click="
                      setFieldValue(
                        'non_working_days',
                        values.non_working_days.filter(
                          (nwd: NonWorkingDay) => nwd !== nonWorkingDay,
                        ),
                      )
                    "
                  />
                </div>
              </div>
              <div class="grid grid-cols-2 items-center border-b">
                <div
                  class="px-3 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  {{ t("admin.durations_project_settings.holidays_field_name") }}
                </div>
                <div class="py-2 text-sm text-gray-500">{{ nonWorkingDay.name }}</div>
              </div>
              <div class="grid grid-cols-2 items-center border-b">
                <div
                  class="px-3 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  {{ t("admin.durations_project_settings.holidays_field_date") }}
                </div>
                <div class="py-2 text-sm text-gray-500">{{ formatDate(nonWorkingDay) }}</div>
              </div>
              <div class="grid grid-cols-2 items-center border-b">
                <div
                  class="px-3 py-2 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  {{ t("admin.durations_project_settings.holidays_field_duration") }}
                </div>
                <div class="py-2 text-sm text-gray-500">
                  <span>
                    {{
                      nonWorkingDay.start_date &&
                      nonWorkingDay.end_date &&
                      differenceInDays(nonWorkingDay.end_date, nonWorkingDay.start_date) + 1
                    }}
                  </span>

                  <p
                    v-if="nonWorkingDay.type === 'disturbance' && nonWorkingDay.is_critical"
                    class="px-1.5 py-0.5 rounded bg-red-200 text-red-900 w-fit"
                  >
                    <span class="text-xs leading-normal">
                      {{ t("working_day.inactive_days") }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col md:flex-row w-full">
        <div class="flex items-center flex-wrap w-full mb-2 md:mb-0">
          <MainButton
            @clicked="isPublicHolidaysModalVisible = true"
            class="w-full md:w-auto text-white bg-green-500 hover:bg-green-700"
            :label="t('admin.durations_project_settings.add_public_holidays_button')"
            type="button"
          />

          <MainButton
            @clicked="isAddModalVisible = true"
            :label="t('buttons.add')"
            type="button"
            color="yellow"
            class="w-full md:w-auto md:ml-2"
          >
            <PlusIcon class="h-5 w-5 cursor-pointer" />
          </MainButton>
        </div>
        <MainButton
          :label="t('buttons.save')"
          type="submit"
          color="yellow"
          class="sm:mr-0"
          :isDisabled="isSubmitting || !meta.dirty"
        >
          <LoadingSpinner
            v-if="isSubmitting && meta.valid"
            size="w-5 h-5"
            color="white"
            class="mr-2"
          />
        </MainButton>
      </div>
      <div v-if="isUpdateError" class="text-red-900 text-center md:text-right text-sm">
        {{ t("admin.durations_project_settings.save_error_message") }}
      </div>
      <NonWorkingDaysEditor
        v-if="isAddModalVisible"
        @close="isAddModalVisible = false"
        @commit="
          isAddModalVisible = false;
          setFieldValue('non_working_days', [...values.non_working_days, $event]);
        "
      />
      <PublicHolidaysModal
        v-if="isPublicHolidaysModalVisible"
        :project="project"
        @close="isPublicHolidaysModalVisible = false"
        @commit="
          isPublicHolidaysModalVisible = false;
          setFieldValue('non_working_days', getMergedHolidays(values.non_working_days, $event));
        "
      />
    </Form>
  </div>
</template>

<script lang="ts" setup>
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/vue/24/solid";
import { format, getYear, differenceInDays } from "date-fns";
import { Form, GenericObject, SubmissionHandler } from "vee-validate";
import { computed, ref, PropType } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { useUpdateProjectDurationSettings } from "shared/composables/projectDurationSettings";
import { useTrackEvent } from "shared/composables/tracking";
import { Project } from "shared/types/Project";
import { NonWorkingDay, ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import MainButton from "@/components/other/MainButton.vue";
import NonWorkingDaysEditor from "@/components/working_days/NonWorkingDaysEditor.vue";
import PublicHolidaysModal from "@/views/settings_project/components/PublicHolidaysModal.vue";

const { t } = useI18n();

const { updateProjectDurationSettings, isUpdateError } = useUpdateProjectDurationSettings();

const formRef = ref<typeof Form | null>(null);

const props = defineProps({
  projectDurationSettings: {
    type: Object as PropType<ProjectDurationSettings>,
    required: true,
  },
  project: {
    type: Object as PropType<Project>,
    required: true,
  },
});

defineExpose({ form: formRef });

const editedNonWorkingDay = ref<NonWorkingDay | null>(null);
const isAddModalVisible = ref(false);
const isPublicHolidaysModalVisible = ref(false);

const schema = computed(() =>
  yup.object({
    non_working_days: yup.array().of(
      yup.object({
        name: yup.string().required(),
        start_date: yup.date().required(),
        end_date: yup.date().required(),
      }),
    ),
  }),
);

const initialValues = computed(() => ({
  non_working_days: props.projectDurationSettings.non_working_days,
}));

const trackEvent = useTrackEvent();

const handleSubmit: SubmissionHandler = async (genericObject, { resetForm }) => {
  trackEvent("settings_non-working-days_save");
  try {
    await updateProjectDurationSettings({ non_working_days: genericObject.non_working_days });
    resetForm({ values: initialValues.value });
  } catch {
    // have to catch the error here, otherwise it's propagated as an unhandled error
    // it's already logged by vue-query
    return undefined;
  }
};

const getMergedHolidays = (non_working_days: NonWorkingDay[], publicHolidays: NonWorkingDay[]) => {
  return [
    ...non_working_days.filter(
      (nwd) =>
        nwd.type !== "public_holiday" ||
        (nwd.type === "public_holiday" &&
          !publicHolidays.some(
            (publicHoliday) =>
              publicHoliday.start_date.getTime() === nwd.start_date.getTime() &&
              publicHoliday.end_date.getTime() === nwd.end_date.getTime(),
          )),
    ),
    ...publicHolidays,
  ];
};

const formatDate = (holiday: NonWorkingDay) => {
  if (!holiday.start_date || !holiday.end_date) {
    return "";
  }
  if (holiday.start_date.getTime() === holiday.end_date.getTime()) {
    return format(holiday.start_date, "dd.MM.yyyy");
  }
  return `${format(holiday.start_date, "dd.MM.yyyy")} - ${format(holiday.end_date, "dd.MM.yyyy")}`;
};

const hasChangeInYear = (values: GenericObject, index: number, non_working_day: NonWorkingDay) =>
  non_working_day &&
  values.non_working_days[index - 1] &&
  getYear(non_working_day.start_date) !== getYear(values.non_working_days[index - 1].start_date);
</script>
