<template>
  <img :src="logoUrl" class="object-contain" :class="cls" alt="organization logo" v-if="logoUrl" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useLogoUrl } from "shared/composables/organization";

const props = defineProps<{ organizationId: string; cls?: string }>();

const { logoUrl } = useLogoUrl(computed(() => props.organizationId));
</script>
