<template>
  <div :style="fullWindow4to3RatioDimensionStyle">
    <OaiLiveImagePlayer
      :cameraId="route.params.camera_id as string"
      :showControls="false"
      :isPublic="true"
    />
  </div>
</template>

<script lang="ts" setup>
// TODO: drop this component after everyone switched to public tokens
import { useRoute } from "vue-router";
import OaiLiveImagePlayer from "shared/components/camera/OaiLiveImagePlayer.vue";
import { useFullWindow4to3RatioDimensionStyle } from "shared/composables/screen";

const route = useRoute();
const fullWindow4to3RatioDimensionStyle = useFullWindow4to3RatioDimensionStyle();
</script>
