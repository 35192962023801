<template>
  <div class="flex-1 flex items-stretch overflow-hidden">
    <main class="flex-1 overflow-y-auto">
      <div class="pr-2">
        <div class="flex justify-center items-center pb-2" style="min-height: 56px">
          <button
            @click="emit('dateChange', data?.previous ?? null)"
            type="button"
            :disabled="!(data && data.previous)"
            class="border-transparent inline-flex items-center px-4 py-2 text-sm font-medium"
            v-if="!loading"
          >
            <ChevronLeftIcon
              class="h-6 w-6 text-oaiGray-300"
              :class="{ 'text-transparent': !(data && data.previous) }"
              aria-hidden="true"
            />
          </button>
          <h2
            class="pl-8 pr-8 2xl:pl-12 2xl:pr-12 text-sm 2xl:text-lg font-extrabold text-oaiGray-300"
            v-if="date && !enableHeaderDateField"
          >
            {{ formatDate(date) }}
          </h2>
          <input
            type="date"
            :value="format(date, 'yyyy-MM-dd')"
            v-if="date && enableHeaderDateField"
            @change="handleHeaderDatePickerChange"
            :disabled="loading"
            :max="format(maxDate, 'yyyy-MM-dd')"
            @keydown.prevent
            class="w-full rounded-md border-gray-300 text-xs shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
            style="width: min-content !important"
          />
          <button
            @click="emit('dateChange', data?.next ?? null)"
            type="button"
            :disabled="!(data && data.next)"
            class="border-transparent inline-flex items-center px-4 py-2 text-sm font-medium"
            v-if="!loading"
          >
            <ChevronRightIcon
              class="h-6 w-6 text-oaiGray-300"
              :class="{ 'text-transparent': !(data && data.next) }"
              aria-hidden="true"
            />
          </button>
        </div>
        <div v-if="loading">
          <div
            class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 xl:gap-x-8"
          >
            <div class="loadingCard is-loading" v-for="i in 4" :key="i">
              <div
                class="image group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-50 overflow-hidden"
              />
              <h2 class="mt-2 block h-xs font-medium text-gray-900 rounded-xs w-10" />
            </div>
          </div>
        </div>
        <div v-if="!loading && showDefaultImages">
          <div
            class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 xl:gap-x-8 pb-8"
          >
            <div v-for="i in 4" :key="i">
              <img
                :src="defaultProjectThumbnailUrl"
                alt="default image"
                class="group block w-full rounded-lg bg-gray-50 overflow-hidden"
              />
            </div>
          </div>
        </div>
        <div
          :id="galleryId"
          v-show="!loading && data"
          class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-4 xl:gap-x-8"
        >
          <div v-for="(image, key) in data?.items" :key="key">
            <a
              :href="image.url"
              :data-pswp-width="image.width"
              :data-pswp-height="image.height"
              :data-timestamp="image.timestamp"
              target="_blank"
              rel="noreferrer"
            >
              <div
                class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden"
              >
                <img
                  :src="image.url"
                  class="group-hover:opacity-75 object-cover pointer-events-none"
                  alt="gallery image"
                />
              </div>
            </a>
            <div class="mt-2 block text-xs font-medium text-gray-900">
              {{ displayTime(image.timestamp) }}
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup lang="ts">
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/solid";
import { format, isValid } from "date-fns";
import { Padding } from "photoswipe";
import { PhotoSwipe } from "photoswipe/dist/types/ui/ui-element";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import { computed, onMounted, onUnmounted, Ref, ref, watch } from "vue";
import defaultProjectThumbnailUrl from "shared/assets/imgs/default-project-thumbnail.jpg";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { useCustomToast } from "shared/composables/toast";
import CameraRepository from "shared/repositories/CameraRepository";
import logger from "shared/services/logger";
import { GalleryImagesPaginatedResponse } from "shared/types/Camera";

const props = defineProps<{
  enableHeaderDateField?: boolean;
  date: Date | null;
  data: GalleryImagesPaginatedResponse | null;
  loading: boolean;
  showDefaultImages: boolean;
  cameraId: string;
  hasAdminPermissions?: boolean;
}>();

const emit = defineEmits<{ (eventName: "dateChange", payload: string | null): void }>();

const lightbox = ref(null) as Ref<PhotoSwipeLightbox | null>;

const customer = useCurrentCustomerName();
const site = useCurrentSiteId();
const toast = useCustomToast();

onUnmounted(() => {
  if (lightbox.value) {
    lightbox.value.destroy();
    lightbox.value = null;
  }
});

const galleryId = computed(() => `gallery-${customer}-${site}-${props.cameraId}`);

const maxDate = computed(() => new Date());

const mountPhotoSwipe = () => {
  if (!lightbox.value) {
    const lightboxInstance = new PhotoSwipeLightbox({
      gallery: `#${galleryId.value}`,
      children: "a",
      pswpModule: () => import("photoswipe"),
      wheelToZoom: true,
      padding: { top: 10, bottom: 10 } as Padding,
    });
    lightboxInstance.on("uiRegister", () => {
      lightboxInstance.pswp?.ui?.registerElement({
        name: "download-button",
        order: 8,
        isButton: true,
        tagName: "a",

        // SVG with outline
        html: {
          isCustomSVG: true,
          inner:
            '<path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1-1.5-1.6ZM23 23H9v2h14" id="pswp__icn-download"/>',
          outlineID: "pswp__icn-download",
        },

        onInit: (el: HTMLElement, pswp: PhotoSwipe) => {
          el.setAttribute("download", "");
          el.setAttribute("target", "_blank");
          el.setAttribute("rel", "noopener");

          pswp.on("change", () => {
            el.setAttribute("href", pswp.currSlide?.data.src as string);
          });
        },
      });

      if (props.hasAdminPermissions) {
        lightboxInstance.pswp?.ui?.registerElement({
          name: "annotation-button",
          order: 7,
          isButton: true,
          tagName: "p",
          className: "!w-auto !flex !items-center",
          html: '<p class="px-3 py-2 rounded-md bg-yellow-600 text-white text-xs whitespace-nowrap">Add for CV Dataset</p>',

          onClick(e, el, pswp) {
            const timestamp = pswp.currSlide?.data?.element?.dataset?.timestamp;

            if (!timestamp) {
              return;
            }
            const formattedTimestamp = formatTimestampForPersonDataset(timestamp);

            CameraRepository.addPersonImageToDatasetBuffer(
              customer as string,
              site as string,
              props.cameraId,
              formattedTimestamp,
            )
              .then(() => {
                toast.success("Image successfully uploaded");
              })
              .catch((error) => {
                logger.error(error);
                toast.error("Failed to upload image");
              });
          },
        });
      }
    });

    lightboxInstance.init();
    lightbox.value = lightboxInstance;
  }
};

const displayTime = (value: string) => {
  const date = new Date(value);
  return isValid(date) ? format(new Date(value), "HH:mm") : "";
};

const handleHeaderDatePickerChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  emit("dateChange", target.value);
};

const formatDate = (dateValue: string | Date) => {
  const dateObject = typeof dateValue === "string" ? new Date(dateValue) : dateValue;
  return format(dateObject, "dd.MM.yyyy");
};

const formatTimestampForPersonDataset = (timestamp: string) =>
  format(new Date(timestamp), "yyyy-MM-dd_HHmmss");

watch(
  () => props.data,
  () => {
    mountPhotoSwipe();
  },
);

onMounted(() => {
  mountPhotoSwipe();
});
</script>
