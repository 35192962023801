import { Organization } from "shared/types/Organization";
import { User } from "shared/types/User";

export const calculateIndentation = (indentation: number) => indentation * 24 + 16;

export const getUsersForOrganizationAndChildren = (
  organizationId: string,
  organizationsByParentId: Record<string, Organization[] | undefined>,
  usersByOrganizationId: Record<string, User[] | undefined>,
): User[] => {
  const result = (usersByOrganizationId[organizationId] || []).slice();
  const resultIds = new Set<string>(result.map((user) => user._id));
  const children = organizationsByParentId[organizationId] || [];
  for (const child of children) {
    const childResults = getUsersForOrganizationAndChildren(
      child._id,
      organizationsByParentId,
      usersByOrganizationId,
    );
    for (const childResult of childResults) {
      if (!resultIds.has(childResult._id)) {
        result.push(childResult);
        resultIds.add(childResult._id);
      }
    }
  }
  return result;
};
