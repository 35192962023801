<template>
  <Modal :open="true" @close="emit('close')" custom-cls="sm:w-full sm:max-w-lg">
    <template #title>
      <div>
        <h3 class="pt-2 text-lg font-medium leading-6 text-gray-900">
          {{ user.name }}
        </h3>
        <small class="truncate text-gray-400"> {{ user.email }} </small>
      </div>
    </template>
    <template #content>
      <div>
        <Form
          @submit="handleUpdateProjectPermissions"
          :initialValues="initialValues"
          :validationSchema="projectPermissionSchema"
          class="flex flex-col gap-2 w-full"
        >
          <SelectListField
            v-if="!hideCompanyType || !initialValues.company_type"
            name="company_type"
            :label="t('admin.project_participants.company_type')"
            :options="companyTypesList"
            :minWidth="250"
            :placeholder="`--
              ${t('form.select_option')} --`"
          />
          <hr class="my-4" v-if="!hideCompanyType || !initialValues.company_type" />
          <FieldArray name="groups" v-slot="{ fields, update }">
            <div
              v-for="(field, idx) in fields as FieldEntry<PermissionBoxEntry>[]"
              :key="idx"
              class="py-1"
            >
              <div v-if="field.value.type === 'live_camera_feature_user'" class="mb-4">
                <hr />
              </div>
              <CheckboxField
                v-if="field.value.type !== 'project_base'"
                :name="`groups[${idx}].enabled`"
                :label="t(`permissions.${field.value.type}`)"
                @change="
                  onCheckboxChange(
                    update,
                    field.value.type,
                    fields as FieldEntry<PermissionBoxEntry>[],
                  )
                "
              />
            </div>
          </FieldArray>

          <div class="pt-4 flex items-center justify-center">
            <button
              type="submit"
              class="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-yellow-600 px-8 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            >
              <ArrowPathIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              {{ t("admin.project_participants.modal_permission.update") }}
            </button>
          </div>
        </Form>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { ArrowPathIcon } from "@heroicons/vue/24/solid";
import { FieldArray, FieldEntry, Form, SubmissionHandler } from "vee-validate";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import SelectListField from "shared/components/forms/SelectListField.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { companyTypes } from "shared/constants/user";
import { ProjectFeature } from "shared/types/Project";
import { User, UserProject, UserProjectPermissionGroup } from "shared/types/User";
import CheckboxField from "@/components/forms/CheckboxField.vue";
import { useCurrentProject } from "@/composables/project";
import {
  userProjectPermissionsOrdered,
  useGetSortedFeaturePermissionsForUser,
  useUpdateUserProjectPermission,
} from "@/composables/user";

type PermissionBoxEntry = {
  type: UserProjectPermissionGroup;
  enabled: boolean;
};

const props = defineProps<{ user: User; hideCompanyType?: boolean }>();
const emit = defineEmits<{ (eventName: "close"): void }>();

const { t } = useI18n();

const permissionsToFeature = {
  all_camera_features_user: "camera",
  live_camera_feature_user: "camera",
  planner_user: "planner",
  processes_user: "processes",
  daily_report_user: "daily_report",
  site_activity_user: "site_activity",
} as Record<UserProjectPermissionGroup, ProjectFeature>;

const companyTypesList = companyTypes.map((type) => ({
  value: type,
  name: t(`admin.project_participants.company_types.${type}`),
}));

const currentProject = useCurrentProject();
const customerName = useCurrentCustomerName();
const siteId = useCurrentSiteId();
const { updateUserProjectPermission } = useUpdateUserProjectPermission();
const getSortedFeaturePermissionsForUser = useGetSortedFeaturePermissionsForUser();

const projectPermissionSchema = yup.object().shape({
  company_type: yup.string().required(t("authentication.validation.validation_field_required")),
  groups: yup.array().of(
    yup.object().shape({
      enabled: yup.boolean().required(),
    }),
  ),
});

const initializePermissionsForUser = (user: User) => {
  const currentFeaturesSelected = getSortedFeaturePermissionsForUser(user);
  const projectFeatures = Object.values(currentProject.features);

  const selectedPermissions = userProjectPermissionsOrdered
    .filter(
      (permission) =>
        permission === "project_admin" ||
        permission === "project_base" ||
        projectFeatures.includes(permissionsToFeature[permission]),
    )
    .map((item) => {
      return {
        type: item,
        enabled: currentFeaturesSelected.some((feat) => feat === item),
      };
    });

  const project = user.projects.find(
    (project: UserProject) => project.customer_name === customerName && project.site_id === siteId,
  );

  return {
    groups: selectedPermissions,
    company_type: (project && project.company_type) || "",
  };
};

const initialValues = computed(() => initializePermissionsForUser(props.user));

const onCheckboxChange = (
  update: (idx: number, value: PermissionBoxEntry) => void,
  typeClicked: string,
  fields: { value: PermissionBoxEntry }[],
) => {
  for (let i = 0; i < fields.length; i++) {
    if (
      typeClicked === "live_camera_feature_user" &&
      !["live_camera_feature_user", "project_base"].includes(fields[i].value.type)
    ) {
      update(i, { type: fields[i].value.type, enabled: false });
    }
    if (
      typeClicked !== "live_camera_feature_user" &&
      fields[i].value.type === "live_camera_feature_user"
    ) {
      update(i, { type: fields[i].value.type, enabled: false });
    }
  }
};

const handleUpdateProjectPermissions: SubmissionHandler = async (genericObject) => {
  const groups = genericObject.groups
    .filter((group: PermissionBoxEntry) => group.enabled)
    .map((group: PermissionBoxEntry) => group.type);

  await updateUserProjectPermission({
    username: props.user.username,
    updatedProjectPermission: {
      customer_name: customerName,
      site_id: siteId,
      company_type: genericObject.company_type,
      explicit_groups: groups,
      groups: [],
    },
  })
    .then(() => {
      emit("close");
    })
    .catch(() => undefined);
};
</script>
