<template>
  <Modal :open="open" @close="emit('close')" customCls="h-fit max-w-96 w-full">
    <template #content>
      <Form
        @submit="submitIssueReport"
        :validationSchema="schema"
        v-slot="{ meta }"
        :initialValues="{ date: date, processClass: null }"
      >
        <h4 class="text-lg font-bold mb-6 text-center">
          {{ t("issue_report.report_missing_process") }}
        </h4>

        <div class="flex flex-col gap-4">
          <div class="pb-4 border-b border-gray-200">
            <p class="text-sm">{{ t("issue_report.location") }}:</p>
            <p class="text-sm text-gray-600">{{ locationName }}</p>
          </div>

          <div>
            <p class="text-sm">{{ t("issue_report.issue_date") }}:</p>

            <DateField name="date" />
          </div>

          <div>
            <p class="text-sm">{{ t("issue_report.issue_process_class") }}:</p>
            <SearchListField
              name="processClass"
              :placeholder="t('report.general.empty_combobox')"
              :options="
                processClasses.map((processClass) => ({
                  value: processClass.encodedLabel,
                  name: t(`process_classes.${processClass.encodedLabel}`),
                }))
              "
            />
          </div>
        </div>

        <div class="flex justify-end mt-8">
          <MainButton :isDisabled="!meta.valid || isSubmitting" type="submit" color="yellow">
            {{ t("buttons.submit") }}
            <LoadingSpinner cls="h-4 w-4 ml-3 text-white" v-if="isSubmitting" />
          </MainButton>
        </div>
      </Form>
    </template>
  </Modal>
</template>

<script lang="ts" setup>
import { format } from "date-fns";
import { Form, SubmissionHandler } from "vee-validate";
import { computed, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import logger from "shared/services/logger";
import DateField from "@/components/forms/DateField.vue";
import SearchListField from "@/components/forms/SearchListField.vue";
import MainButton from "@/components/other/MainButton.vue";
import { useProcessClasses } from "@/composables/process";
import IssueReportRepository from "@/repositories/IssueReportRepository";
import { TagGroupedProcess } from "@/views/process_gantt/groupProcesses";

const { t } = useI18n();

const schema = yup.object().shape({
  processClass: yup.number().required(t("issue_report.validation.process_class_required")),
  date: yup.date().required(t("issue_report.validation.date_required")),
});

type FormValues = yup.InferType<typeof schema>;

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  tags: {
    type: Object as PropType<TagGroupedProcess["tags"] | null>,
  },
  date: {
    type: Date as PropType<Date | null>,
  },
});

const processClasses = useProcessClasses();
const customerName = useCurrentCustomerName();
const siteId = useCurrentSiteId();

const isSubmitting = ref(false);

const emit = defineEmits<{
  (event: "close"): void;
}>();

const locationName = computed(() => {
  const locationString = [
    props.tags?.building,
    props.tags?.level,
    props.tags?.section,
    props.tags?.component,
  ]
    .filter(Boolean)
    .join(" > ");

  return locationString;
});

const submitIssueReport: SubmissionHandler = async (genericValues) => {
  if (isSubmitting.value) {
    return;
  }

  const values = genericValues as FormValues;

  let message = `Issue type: Missing Process`;
  message += `\n\nLocation: ${locationName.value}`;
  message += `\nDate: ${format(values.date, "dd.MM.yyyy")}`;
  message += `\nProcess: ${t(`process_classes.${values.processClass}`)}`;

  isSubmitting.value = true;

  try {
    await IssueReportRepository.createIssueReport(customerName, siteId, {
      type: "process",
      entry_id: "",
      report_message: message,
    });
  } catch (error) {
    logger.error(error);
  }

  isSubmitting.value = false;

  emit("close");
};
</script>
