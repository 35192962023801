<template>
  <div
    class="divide-y divide-gray-200 overflow-y-auto overflow-x-hidden border-l border-r"
    v-if="processes.length"
  >
    <div
      v-for="process in processes"
      :key="process._id"
      class="grid grid-cols-7 items-center justify-between gap-3 relative py-1 w-full pl-3 pr-1 hover:bg-gray-50"
    >
      <label
        :for="t(`process_classes.${process.encoded_label}`)"
        class="text-gray-500 font-light min-w-0 col-span-2 text-xs truncate"
        :title="t(`process_classes.${process.encoded_label}`)"
      >
        {{ t(`process_classes.${process.encoded_label}`) }}
      </label>
      <small
        class="truncate cursor-default min-w-0 col-span-3"
        :title="process?.tags ? getLocation(process?.tags) : ''"
      >
        {{ process?.tags ? getLocation(process?.tags) : "" }}
      </small>
      <small class="font-light leading-none whitespace-nowrap self-center col-span-1">
        {{ format(process.date, "dd.MM.yyyy") }}
      </small>
      <div class="justify-self-end flex gap-2 col-span-1">
        <OaiTooltip
          position="left"
          :simple="false"
          @show="nextTick(() => initSectionMasksCanvas(process))"
          @hide="disposeSectionMasksCanvas(process._id)"
        >
          <div
            @mouseover="
              isHovered = process._id;
              getThumbnail(process);
            "
          >
            <InformationCircleIcon class="size-5 text-green hover:text-green-700" />
          </div>

          <template #tooltip>
            <div class="p-4 flex flex-col content-center gap-3 w-80 bg-white border shadow-md">
              <h2 class="break-words">{{ process?.tags ? getLocation(process?.tags) : "" }}</h2>
              <small class="text-sm plannedBg rounded px-1.5 py-0.5 w-min text-gray">{{
                format(process.date, "dd.MM.yyyy")
              }}</small>
              <label
                :for="process._id"
                class="text-gray-900 truncate"
                :title="t(`process_classes.${process.encoded_label}`)"
              >
                {{ t(`process_classes.${process.encoded_label}`) }}
              </label>

              <small class="font-light leading-none whitespace-nowrap">
                {{ format(process?.start_time, "HH:mm") }} -
                {{ format(process?.end_time, "HH:mm") }}
              </small>
              <div class="relative">
                <img
                  v-if="isHovered === process._id"
                  :src="thumbnailUrl || defaultThumbnailUrl"
                  alt="Thumbnail"
                  class="mx-auto"
                />
                <canvas
                  class="absolute top-0 left-0 w-full h-full"
                  :id="`section_mask_canvas_${process._id}`"
                />
              </div>
            </div>
          </template>
        </OaiTooltip>
        <button class="flex items-center" @click="removeProcessFromSelected(process)">
          <XMarkIcon class="size-4 rounded text-gray-400 hover:text-yellow-600" />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { XMarkIcon, InformationCircleIcon } from "@heroicons/vue/24/outline";
import { format } from "date-fns";
import * as fabric from "fabric";
import { nextTick, onUnmounted, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import defaultThumbnailUrl from "shared/assets/imgs/default-project-thumbnail.jpg";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { useSectionMasks } from "shared/composables/sectionMasks";
import { HierarchyType } from "shared/types/HierarchyTag";
import { ShortenedProcessWithTags } from "shared/types/Process";
import OpsProcessesRepository from "@/repositories/OpsProcessesRepository";

defineProps({
  processes: {
    type: Array as PropType<ShortenedProcessWithTags[]>,
    required: true,
  },
});

const { t } = useI18n();
const emit = defineEmits(["updateProcessList"]);
const isHovered = ref();

const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();
const { sectionMasks } = useSectionMasks();
const thumbnailUrl = ref<string>("");

const initiatedSectionMasksCanvases: Record<string, fabric.StaticCanvas> = {};

onUnmounted(() => {
  disposeSectionMasksCanvas();
});

const removeProcessFromSelected = (process: ShortenedProcessWithTags) => {
  emit("updateProcessList", process._id);
};

const getThumbnail = async (process: ShortenedProcessWithTags) => {
  try {
    const blob = await OpsProcessesRepository.loadProcessThumbnail(
      currentCustomerName,
      currentSiteId,
      process._id,
    );

    thumbnailUrl.value = blob ? URL.createObjectURL(blob) : defaultThumbnailUrl;
  } catch (error) {
    thumbnailUrl.value = defaultThumbnailUrl;
  }
};

const getLocation = (tags: Record<HierarchyType, string>) => {
  return Object.values(tags)
    .filter((value) => value)
    .join(" | ");
};

const initSectionMasksCanvas = (process: ShortenedProcessWithTags) => {
  const isCanvasInitiated = !!initiatedSectionMasksCanvases[process._id];
  const sectionMask = sectionMasks.value?.find((mask) => {
    return mask._id === process.section_mask_mapping.id;
  });

  if (!sectionMask || isCanvasInitiated) {
    return;
  }

  const canvasId = `section_mask_canvas_${process._id}`;
  const canvasElement = document.querySelector(`#${canvasId}`);
  const canvas = new fabric.StaticCanvas(canvasId, {
    width: canvasElement?.clientWidth || 0,
    height: canvasElement?.clientHeight || 0,
  });

  const maskPath = new fabric.Path(
    sectionMask.mask
      .flat()
      .map(([x, y], index) => {
        return `${index === 0 ? "M" : "L"} ${x * canvas.width} ${y * canvas.height}`;
      })
      .join(" ") + " Z",
  );

  const opacity = 0.2;
  const fillColor = `${sectionMask.color.slice(0, -4)}${opacity})`;

  maskPath.set({
    fill: fillColor,
    stroke: sectionMask.color,
    strokeWidth: 2,
  });

  canvas.add(maskPath);

  const coords = maskPath.getCenterPoint();
  const text = new fabric.Text(
    `${process.section_mask_mapping.level_name} - ${process.section_mask_mapping.section_name}`,
    {
      fontSize: 14,
      strokeWidth: 4,
      stroke: "#000",
      fill: "#fff",
      fontFamily: "arial",
      fontWeight: "bold",
      paintFirst: "stroke",
    },
  );

  canvas.add(text);
  text.set({
    left: coords.x - text.width / 2,
    top: coords.y - text.height / 2,
  });

  canvas.renderAll();
  initiatedSectionMasksCanvases[process._id] = canvas;
};

const disposeSectionMasksCanvas = (id?: string) => {
  if (id) {
    const canvas = initiatedSectionMasksCanvases[id];
    if (canvas) {
      canvas.dispose();
      delete initiatedSectionMasksCanvases[id];
    }
    return;
  }

  Object.keys(initiatedSectionMasksCanvases).forEach((key) => {
    initiatedSectionMasksCanvases[key].dispose();
    delete initiatedSectionMasksCanvases[key];
  });
};
</script>
