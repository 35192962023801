<template>
  <Sidebar isHome>
    <Projects />
  </Sidebar>
</template>

<script setup lang="ts">
import { useDisableScrollbarsOnBody } from "shared/composables/screen";
import Sidebar from "@/components/layout/Sidebar.vue";
import Projects from "@/views/dashboard/components/Projects.vue";

useDisableScrollbarsOnBody();
</script>
