import { HierarchyTagContext, HierarchyTagStore } from "shared/types/HierarchyTag";
import {
  createHierarchyTagContext,
  createIsMatchingLocationFn,
} from "shared/views/planner/hierarchyTags";
import { UnitValue, UnitValueTag, UnitValueType } from "@/types/UnitValue";
import {
  QueryValueAggregation,
  QueryValueContext,
  QueryValueSingleValue,
  QueryValueSingleValueContextForUnitValue,
} from "@/types/reports/PlotQueryValue";
import { calculateRowTag } from "@/views/unit_values/services/unitValues";

const calculateTagScore = (
  unitValue: UnitValue,
  hierarchyTagContext: HierarchyTagContext,
  property: keyof UnitValueTag,
) => {
  const numbers = unitValue.tags
    .map((tag) => {
      const tagId = tag[property];
      if (!tagId) {
        return null;
      }
      const hierarchyTag = hierarchyTagContext.tagsById[tagId];
      if (!hierarchyTag) {
        return null;
      }
      return hierarchyTag.number;
    })
    .filter((number) => number !== null) as number[];
  return numbers.length > 0 ? Math.min(...numbers) : 0;
};

const calculateSortScore = (unitValue: UnitValue, hierarchyTagContext: HierarchyTagContext) => {
  const buildingScore = calculateTagScore(unitValue, hierarchyTagContext, "building_id");
  const levelScore = calculateTagScore(unitValue, hierarchyTagContext, "level_id");
  const sectionScore = calculateTagScore(unitValue, hierarchyTagContext, "section_id");
  return buildingScore * 1000000 + levelScore * 1000 + sectionScore;
};

const getUnitValues = (
  type: UnitValueType,
  unitValues: UnitValue[],
  hierarchyTags: HierarchyTagStore[],
  filterTagIds: string[],
) => {
  const hierarchyTagContext = createHierarchyTagContext(hierarchyTags);
  const isMatchingLocation = createIsMatchingLocationFn(filterTagIds, hierarchyTags);
  const filteredUnitValues = unitValues.filter(
    (unitValue) =>
      unitValue.state === "finished" &&
      unitValue.actual_end &&
      unitValue.type === type &&
      unitValue.value !== null &&
      unitValue.tags.some((tag) =>
        isMatchingLocation({
          building: tag.building_id,
          level: tag.level_id,
          section: tag.section_id,
        }),
      ),
  );
  const sortedUnitValues = filteredUnitValues.map((unitValue) => ({
    ...unitValue,
    sortScore: calculateSortScore(unitValue, hierarchyTagContext),
  }));
  sortedUnitValues.sort((a, b) => {
    if (!a.actual_end || !b.actual_end) {
      return -1;
    }
    if (a.actual_end.getTime() === b.actual_end.getTime()) {
      return a.sortScore - b.sortScore;
    }
    return a.actual_end.getTime() - b.actual_end.getTime();
  });
  return sortedUnitValues;
};

const calculateAggregatedValue = (unitValues: UnitValue[], aggregation: QueryValueAggregation) => {
  const values = unitValues.map((unitValue) => unitValue.value) as number[];
  if (values.length === 0) {
    return null;
  }
  const sum = () => values.reduce((acc, value) => acc + value, 0);
  const aggregators: Record<QueryValueAggregation, () => number | null> = {
    sum,
    median: () => {
      const sortedValues = values.slice().sort((a, b) => a - b);
      const half = Math.floor(sortedValues.length / 2);
      return sortedValues.length % 2
        ? sortedValues[half]
        : (sortedValues[half - 1] + sortedValues[half]) / 2;
    },
    latest: () => values[values.length - 1],
    average: () => sum() / values.length,
  };
  return aggregators[aggregation]();
};

const calculateUnitValueMetric = (context: QueryValueContext): QueryValueSingleValue | null => {
  const { unitValues, hierarchyTags, filters, config, aggregation } = context;

  if (!unitValues || hierarchyTags.length === 0 || !filters.unit_value_type) {
    return null;
  }

  const filteredUnitValues = getUnitValues(
    filters.unit_value_type,
    unitValues,
    hierarchyTags,
    filters.location,
  );
  const value = calculateAggregatedValue(filteredUnitValues, aggregation);
  const previousPeriodUnitValue =
    config.show_previous_period && aggregation === "latest"
      ? filteredUnitValues[filteredUnitValues.length - 2]
      : undefined;
  const previousPeriodValue = previousPeriodUnitValue?.value ?? null;
  return {
    value,
    previous_period: {
      value: previousPeriodValue,
    },
    context: {
      unitValue:
        aggregation === "latest" ? filteredUnitValues[filteredUnitValues.length - 1] ?? null : null,
      previousPeriodUnitValue: previousPeriodUnitValue ?? null,
    },
  };
};

const getUnitValueLocation = (
  unitValue: UnitValue | null,
  hierarchyTagContext: HierarchyTagContext,
) => {
  if (!unitValue) {
    return null;
  }
  const unitValueRowTag = calculateRowTag(unitValue, hierarchyTagContext);
  return [unitValueRowTag.building, unitValueRowTag.level, unitValueRowTag.section]
    .filter((item) => item)
    .join(", ");
};

export const getUnitValueContextLocation = (
  singleValueData: QueryValueSingleValue,
  hierarchyTagContext: HierarchyTagContext,
) => {
  const unitValueContext = singleValueData.context as
    | QueryValueSingleValueContextForUnitValue
    | undefined;
  if (!unitValueContext) {
    return null;
  }
  return {
    location: getUnitValueLocation(unitValueContext.unitValue, hierarchyTagContext),
    previousPeriodLocation: getUnitValueLocation(
      unitValueContext.previousPeriodUnitValue,
      hierarchyTagContext,
    ),
  };
};

export default calculateUnitValueMetric;
