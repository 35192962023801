<template>
  <Disclosure v-slot="{ open }" :defaultOpen="true">
    <DisclosureButton
      class="flex items-center py-1 pl-2 pr-3.5 h-[50px] bg-gray-100 border-b"
      :class="hideFirstTopBorder ? '' : 'border-t'"
      :style="{ paddingLeft: `${calculateIndentation(indentation)}px` }"
    >
      <ChevronRightIcon :class="{ 'rotate-90': open }" class="w-5 h-5 shrink-0" />
      <div class="font-bold text-gray-900 px-1 truncate">
        {{ organization.name }} ({{ usersForOrganizationAndChildren.length }})
      </div>
      <PlusIcon
        class="w-5 h-5 cursor-pointer text-gray-600 shrink-0 ml-1"
        v-if="hasOrganizationAdminPermission"
        @click.stop="isInviteUsersModalOpen = true"
      />
      <div class="flex-1" />
      <OrganizationLogo
        :organizationId="organization._id"
        cls="h-full border bg-white rounded p-[6px] min-w-0 shadow-md"
      />
    </DisclosureButton>
    <DisclosurePanel>
      <OrganizationTabUsersList
        :users="users"
        :organization="organization"
        :indentation="indentation + 1"
        :organizationsByParentId="organizationsByParentId"
      />
      <div class="flex flex-col" v-if="children.length > 0">
        <OrganizationTabUsersHierarchyItem
          v-for="childOrganization in children"
          :key="childOrganization._id"
          :organization="childOrganization"
          :organizationsByParentId="organizationsByParentId"
          :usersByOrganizationId="usersByOrganizationId"
          :indentation="indentation + 1"
        />
      </div>
    </DisclosurePanel>
    <InviteUsersModal
      v-if="isInviteUsersModalOpen"
      :existingUsers="users"
      :organizationId="organization._id"
      @close="isInviteUsersModalOpen = false"
    />
  </Disclosure>
</template>

<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { PlusIcon } from "@heroicons/vue/24/solid";
import { computed, defineProps, ref } from "vue";
import { useHasOrganizationPermission } from "shared/composables/organization";
import { Organization } from "shared/types/Organization";
import { User } from "shared/types/User";
import InviteUsersModal from "@/components/users/InviteUsersModal.vue";
import OrganizationLogo from "@/views/organizations/components/OrganizationLogo.vue";
import OrganizationTabUsersHierarchyItem from "@/views/organizations/components/OrganizationTabUsersHierarchyItem.vue";
import OrganizationTabUsersList from "@/views/organizations/components/OrganizationTabUsersList.vue";
import {
  calculateIndentation,
  getUsersForOrganizationAndChildren,
} from "@/views/organizations/organizations";

const props = withDefaults(
  defineProps<{
    organization: Organization;
    organizationsByParentId: Record<string, Organization[] | undefined>;
    usersByOrganizationId: Record<string, User[] | undefined>;
    indentation?: number;
    hideFirstTopBorder?: boolean;
  }>(),
  { indentation: 0 },
);

const isInviteUsersModalOpen = ref(false);

const hasOrganizationAdminPermission = useHasOrganizationPermission(
  "organization_admin",
  props.organization._id,
);

const children = computed(() => {
  const items = props.organizationsByParentId[props.organization._id] || [];
  return items.slice().sort((a, b) => a.name.localeCompare(b.name));
});

const users = computed(() => props.usersByOrganizationId[props.organization._id] || []);

const usersForOrganizationAndChildren = computed(() =>
  getUsersForOrganizationAndChildren(
    props.organization._id,
    props.organizationsByParentId,
    props.usersByOrganizationId,
  ),
);
</script>
