<template>
  <div
    @click="editMode ? emit('addHighlight') : ''"
    :class="[
      {
        'shadow-lg mix-blend-normal bg-blend-color-dodge': editMode,
        'border-yellow': highlightSection === 'disturbances',
      },
      'my-4 bg-white shadow sm:rounded-lg md:px-5 px-1 py-6 border',
    ]"
  >
    <div class="my-2 md:mb-4">
      <div class="flex items-center justify-between px-2">
        <h3 class="md:text-lg leading-4 font-semibold text-gray-900">
          {{ t("disturbances.disturbance", 2) }}
        </h3>
        <div v-if="!approved">
          <PencilIcon
            v-if="!editMode"
            class="w-4 h-4 text-oaiGray-300 cursor-pointer ml-3"
            @click="activeEditMode"
          />
        </div>
      </div>
      <div v-if="approved && !disturbancesToShow.some((d) => d.show)">
        <p class="md:text-center pl-2 pt-2 md:p-0 md:text-base text-sm">
          {{ t("report.general.section_empty") }}
        </p>
      </div>
    </div>
    <div :class="[editMode ? 'editInput' : 'overviewInput']">
      <div
        class="py-1 hidden md:flex gap-2 md:gap-4 px-2"
        v-if="disturbancesToShow.some((d) => d.show)"
      >
        <p class="font-medium truncate text-gray-600 w-3/12">
          {{ t("report.sections.disturbances.name") }}
        </p>
        <p class="font-medium truncate text-gray-600 w-2/12">
          {{ t("report.sections.disturbances.type") }}
        </p>
        <p class="font-medium truncate text-gray-600 w-2/12">
          {{ t("report.sections.disturbances.date_range") }}
        </p>
        <p class="font-medium truncate text-gray-600 w-3/12">
          {{ t("report.sections.disturbances.description") }}
        </p>
        <p class="font-medium truncate text-gray-600 w-2/12">
          {{ t("working_day.inactive_days", 1) }}?
        </p>
      </div>

      <div v-for="(disturbance, i) in disturbancesToShow" :key="i">
        <div
          class="flex flex-wrap md:flex-nowrap px-2 py-2 gap-2 md:gap-4 relative tableRow items-center border-t border-gray-200"
          v-if="disturbance.show"
        >
          <div class="w-3/12 relative">
            <input
              v-if="editMode"
              v-model="disturbance.name"
              type="text"
              :placeholder="t('report.sections.disturbances.name')"
              :class="{ 'oai-inputs': editMode }"
            />
            <p v-else class="text-sm">
              {{ disturbance.name || "-" }}
            </p>
            <small
              v-if="validationError && !disturbance.name"
              class="text-red absolute left-0"
              style="top: calc(100% + 4px)"
            >
              {{ t("err.required") }}
            </small>
          </div>
          <div class="w-2/12">
            <CustomOptionsCombobox
              :disabled="!editMode"
              :options="
                disturbances.map((d) => ({
                  name: te(`disturbances.type.${d}`) ? t(`disturbances.type.${d}`) : d,
                  value: d,
                }))
              "
              :selected="disturbance.disturbance_type as string"
              :error="validationError && !disturbance.disturbance_type"
              @update="disturbancesToShow[i].disturbance_type = $event.value"
            />
          </div>
          <div class="w-2/12">
            <VueDatePicker
              :columns="4"
              :placeholder="`${t('report.bulk_export.main_label')}`"
              :locale="locale"
              format="dd.MM.yyyy"
              autoApply
              :modelValue="[disturbance.start_date, disturbance.end_date]"
              range
              :enableTimePicker="false"
              :disabled="!editMode"
              @update:modelValue="handleDisturbanceDateRangeChange($event, i)"
            >
              <template #trigger>
                <p
                  :class="[
                    'rounded-md text-xs py-2 bg-white flex justify-between',
                    editMode && 'shadow-sm cursor-pointer border border-gray-300 px-3',
                  ]"
                >
                  <span>
                    {{ formatDate(disturbance.start_date) }} -
                    {{ formatDate(disturbance.end_date) }}
                  </span>
                  <CalendarIcon v-if="editMode" class="w-4 h-4 text-gray-400" />
                </p>
              </template>
            </VueDatePicker>
          </div>
          <div class="w-3/12 flex">
            <textarea
              v-model="disturbance.disturbance_description"
              rows="2"
              name="note"
              v-if="editMode"
              :placeholder="t('report.general.notes_placeholder')"
              :class="[{ 'oai-inputs': editMode }, 'col-span-2']"
            />
            <p v-else class="text-xs">
              {{ disturbance.disturbance_description || "-" }}
            </p>
          </div>
          <div class="w-2/12 flex">
            <Switch
              v-model="disturbance.is_critical"
              :class="[
                'group relative inline-flex h-5 w-10 shrink-0 items-center justify-center rounded-full focus:outline-none mr-2',
                editMode && 'cursor-pointer',
              ]"
              @update:modelValue="!editMode && activeEditMode()"
            >
              <span
                aria-hidden="true"
                class="pointer-events-none absolute h-full w-full rounded-md"
              />
              <span
                aria-hidden="true"
                :class="[
                  disturbance.is_critical ? 'bg-yellow-500' : 'bg-gray-200',
                  'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                ]"
              />
              <span
                aria-hidden="true"
                :class="[
                  disturbance.is_critical ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                ]"
              />
            </Switch>
          </div>
          <button class="w-6 order-first md:order-last">
            <XMarkIcon
              v-if="editMode"
              :class="{ 'w-6 text-red-900 hover:text-red': editMode }"
              @click="handleDeleteDisturbance(disturbance, i)"
            />
          </button>
        </div>
      </div>
      <div class="flex justify-center w-full md:mt-6">
        <button
          @click="addNewDisturbance"
          v-if="(editMode || !disturbancesToShow.some((d) => d.show)) && !approved"
          type="button"
          class="relative inline-flex items-center p-2 shadow-sm border-2 border-yellow rounded-full text-yellow-700 hover:bg-yellow-700 hover:text-white focus:outline-none"
        >
          <PlusIcon class="w-6" />
        </button>
      </div>
    </div>
    <div :class="['flex justify-center', { 'mt-5': editMode }]"></div>
  </div>
</template>

<script lang="ts" setup>
import { Switch } from "@headlessui/vue";
import { PlusIcon, PencilIcon, XMarkIcon, CalendarIcon } from "@heroicons/vue/24/solid";
import VueDatePicker from "@vuepic/vue-datepicker";
import { format } from "date-fns";
import deepEqual from "deep-equal";
import { watch, PropType, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useConfirmationModal } from "shared/composables/toast";
import { NonWorkingDay } from "shared/types/ProjectDurationSettings";
import CustomOptionsCombobox from "@/components/other/CustomOptionsCombobox.vue";
import { disturbances } from "@/constants/disturbances";

const props = defineProps({
  nonWorkingDays: {
    type: Array as PropType<NonWorkingDay[]>,
    required: true,
  },
  approved: {
    type: Boolean,
    required: true,
  },
  editMode: {
    type: Boolean,
    required: true,
  },
  highlightSection: {
    type: String,
    required: true,
  },
  date: {
    type: Date,
    required: true,
  },
  validationError: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["addHighlight", "activeEditMode"]);
const showConfirmationModal = useConfirmationModal();

const disturbancesToShow = ref<(NonWorkingDay & { show: boolean })[]>([]);

watch(
  () => props.nonWorkingDays,
  (value) => {
    const currentDate = format(props.date, "yyyy-MM-dd");
    disturbancesToShow.value = value.map((nonWorkingDay) => {
      const rangeStart = format(nonWorkingDay.start_date, "yyyy-MM-dd");
      const rangeEnd = format(nonWorkingDay.end_date, "yyyy-MM-dd");

      const shouldShow =
        nonWorkingDay.type === "disturbance" &&
        rangeStart <= currentDate &&
        rangeEnd >= currentDate;

      return {
        ...nonWorkingDay,
        show: shouldShow,
      };
    });
  },
  { immediate: true },
);

const { t, te, locale } = useI18n();

const areDisturbancesChanges = computed(() => {
  return !deepEqual(
    disturbancesToShow.value.map((disturbance) => {
      const { show, ...rest } = disturbance;
      return rest;
    }),
    props.nonWorkingDays,
  );
});

const activeEditMode = () => {
  emit("addHighlight");
  emit("activeEditMode");
};

const addNewDisturbance = () => {
  activeEditMode();

  disturbancesToShow.value = [
    ...disturbancesToShow.value,
    {
      name: "",
      type: "disturbance",
      start_date: props.date,
      end_date: props.date,
      disturbance_type: "",
      disturbance_description: "",
      is_critical: false,
      show: true,
    },
  ];
};

const handleDisturbanceDateRangeChange = (value: [Date, Date] | [null, null], index: number) => {
  if (!value[0] || !value[1]) {
    return;
  }

  const updatedDisturbances = disturbancesToShow.value.map((disturbance, i) => {
    if (i === index) {
      return {
        ...disturbancesToShow.value[index],
        start_date: value[0],
        end_date: value[1],
      };
    }

    return disturbance;
  });

  disturbancesToShow.value = updatedDisturbances;
};

const handleDeleteDisturbance = async (disturbance: NonWorkingDay, index: number) => {
  let isConfirmed = true;
  if (disturbance.start_date !== disturbance.end_date) {
    isConfirmed = await showConfirmationModal({
      message: t("report.sections.disturbances.confirmation_message"),
      header: t("report.sections.disturbances.confirmation_header"),
      confirmAction: t("report.sections.disturbances.delete_action"),
      cancelAction: t("report.sections.disturbances.cancel_action"),
    });
  }

  if (isConfirmed) {
    const updatedDisturbances = disturbancesToShow.value.filter((_, i) => i !== index);
    disturbancesToShow.value = updatedDisturbances;
  }
};

const formatDate = (date: Date) => {
  return format(date, "dd.MM.yyyy");
};

const getDisturbances = () => {
  return disturbancesToShow.value.map((disturbance) => {
    const { show, ...rest } = disturbance;
    return rest;
  });
};

const areDisturbancesValid = () => {
  return disturbancesToShow.value.every((disturbance) => {
    return disturbance.show
      ? disturbance.name &&
          disturbance.disturbance_type &&
          disturbance.start_date <= disturbance.end_date
      : true;
  });
};

defineExpose({
  getData: getDisturbances,
  isValid: areDisturbancesValid,
  isDirty: areDisturbancesChanges,
});
</script>
