<template>
  <Modal :open="true" @close="emit('close')" customCls="w-full m-2 lg:w-1/3">
    <template #title>
      <div class="truncate flex gap-1 text-sm items-center justify-center font-semibold">
        <HierarchyTagItem :key="tag._id" v-for="tag in tags" :tag="tag" />
      </div>
    </template>
    <template #content>
      <div class="flex flex-col gap-4">
        <div
          class="flex gap-4 text-xs mx-9 text-gray-600 self-end -mb-3"
          v-if="processes.length > 0"
        >
          <div
            class="hover:underline cursor-pointer"
            @click="currentProcessIndex = processes.length - 1"
          >
            {{ t("analytics.planner.mark_as_done_modal.go_to_last_process") }}
          </div>
          <div
            class="hover:underline cursor-pointer"
            v-if="lastConcretingProcessIndex !== -1"
            @click="goToLastConcretingProcess"
          >
            {{ t("analytics.planner.mark_as_done_modal.go_to_last_concreting") }}
          </div>
        </div>
        <div class="flex-1 flex gap-2 items-center" v-if="currentProcess">
          <div class="w-7 h-7 shrink-0">
            <ChevronLeftIcon
              class="w-full h-full cursor-pointer"
              @click="currentProcessIndex -= 1"
              v-if="currentProcessIndex > 0"
            />
          </div>
          <div class="flex-1 flex flex-col gap-1">
            <div ref="playerWrapper">
              <OaiVideoPlayer :loading="isProcessVideoUrlLoading" :src="processVideoUrl" />
            </div>
            <div class="text-xs flex gap-1 truncate">
              <div class="truncate flex-1">
                {{ currentProcessIndex + 1 }}/{{ processes.length }}:
                {{ t(`process_classes.${currentProcess.encoded_label}`) }}
              </div>
              <div class="flex gap-2">
                {{ formatProcessDate(currentProcess.date) }}
                {{ formatIsoHourMinute(currentProcess.start_time) }}-{{
                  formatIsoHourMinute(currentProcess.end_time)
                }}
              </div>
            </div>
          </div>
          <div class="w-7 h-7 shrink-0">
            <ChevronRightIcon
              class="w-full h-full cursor-pointer"
              @click="currentProcessIndex += 1"
              v-if="currentProcessIndex < processes.length - 1"
            />
          </div>
        </div>
        <div class="flex flex-col gap-1 mt-2">
          <div class="mx-9 text-sm font-semibold">
            {{ t("analytics.planner.mark_as_done_modal.date_label") }}
          </div>
          <VueDatePicker
            class="!w-[150px] mx-9"
            input-class-name="dp-custom-input"
            menu-class-name="dp-custom-menu"
            :locale="locale"
            v-model="selectedDate"
            :enable-time-picker="false"
            format="dd.MM.yyyy"
            auto-apply
            reverse-years
            :clearable="false"
          />
        </div>
        <button
          type="button"
          :disabled="isLoading"
          @click="setActualEventDate"
          class="mx-9 whitespace-nowrap self-end w-min inline-flex justify-center items-center gap-2 rounded-md border border-transparent bg-yellow-500 px-3 py-1 text-sm text-white focus:outline-none hover:bg-yellow-600 disabled:bg-gray-300"
        >
          {{ t("analytics.planner.mark_as_done_modal.mark_as_done_button") }}
          <LoadingSpinner class="w-4 h-4" v-if="isLoading" />
        </button>
      </div>
    </template>
  </Modal>
</template>
<script setup lang="ts">
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";
import VueDatePicker from "@vuepic/vue-datepicker";
import { format, parse, set } from "date-fns";
import * as fabric from "fabric";
import { v4 as uuid } from "uuid";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import OaiVideoPlayer from "shared/components/camera/OaiVideoPlayer.vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import Modal from "shared/components/modals/Modal.vue";
import HierarchyTagItem from "shared/components/other/HierarchyTagItem.vue";
import { useUpdateActualEvents } from "shared/composables/planner";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { useSectionMasks } from "shared/composables/sectionMasks";
import { useTrackEvent } from "shared/composables/tracking";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { ActualEventChanges } from "shared/types/Plan";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { EncodedLabel } from "shared/types/ProcessClass";
import { useCreateSelfResolvedIssueReport } from "@/composables/issueReport";
import { useProcessClasses, useProcessVideoUrl } from "@/composables/process";
import { drawMaskOnCanvas } from "@/services/sectionMaskCanvasService";
import { NewIssueReport } from "@/types/IssueReport";

const props = defineProps<{
  actualEventId: string;
  start: Date;
  processes: ShortenedProcessWithTags[];
  tags: HierarchyTagStore[];
}>();
const emit = defineEmits<{ (eventName: "close"): void }>();

const { t, locale } = useI18n();
const trackEvent = useTrackEvent();

const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();

const processClasses = useProcessClasses();
const { sectionMasks, error: sectionMaskError } = useSectionMasks();

let interval: number;
let resizeObserver: ResizeObserver;
const componentId = `process-video-${uuid()}`;

const canvasInstance = ref<fabric.StaticCanvas>();

const playerWrapper = ref<HTMLElement>();

const lastConcretingProcessIndex = computed(() => {
  const concretingEncodedLabels = new Set(
    processClasses.value
      .filter((processClass) => processClass.processElement === "concrete")
      .map((processClass) => processClass.encodedLabel),
  );
  const index = props.processes
    .slice()
    .reverse()
    .findIndex((process) => concretingEncodedLabels.has(process.encoded_label as EncodedLabel));
  if (index === -1) {
    return -1;
  }
  return props.processes.length - 1 - index;
});

const getInitialProcessIndex = () =>
  lastConcretingProcessIndex.value !== -1
    ? lastConcretingProcessIndex.value
    : props.processes.length - 1;

const currentProcessIndex = ref<number>(getInitialProcessIndex());

const currentProcess = computed(() => props.processes[currentProcessIndex.value]);

const currentProcessId = computed(() => currentProcess.value?._id);

const currentSectionMask = computed(() =>
  sectionMasks.value?.find((mask) => mask._id === currentProcess.value?.section_mask_mapping.id),
);

const getInitialSelectedDate = () =>
  currentProcess.value ? currentProcess.value.start_time : new Date();

const selectedDate = ref(getInitialSelectedDate());

const { updateActualEvents, isLoading: isUpdateActualEventsLoading } = useUpdateActualEvents();

const { createSelfResolvedIssueReport, isLoading: isCreateSelfResolvedIssueReportLoading } =
  useCreateSelfResolvedIssueReport();

const isLoading = computed(
  () => isUpdateActualEventsLoading.value || isCreateSelfResolvedIssueReportLoading.value,
);

const { processVideoUrl, isLoading: isProcessVideoUrlLoading } =
  useProcessVideoUrl(currentProcessId);

const formatProcessDate = (dateText: string) =>
  format(parse(dateText, "yyyy-MM-dd", new Date()), "dd.MM.yyyy");

const formatIsoHourMinute = (date: Date) => format(date, "HH:mm");

const setActualEventDate = () => {
  const changes: ActualEventChanges = {
    added: [],
    modified: [
      {
        _id: props.actualEventId,
        start: props.start,
        end: set(selectedDate.value, { hours: 17, minutes: 0, milliseconds: 0 }),
      },
    ],
    removed: [],
  };
  const issue: NewIssueReport = {
    type: "planner",
    entry_id: props.actualEventId,
    resolve_message: `Actual event ${props.tags
      .map((tag) => tag.name)
      .join("/")} marked as ended for ${currentCustomerName}/${currentSiteId}`,
    report_message: "",
  };
  updateActualEvents(changes)
    .then(() => createSelfResolvedIssueReport(issue))
    .then(() => {
      emit("close");
    })
    .catch(() => undefined)
    .finally(() => {
      trackEvent("progress_mark-completed_apply");
    });
};

const goToLastConcretingProcess = () => {
  if (lastConcretingProcessIndex.value === -1) {
    return;
  }
  currentProcessIndex.value = lastConcretingProcessIndex.value;
};

const clearSectionMaskOverlay = () => {
  clearInterval(interval);

  if (canvasInstance.value) {
    canvasInstance.value.dispose();
  }

  if (resizeObserver) {
    resizeObserver.disconnect();
  }
};

const handleResize = (entries: ResizeObserverEntry[]) => {
  const entry = entries[0];
  if (!canvasInstance.value) {
    return;
  }

  const objects = canvasInstance.value.getObjects();
  const videoElement = entry.target as HTMLVideoElement;
  const videoRatio = Math.round((videoElement.videoWidth / videoElement.videoHeight) * 100) / 100;
  const canvasRatio = Math.round((entry.contentRect.width / entry.contentRect.height) * 100) / 100;
  let videoElementWidth = entry.contentRect.width;
  let videoElementHeight = entry.contentRect.height;
  const oldWidth = canvasInstance.value.width;
  const oldHeight = canvasInstance.value.height;

  if (videoRatio > canvasRatio) {
    videoElementHeight = entry.contentRect.width / videoRatio;
  } else if (videoRatio < canvasRatio) {
    videoElementWidth = entry.contentRect.height * videoRatio;
  }

  const scaleX = videoElementWidth / oldWidth;
  const scaleY = videoElementHeight / oldHeight;

  objects.forEach((object) => {
    if (object instanceof fabric.Text) {
      object.set({
        left: videoElementWidth - object.width - 10,
      });

      return;
    }

    object.set({
      left: object.left * scaleX,
      top: object.top * scaleY,
      scaleX: object.scaleX * scaleX,
      scaleY: object.scaleY * scaleY,
    });
  });

  canvasInstance.value.setDimensions({
    width: videoElementWidth,
    height: videoElementHeight,
  });

  canvasInstance.value.renderAll();
};
const initSectionMaskCanvas = (videoElement: HTMLVideoElement) => {
  if (!currentSectionMask.value) {
    return;
  }

  canvasInstance.value = new fabric.StaticCanvas(componentId, {
    width: videoElement.clientWidth,
    height: videoElement.clientHeight,
  });

  const name = [
    currentProcess.value.section_mask_mapping.building_name,
    currentProcess.value.section_mask_mapping.level_name,
    currentProcess.value.section_mask_mapping.section_name,
  ]
    .filter(Boolean)
    .join(" - ");

  drawMaskOnCanvas(canvasInstance.value, currentSectionMask.value, name);
  resizeObserver = new ResizeObserver(handleResize);
  resizeObserver.observe(videoElement);
};

watch([() => props.processes, currentProcess], () => {
  selectedDate.value = getInitialSelectedDate();
});

watch(
  () => props.processes,
  () => {
    currentProcessIndex.value = props.processes.length - 1;
  },
);

watch(
  () => [processVideoUrl.value, isProcessVideoUrlLoading.value],
  () => {
    clearSectionMaskOverlay();

    if (!processVideoUrl.value || isProcessVideoUrlLoading.value) {
      return;
    }

    interval = setInterval(() => {
      const videoElement = playerWrapper.value?.querySelector("video");

      if (sectionMaskError.value) {
        clearSectionMaskOverlay();
        return;
      }

      if (!videoElement || !currentSectionMask.value) {
        return;
      }

      clearInterval(interval);

      const canvasElement = document.createElement("canvas");
      canvasElement.id = componentId;
      canvasElement.classList.add(
        "absolute",
        "pointer-events-none",
        "w-full",
        "h-full",
        "top-1/2",
        "left-1/2",
        "transform",
        "-translate-x-1/2",
        "-translate-y-1/2",
      );

      videoElement.parentElement?.insertBefore(
        canvasElement,
        videoElement.parentElement?.children[1],
      );

      videoElement.onloadeddata = () => initSectionMaskCanvas(videoElement);
    }, 100) as unknown as number;
  },
  { immediate: true },
);
</script>
