import { useQuery } from "@tanstack/vue-query";
import { computed, Ref } from "vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import CameraRepository from "shared/repositories/CameraRepository";
import logger from "shared/services/logger";
import { GalleryDownloadItem } from "shared/types/Camera";

export const useGalleryDownloadItems = (
  cameraIds: Ref<string[]>,
  startDate: Ref<string | null>,
  endDate: Ref<string | null>,
  imgsPerDay: Ref<number | null>,
) => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const { data, isLoading, error } = useQuery<GalleryDownloadItem[]>({
    queryKey: [
      "gallery-download-items",
      currentCustomerName,
      currentSiteId,
      cameraIds,
      startDate,
      endDate,
      imgsPerDay,
    ],
    queryFn: ({ signal }) => {
      if (cameraIds.value.length === 0) {
        return [];
      }

      return CameraRepository.loadGalleryDownloadItems(
        currentCustomerName,
        currentSiteId,
        cameraIds.value,
        startDate.value,
        endDate.value,
        imgsPerDay.value,
        signal,
      );
    },

    useErrorBoundary: (error) => {
      logger.error(error);
      return false;
    },
  });

  const galleryDownloadItems = computed(() => data.value || []);

  return { galleryDownloadItems, isLoading, error };
};
