<template>
  <div
    class="flex flex-col min-w-0"
    :class="cls"
    :style="{
      fontSize: `${fontSize}px`,
      padding: `1px ${paddingX}px`,
    }"
  >
    <div
      class="grid grid-cols-1 text-center bg-white rounded-xl overflow-hidden oaiQueryValuePreviousPeriodValueContainer"
    >
      <div
        class="flex items-center gap-3 overflow-hidden row-start-1 col-start-1 justify-center"
        :class="
          extra.invert_shown_info
            ? 'oaiQueryValuePreviousPeriodValue'
            : 'oaiQueryValuePreviousPeriodValueChange'
        "
      >
        <div
          v-if="valueChange !== null && valueChange !== 0 && !extra.use_dot"
          :style="{
            borderLeft: `${arrowSize - arrowSize * 0.3}px solid transparent`,
            borderRight: `${arrowSize - arrowSize * 0.3}px solid transparent`,
            borderBottom: isArrowPointingUp ? `${arrowSize}px solid ${arrowColor}` : undefined,
            borderTop: !isArrowPointingUp ? `${arrowSize}px solid ${arrowColor}` : undefined,
          }"
        />
        <div
          v-if="valueChange !== null && (valueChange === 0 || extra.use_dot)"
          class="bg-gray-400 rounded-full"
          :style="{
            width: `${dotSize}px`,
            height: `${dotSize}px`,
            marginTop: dotSize > 15 ? '2px' : undefined,
            backgroundColor:
              extra.value !== 0 ? (isArrowPointingUp ? '#56AF9C' : '#F87171') : undefined,
          }"
        />
        <div class="truncate">
          {{ formattedChange }}{{ extra.use_pp ? " pp" : "%" }}
          <span
            class="font-extralight"
            :style="{ fontSize: `${labelFontSize}px` }"
            v-if="extra.show_change_than_text && valueChange !== null"
            >{{
              isArrowPointingUp
                ? t("analytics.reports.query_value_progress.faster_than")
                : t("analytics.reports.query_value_progress.slower_than")
            }}</span
          >
        </div>
      </div>
      <div
        class="truncate row-start-1 col-start-1"
        :class="
          extra.invert_shown_info
            ? 'oaiQueryValuePreviousPeriodValueChange'
            : 'oaiQueryValuePreviousPeriodValue'
        "
      >
        <span v-if="extra.prefix" class="font-extralight">{{ extra.prefix }}</span
        >{{ formattedExtraValue }}{{ valueUnit }}
      </div>
    </div>
    <div
      class="text-center font-extralight flex items-center justify-center gap-1"
      :style="{ fontSize: `${labelFontSize}px` }"
      v-if="extra.label"
    >
      <span class="truncate">{{ extra.label }}</span>
      <OaiTooltip v-if="extra.tooltip">
        <InformationCircleIcon
          :style="{ width: `${infoIconSize}px`, height: `${infoIconSize}px` }"
        />
        <template #tooltip>
          <div class="whitespace-pre">{{ extra.tooltip }}</div>
        </template>
      </OaiTooltip>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { InformationCircleIcon } from "@heroicons/vue/24/solid";
import { computed, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import numberService from "shared/services/numberService";
import {
  QueryValueAdditionalValue,
  QueryValueReportConfig,
  QueryValueSingleValue,
} from "@/types/reports/PlotQueryValue";
import {
  calculateValueChange,
  calculateValuePP,
  formatValue,
  precisionByMetric,
} from "@/views/reports/plots/query_value/queryValue";

const containerBaseHeight = 200;
const containerBaseWidth = 600;

const props = defineProps<{
  data: QueryValueSingleValue;
  config: QueryValueReportConfig;
  extra: QueryValueAdditionalValue;
  valueFontSize: number;
  valueUnit: string;
  containerRect: DOMRect;
  textFittedElementRect: DOMRect;
  cls?: string;
  useWidthBasedScaling?: boolean;
}>();

const { t, locale } = useI18n();

const formattedExtraValue = computed(() => formatValue(props.extra.value, props.config.metric));

const roundValue = (number: number | null) => {
  if (number === null) {
    return null;
  }
  return numberService.fixToPrecision(number, precisionByMetric[props.config.metric]);
};

const valueChange = computed(() => {
  if (props.extra.null_out_change) {
    return null;
  }
  const roundedValue = roundValue(props.data.value);
  const extraValue = roundValue(props.extra.value);
  return props.extra.use_pp
    ? calculateValuePP(roundedValue, extraValue)
    : calculateValueChange(roundedValue, extraValue);
});

const formattedChange = computed(() => {
  if (valueChange.value === null) {
    return "-";
  }
  const value = props.extra.use_pp ? valueChange.value : valueChange.value * 100;
  const finalValue = props.extra.show_change_than_text ? Math.abs(value) : value;
  return finalValue.toLocaleString(locale.value, {
    minimumFractionDigits: precisionByMetric[props.config.metric],
    maximumFractionDigits: precisionByMetric[props.config.metric],
    useGrouping: false,
  });
});

const paddingX = computed(() => {
  const scale = props.extra.show_change_than_text ? 0.05 : 0.1;
  return props.textFittedElementRect.width * scale;
});

const arrowSize = computed(() => {
  if (props.useWidthBasedScaling) {
    return Math.max((props.containerRect.width / containerBaseWidth) * 14, 8);
  }
  return Math.max((props.containerRect.height / containerBaseHeight) * 15, 8);
});

const dotSize = computed(() => {
  if (props.useWidthBasedScaling) {
    return Math.min(Math.max((props.containerRect.width / containerBaseWidth) * 14, 8), 20);
  }
  return Math.min(Math.max((props.containerRect.height / containerBaseHeight) * 15, 8), 20);
});

const isArrowPointingUp = computed(() => {
  if (valueChange.value === null) {
    return false;
  }
  return props.extra.smaller_is_better ? valueChange.value < 0 : valueChange.value > 0;
});

const arrowColor = computed(() => (isArrowPointingUp.value ? "#56AF9C" : "#F87171"));

const fontSize = computed(() => {
  if (props.useWidthBasedScaling) {
    return Math.max(
      Math.min((props.containerRect.width / containerBaseWidth) * 18, props.valueFontSize * 0.5),
      9,
    );
  }
  return Math.max(
    Math.min((props.containerRect.height / containerBaseHeight) * 21, props.valueFontSize * 0.75),
    9,
  );
});

const labelFontSize = computed(() => Math.max(fontSize.value * 0.7, 9));

const infoIconSize = computed(() => Math.max(arrowSize.value * 0.9, 12));
</script>

<style scoped>
.oaiQueryValuePreviousPeriodValueChange {
  transition: transform 300ms, opacity 300ms;
}

.oaiQueryValuePreviousPeriodValue {
  opacity: 0;
  transform: translateX(-100%);
  transition: transform 300ms, opacity 300ms;
}

.oaiQueryValuePreviousPeriodValueContainer:hover .oaiQueryValuePreviousPeriodValue {
  opacity: 1;
  transform: none;
}

.oaiQueryValuePreviousPeriodValueContainer:hover .oaiQueryValuePreviousPeriodValueChange {
  opacity: 0;
  transform: translateX(100%);
}
</style>
