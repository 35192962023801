<template>
  <OaiVideoPlayer
    :loading="loading"
    :src="videoUrl"
    :hls="true"
    :noSrcMessage="noSrcMessage"
    :addRightSpaceToControlBar="addRightSpaceToControlBar"
  />
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import OaiVideoPlayer from "shared/components/camera/OaiVideoPlayer.vue";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import PublicTokenRepository from "shared/repositories/PublicTokenRepository";
import StreamRepository from "shared/repositories/StreamRepository";
import logger from "shared/services/logger";

const props = defineProps<{
  cameraId: string;
  addRightSpaceToControlBar?: boolean;
}>();

const emit = defineEmits<{ (eventName: "videoUrlError"): void }>();

const { t } = useI18n();

const currentCustomerName = useCurrentCustomerName();
const currentSiteId = useCurrentSiteId();

const videoUrl = ref<string | null>(null);
const loading = ref(false);
const noSrcMessage = ref<string | undefined>(undefined);

onMounted(async () => {
  loading.value = true;
  try {
    videoUrl.value = await StreamRepository.loadLiveUrl(
      currentCustomerName,
      currentSiteId,
      props.cameraId,
    );
  } catch (error) {
    if (PublicTokenRepository.isPublicTokenAuthError(error)) {
      noSrcMessage.value = t("public_tokens.link_expired");
    } else {
      logger.error(error);
    }
    videoUrl.value = null;
  } finally {
    loading.value = false;
    if (!videoUrl.value) {
      emit("videoUrlError");
    }
  }
});
</script>
