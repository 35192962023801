<template>
  <Popover class="relative">
    <PopoverButton
      class="outline-none"
      :disabled="arePublicTokensLoading"
      @click="handlePopoverClick"
    >
      <button
        class="min-w-0 rounded-full px-4 py-1 text-xs bg-white shadow-sm border flex items-center gap-1"
        :class="arePublicTokensLoading ? 'text-gray-300' : 'text-gray-500'"
      >
        <span
          :class="currentPublicTokens.length > 0 ? 'bg-green-300' : 'bg-gray-300'"
          class="block w-[8px] h-[8px] rounded-full"
        />
        {{ currentPublicTokens.length > 0 ? t("public_tokens.shared") : t("public_tokens.share") }}
      </button>
    </PopoverButton>
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        class="absolute top-[30px] right-0 z-10 bg-white border shadow-md w-[370px] rounded"
      >
        <div class="border-b">
          <div
            :key="currentPublicToken._id"
            v-for="(currentPublicToken, index) in currentPublicTokens"
            class="flex flex-col gap-1 mx-3 my-4"
            :class="index !== currentPublicTokens.length - 1 ? 'border-b pb-4' : ''"
          >
            <div class="flex items-center gap-1 mb-2">
              <input
                type="text"
                :value="publicTokenService.createUrl(currentPublicToken)"
                :readonly="true"
                class="max-w-[140px] font-medium px-2 py-1 h-[26px] rounded border border-gray-300 focus:border-yellow-500 focus:outline-none bg-white min-w-0 w-full mr-1"
                :style="{ boxShadow: 'none', fontSize: '10px' }"
              />
              <DocumentDuplicateIcon
                class="w-5 h-5 text-gray-600 cursor-pointer shrink-0"
                @click="copyPublicTokenLink(currentPublicToken)"
              />
              <OaiTooltip cls="shrink-0">
                <InformationCircleIcon class="h-5 w-5 cursor-pointer text-gray-600" />
                <template #tooltip>
                  <div class="text-xs ml-1">
                    {{ t("public_tokens.published_by") }}
                    <span class="font-semibold">{{ currentPublicToken.created_by }}</span>
                    {{ t("public_tokens.at") }}
                    {{ formatDate(currentPublicToken.created) }}
                  </div>
                </template>
              </OaiTooltip>
              <div class="flex-1" />
              <button
                class="ml-4 truncate min-w-0 rounded px-2 py-1 border text-xs shadow-sm"
                :class="
                  isDeletePublicTokenLoading
                    ? 'bg-gray-100 text-gray-400'
                    : 'cursor-pointer bg-white text-gray-600 hover:bg-gray-100'
                "
                @click="handleDeleteClick(currentPublicToken)"
              >
                {{ t("public_tokens.unpublish") }}
              </button>
            </div>
            <div class="text-xs ml-1 flex items-center justify-between gap-1">
              <PublicTokenLiveTypeUpdater
                v-if="claims.includes('camera_live_image')"
                :publicToken="currentPublicToken"
              />
              <div class="truncate" v-else>
                {{ getPublicTokenName(currentPublicToken, { hideCameraName: true }) }}
              </div>
              <div class="flex gap-1 text-xs items-center ml-1 shrink-0">
                <span class="truncate">{{ t("public_tokens.expires") }}:</span>
                <PublicTokenExpirationUpdater :publicToken="currentPublicToken" />
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-3 mx-3 my-4">
          <div class="font-semibold text-sm">
            {{ t("public_tokens.create_link_header") }}
          </div>
          <PublicTokenExpirationOptions
            :expirationDate="expirationDate"
            @change="expirationDate = $event"
          />
          <SelectList
            v-if="claims.includes('camera_live_image')"
            :options="liveCameraOptions"
            :defaultSelected="liveCameraOptionValue"
            :label="t('public_tokens.live_option_label')"
            :onUpdate:selected="handleLiveCameraOptionChange"
            inputFieldClass="relative rounded-md py-1 pl-2 pr-10 text-xs text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none sm:leading-6 w-full"
            optionClass="text-xs"
          />
          <div class="flex justify-between items-center gap-1">
            <RouterLink
              :to="{ name: 'ProjectSettings', params: { tab: 'public_tokens' } }"
              class="text-xs truncate hover:underline"
            >
              {{ t("public_tokens.manage_links") }}
            </RouterLink>
            <button
              type="button"
              :disabled="isPublicLinkCreating"
              class="min-w-0 flex items-center justify-center gap-1 rounded bg-yellow-600 disabled:bg-gray-300 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
              @click="handleCreateButtonClick"
            >
              <PlusIcon class="h-5 w-5 shrink-0" />
              <span class="truncate">{{ t("public_tokens.create") }}</span>
            </button>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script lang="ts" setup>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { InformationCircleIcon, DocumentDuplicateIcon } from "@heroicons/vue/24/outline";
import { PlusIcon } from "@heroicons/vue/24/solid";
import { format } from "date-fns";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import SelectList from "shared/components/other/OaiListbox.vue";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { useConfirmationModal } from "shared/composables/toast";
import publicTokenService from "shared/services/publicTokenService";
import { PublicToken, PublicTokenClaim, PublicTokenToCreate } from "shared/types/PublicToken";
import PublicTokenExpirationOptions from "@/components/public_token/PublicTokenExpirationOptions.vue";
import PublicTokenExpirationUpdater from "@/components/public_token/PublicTokenExpirationUpdater.vue";
import PublicTokenLiveTypeUpdater from "@/components/public_token/PublicTokenLiveTypeUpdater.vue";
import {
  useCopyPublicTokenLink,
  useCreatePublicToken,
  useDeletePublicToken,
  useGetPublicTokenName,
  usePublicTokens,
} from "@/composables/publicToken";

const props = defineProps<{ claims: PublicTokenClaim[]; cameraId?: string }>();

const { t } = useI18n();

type LiveCameraOption = "live_video" | "live_image";

const liveCameraOptionList: LiveCameraOption[] = ["live_video", "live_image"];

const liveCameraOptions: { name: string; value: LiveCameraOption }[] = liveCameraOptionList.map(
  (option) => ({
    name: t(`public_tokens.live_options.${option}`),
    value: option,
  }),
);

const expirationDate = ref<Date | null>(null);
const liveCameraOptionValue = ref<LiveCameraOption>("live_image");

const { publicTokens, arePublicTokensLoading } = usePublicTokens();
const { createPublicToken, isLoading: isPublicLinkCreating } = useCreatePublicToken();
const { deletePublicToken, isLoading: isDeletePublicTokenLoading } = useDeletePublicToken();
const showConfirmationModal = useConfirmationModal();
const getPublicTokenName = useGetPublicTokenName();
const copyPublicTokenLink = useCopyPublicTokenLink();

const resetCreateForm = () => {
  expirationDate.value = null;
  liveCameraOptionValue.value = "live_image";
};

const currentPublicTokens = computed(() => {
  if (!publicTokens.value) {
    return [];
  }
  const now = new Date();
  return publicTokens.value.filter(
    (publicToken) =>
      props.claims.some((claim) => publicToken.claims.includes(claim)) &&
      (!props.cameraId || publicToken.camera_ids.includes(props.cameraId)) &&
      (!publicToken.expires || publicToken.expires > now),
  );
});

const handleLiveCameraOptionChange = (newValue: string) => {
  liveCameraOptionValue.value = newValue as LiveCameraOption;
};

const handleCreateButtonClick = () => {
  if (isPublicLinkCreating.value) {
    return;
  }
  const claims: PublicTokenClaim[] = props.claims.includes("camera_live_image")
    ? [liveCameraOptionValue.value === "live_image" ? "camera_live_image" : "camera_live"]
    : props.claims;
  const publicTokenToCreate: PublicTokenToCreate = {
    expires: expirationDate.value,
    camera_ids: props.cameraId ? [props.cameraId] : [],
    claims,
  };
  createPublicToken(publicTokenToCreate)
    .then(() => {
      resetCreateForm();
    })
    .catch(() => undefined);
};

const handleDeleteClick = (currentPublicToken: PublicToken) => {
  if (isDeletePublicTokenLoading.value) {
    return;
  }
  showConfirmationModal({
    confirmAction: t("public_tokens.delete_confirmation.confirm"),
    cancelAction: t("public_tokens.delete_confirmation.cancel"),
    message: t("public_tokens.delete_confirmation.message", {
      name: getPublicTokenName(currentPublicToken),
    }),
    header: t("public_tokens.delete_confirmation.header"),
  }).then((confirmed) => {
    if (confirmed) {
      deletePublicToken(currentPublicToken._id).catch(() => undefined);
    }
  });
};

const handlePopoverClick = () => {
  resetCreateForm();
};

const formatDate = (date: Date) => format(date, "dd.MM.yyyy HH:mm");
</script>
