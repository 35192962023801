<template>
  <div class="flex flex-col gap-12">
    <div class="flex flex-col gap-4">
      <div class="flex items-center justify-between gap-2">
        <div class="text-xl font-bold text-gray-900 px-1 truncate">
          {{ t("organizations.projects") }} ({{ organization.projects.length }})
        </div>
        <button
          @click="isAddProjectModalOpen = true"
          type="button"
          class="hidden sm:flex whitespace-nowrap items-center gap-1 rounded bg-yellow-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
        >
          <PlusIcon class="h-5 w-5 shrink-0" />
          {{ t("dashboard.home.add_project") }}
        </button>
      </div>
      <div class="bg-white rounded border flex flex-col min-h-[200px]">
        <OrganizationTabProjectsList
          v-if="childOrganizations.length === 0"
          :organization="organization"
          :organizationsByParentId="organizationsByParentId"
          :projects="projects"
        />
        <OrganizationTabProjectsHierarchyItem
          v-for="(organizationInHierarchy, index) in organizationsForHierarchyItem"
          :key="organizationInHierarchy._id"
          :organization="organizationInHierarchy"
          :organizationsByParentId="organizationsByParentId"
          :projectsByCustomerNameSiteId="projectsByCustomerNameSiteId"
          :hideFirstTopBorder="index === 0 && projects.length === 0"
        />
      </div>
    </div>
  </div>
  <AddProjectModal :open="isAddProjectModalOpen" @close="isAddProjectModalOpen = false" />
</template>

<script lang="ts" setup>
import { PlusIcon } from "@heroicons/vue/24/outline";
import { computed, defineProps, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { Organization } from "shared/types/Organization";
import { Project } from "shared/types/Project";
import AddProjectModal from "@/views/dashboard/components/AddProjectModal.vue";
import OrganizationTabProjectsHierarchyItem from "@/views/organizations/components/OrganizationTabProjectsHierarchyItem.vue";
import OrganizationTabProjectsList from "@/views/organizations/components/OrganizationTabProjectsList.vue";

const props = defineProps<{
  organization: Organization;
  organizationsByParentId: Record<string, Organization[] | undefined>;
}>();

const { t } = useI18n();
const store = useStore();

const isAddProjectModalOpen = ref(false);

const childOrganizations = computed(
  () => props.organizationsByParentId[props.organization._id] || [],
);

const organizationsForHierarchyItem = computed(() =>
  childOrganizations.value.length > 0 ? [props.organization] : [],
);

const projectsByCustomerNameSiteId = computed(() => {
  const projects: Project[] = store.state.projects || [];
  return projects.reduce((acc, project) => {
    acc[`${project.customer_name}_${project.site_id}`] = project;
    return acc;
  }, {} as Record<string, Project | undefined>);
});

const projects = props.organization.explicit_projects
  .map(
    (project) => projectsByCustomerNameSiteId.value[`${project.customer_name}_${project.site_id}`],
  )
  .filter((project) => project) as Project[];
</script>
