<template>
  <div v-if="sortedProjects.length > 0">
    <OrganizationTabProjectsItem
      v-for="(project, index) in sortedProjects"
      :key="project._id"
      :project="project"
      :organization="organization"
      :indentation="indentation"
      :cls="index === sortedProjects.length - 1 ? '' : 'border-b'"
    />
  </div>
  <div
    v-if="sortedProjects.length === 0"
    class="px-2 text-gray-400 text-xs py-1 h-[50px] flex items-center"
    :style="{ paddingLeft: `${calculateIndentation(indentation)}px` }"
  >
    <span class="truncate">{{ t("organizations.no_projects") }}</span>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { Organization } from "shared/types/Organization";
import { Project, ProjectStatus } from "shared/types/Project";
import OrganizationTabProjectsItem from "@/views/organizations/components/OrganizationTabProjectsItem.vue";
import { calculateIndentation } from "@/views/organizations/organizations";

const props = withDefaults(
  defineProps<{
    organization: Organization;
    organizationsByParentId: Record<string, Organization[] | undefined>;
    projects: Project[];
    indentation?: number;
  }>(),
  { indentation: 0 },
);

const { t } = useI18n();

const projectStatusOrder: Record<ProjectStatus, number> = {
  active: 0,
  completed: 1,
};

const sortedProjects = computed(() =>
  props.projects.slice().sort((a, b) => {
    const statusOrderA = projectStatusOrder[a.status];
    const statusOrderB = projectStatusOrder[b.status];
    if (statusOrderA === statusOrderB) {
      return b.planned_start.getTime() - a.planned_start.getTime();
    }
    return statusOrderA - statusOrderB;
  }),
);
</script>
