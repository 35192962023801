<template>
  <div
    class="flex flex-col px-4 py-1 hover:bg-gray-100 h-[50px]"
    :class="cls"
    :style="{
      paddingLeft: `${calculateIndentation(indentation)}px`,
    }"
  >
    <div class="flex gap-2 items-center">
      <div class="flex flex-col min-w-0">
        <div class="flex gap-2 items-center">
          <div class="truncate">{{ project.name }}</div>
          <div class="text-xs text-gray-500 truncate mt-[1px]" v-if="hasAdminPermission">
            {{ project.customer_name }}/{{ project.site_id }}
          </div>
        </div>
        <div class="text-gray-400 text-xs truncate" v-if="address">
          {{ address }}
        </div>
      </div>
      <div class="flex-1" />
      <div class="text-gray-600 text-xs lowercase truncate">
        {{ format(project.planned_start, "dd.MM.yyyy") }}
        {{
          project.planned_end
            ? `- ${format(project.planned_end, "dd.MM.yyyy")}`
            : `- ${$t("calendar.today")}`
        }}
      </div>
      <div
        class="px-3 py-0.5 text-center rounded-md text-xs text-white truncate"
        :class="[project.status === 'active' ? 'bg-green-500' : 'bg-gray']"
      >
        {{ t(`admin.general_project_settings.project_status.${project.status}`) }}
      </div>
      <RouterLink
        :to="{
          name: 'ProjectOverview',
          params: { customer_name: project.customer_name, site_id: project.site_id },
        }"
        target="_blank"
      >
        <ArrowRightIcon class="w-5 h-5" />
      </RouterLink>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ArrowRightIcon } from "@heroicons/vue/24/outline";
import { format } from "date-fns";
import { computed, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { useHasPermission } from "shared/composables/project";
import { Organization } from "shared/types/Organization";
import { Project } from "shared/types/Project";
import { calculateIndentation } from "@/views/organizations/organizations";

const props = withDefaults(
  defineProps<{
    organization: Organization;
    project: Project;
    cls?: string;
    indentation?: number;
  }>(),
  { indentation: 0 },
);

const { t } = useI18n();

const hasAdminPermission = useHasPermission("app_admin");

const address = computed(() =>
  [props.project.street, props.project.zip_code, props.project.country_code]
    .filter((item) => item)
    .join(", "),
);
</script>
