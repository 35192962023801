import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "md:py-3 py-1 flex flex-col flex-wrap xl:flex-nowrap justify-center sm:items-center md:justify-between md:flex-row headroom header md:sticky md:top-0 md:z-10 px-6 border-b border-gray-100 bg-gray-50 h-max-screen" }
const _hoisted_2 = { class: "md:w-1/3 flex items-center justify-center sm:justify-start gap-2" }
const _hoisted_3 = { class: "text-xs" }
const _hoisted_4 = {
  id: "dayPagination",
  class: "md:w-1/3 flex justify-center items-center pt-2 pb-2 shrink-0"
}
const _hoisted_5 = { id: "loadPriorDay" }
const _hoisted_6 = { class: "font-bold leading-7 text-gray-900 xl:text-3xl sm:text-lg sm:tracking-tight sm:truncate" }
const _hoisted_7 = { id: "loadNextDay" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "xl:w-1/3 xl:ml-4 justify-self-center order-2 sm:order-3 grow-1 shrink-1" }
const _hoisted_10 = { class: "flex items-center justify-center md:justify-end gap-2" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = {
  key: 0,
  class: "flex justify-between items-center sm:rounded-lg px-6 pt-2 max-w-screen-2xl mx-auto"
}
const _hoisted_13 = { class: "md:w-1/3 py-2 text-left" }
const _hoisted_14 = { class: "lg:text-lg text-sm leading-4 font-medium text-black" }
const _hoisted_15 = { class: "text-sm mt-0.5" }
const _hoisted_16 = { class: "md:w-1/3 flex justify-center md:justify-end" }
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "p-2 sm:pb-5 sm:px-6 flex flex-col max-w-screen-2xl mx-auto" }
const _hoisted_19 = {
  key: 0,
  class: "text-sm mb-2 flex justify-end"
}
const _hoisted_20 = {
  key: 0,
  class: "bg-green-50/40 px-2 py-1 rounded-md"
}
const _hoisted_21 = { id: "sections-container" }
const _hoisted_22 = ["id"]
const _hoisted_23 = { id: "processes" }
const _hoisted_24 = { id: "gallery" }
const _hoisted_25 = { id: "notes" }
const _hoisted_26 = { id: "disturbances" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingOverlay = _resolveComponent("LoadingOverlay")!
  const _component_ArrowLongLeftIcon = _resolveComponent("ArrowLongLeftIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ArrowDownTrayIcon = _resolveComponent("ArrowDownTrayIcon")!
  const _component_InformationCircleIcon = _resolveComponent("InformationCircleIcon")!
  const _component_OaiTooltip = _resolveComponent("OaiTooltip")!
  const _component_ChevronLeftIcon = _resolveComponent("ChevronLeftIcon")!
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon")!
  const _component_PencilIcon = _resolveComponent("PencilIcon")!
  const _component_LockOpenIcon = _resolveComponent("LockOpenIcon")!
  const _component_MainButton = _resolveComponent("MainButton")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_CheckIcon = _resolveComponent("CheckIcon")!
  const _component_CheckCircleIcon = _resolveComponent("CheckCircleIcon")!
  const _component_Header = _resolveComponent("Header")!
  const _component_BaseSection = _resolveComponent("BaseSection")!
  const _component_GroupedProcesses = _resolveComponent("GroupedProcesses")!
  const _component_CustomGallery = _resolveComponent("CustomGallery")!
  const _component_NotesSection = _resolveComponent("NotesSection")!
  const _component_DisturbancesSection = _resolveComponent("DisturbancesSection")!
  const _component_LoadingSection = _resolveComponent("LoadingSection")!
  const _component_Sidebar = _resolveComponent("Sidebar")!

  return (_openBlock(), _createBlock(_component_Sidebar, null, {
    default: _withCtx(() => [
      (_ctx.exportLoading)
        ? (_openBlock(), _createBlock(_component_LoadingOverlay, { key: 0 }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_link, {
            to: {
            name: 'DailyReportOverview',
            params: {
              customer_name: _ctx.$route.params.customer_name,
              site_id: _ctx.$route.params.site_id,
            },
            query: {
              year: _ctx.currentYear,
              month: _ctx.currentMonth,
            },
          },
            class: "inline-flex text-orange items-center md:px-4 md:py-1.5 py-1 px-1.5 rounded-md font-bold border border-orange hover:bg-orange hover:text-white bg-gray-50 shadow"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ArrowLongLeftIcon, { class: "shrink-0 h-6" })
            ]),
            _: 1
          }, 8, ["to"]),
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.exportPdf && _ctx.exportPdf(...args))),
            class: "animated-button border-orange text-orange hover:bg-orange hover:text-white rounded-md shadow-sm text-sm"
          }, [
            _createVNode(_component_ArrowDownTrayIcon, { class: "w-full h-6 block m-0 box-border shrink-0" }),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t("buttons.export_report")), 1)
          ]),
          (_ctx.reportMeta && _ctx.reportMeta.status !== 'NO_EDIT')
            ? (_openBlock(), _createBlock(_component_OaiTooltip, {
                key: 0,
                position: "right"
              }, {
                tooltip: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t("report.general.last_update")) + ": " + _toDisplayString(_ctx.formatLastUpdated(_ctx.reportMeta.updated)), 1),
                    _createElementVNode("p", null, _toDisplayString(_ctx.$t("report.general.by")) + ": " + _toDisplayString(_ctx.reportMeta.updated_by), 1)
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_InformationCircleIcon, { class: "h-5 text-orange-500" })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.reportPagination?.prior ? _ctx.paginate(_ctx.reportPagination.prior) : '')),
              class: _normalizeClass([
              'border-transparent inline-flex items-center px-4 py-2 text-sm font-medium text-oaiGray-300',
              { 'text-transparent': !_ctx.reportPagination?.prior },
            ])
            }, [
              _createVNode(_component_ChevronLeftIcon, {
                class: "h-6 w-6",
                "aria-hidden": "true"
              })
            ], 2)
          ]),
          _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.formattedDate), 1),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("button", {
              disabled: !_ctx.reportPagination?.next,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.reportPagination?.next ? _ctx.paginate(_ctx.reportPagination.next) : '')),
              class: _normalizeClass([
              'border-transparent inline-flex items-center px-4 py-2 text-sm font-medium text-oaiGray-300',
              { 'text-transparent': !_ctx.reportPagination?.next },
            ])
            }, [
              _createVNode(_component_ChevronRightIcon, {
                class: "h-6 w-6",
                "aria-hidden": "true"
              })
            ], 10, _hoisted_8)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            (!_ctx.editMode)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "animated-button hover:bg-oaiGray hover:text-white border-oaiGray text-oaiGray",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.edit()))
                }, [
                  (!_ctx.isApproved)
                    ? (_openBlock(), _createBlock(_component_PencilIcon, {
                        key: 0,
                        class: "w-full h-6 block px-2 m-0 box-border shrink-0"
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.isApproved)
                    ? (_openBlock(), _createBlock(_component_LockOpenIcon, {
                        key: 1,
                        class: "w-full h-6 block px-2 m-0 box-border shrink-0"
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("buttons.edit")), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.editMode)
              ? (_openBlock(), _createBlock(_component_MainButton, {
                  key: 1,
                  label: _ctx.$t('buttons.cancel'),
                  class: "sm:mr-0 mt-0 py-1",
                  type: "button",
                  bordered: true,
                  onClicked: _ctx.cancelEdit
                }, null, 8, ["label", "onClicked"]))
              : _createCommentVNode("", true),
            (_ctx.editMode)
              ? (_openBlock(), _createBlock(_component_MainButton, {
                  key: 2,
                  label: _ctx.$t('buttons.save'),
                  type: "button",
                  color: "yellow",
                  class: "mx-0 sm:mr-0 mt-0",
                  isDisabled: !_ctx.canSaveReport,
                  onClicked: _cache[4] || (_cache[4] = ($event: any) => {
              _ctx.save();
              _ctx.trackEvent('dcr_single_save_click');
            })
                }, {
                  default: _withCtx(() => [
                    (_ctx.isSubmitting)
                      ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
                          key: 0,
                          size: "w-5 h-5",
                          color: "white",
                          class: "mr-2"
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["label", "isDisabled"]))
              : _createCommentVNode("", true),
            (!_ctx.isApproved && !_ctx.loading)
              ? (_openBlock(), _createElementBlock("button", {
                  key: 3,
                  type: "button",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.approve())),
                  disabled: _ctx.loading,
                  class: "animated-button hover:bg-green hover:text-white bg-green text-white"
                }, [
                  _createVNode(_component_CheckIcon, { class: "w-full h-6 block m-0 box-border shrink-0" }),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t("buttons.approve")), 1)
                ], 8, _hoisted_11))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.loading && 'hidden')
      }, [
        (_ctx.projectMeta)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("h3", _hoisted_14, _toDisplayString(_ctx.projectMeta.name), 1),
                _createElementVNode("p", _hoisted_15, [
                  _createTextVNode(_toDisplayString(_ctx.projectMeta.street), 1),
                  _withDirectives(_createElementVNode("span", null, ",", 512), [
                    [_vShow, _ctx.loadedReport]
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.projectMeta.zip_code), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                (_ctx.logoUrl !== '')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "h-10",
                      src: _ctx.logoUrl,
                      alt: "Workflow"
                    }, null, 8, _hoisted_17))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_18, [
          (_ctx.isApproved)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                (_ctx.reportMeta)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                      _createVNode(_component_CheckCircleIcon, { class: "w-5 mr-1 text-green-400 inline-flex" }),
                      _createTextVNode(" " + _toDisplayString(_ctx.$t("report.general.approved_by")) + ": " + _toDisplayString(_ctx.reportMeta.updated_by) + " (" + _toDisplayString(_ctx.formatLastUpdated(_ctx.reportMeta.updated)) + ") ", 1)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          (_ctx.reportData)
            ? (_openBlock(), _createBlock(_component_Header, {
                key: 1,
                ref: "header",
                date: _ctx.$route.params.date,
                siteDuration: _ctx.reportData.site_duration,
                editMode: _ctx.editMode,
                approved: _ctx.isApproved,
                highlightSection: _ctx.highlightedSection,
                validationError: _ctx.validationError,
                onAddHighlight: _cache[6] || (_cache[6] = ($event: any) => (_ctx.highlightedSection = 'header')),
                onActiveEditMode: _cache[7] || (_cache[7] = ($event: any) => (_ctx.editMode = true)),
                onUpdateSiteDuration: _cache[8] || (_cache[8] = ($event: any) => (_ctx.updateSiteDuration($event)))
              }, null, 8, ["date", "siteDuration", "editMode", "approved", "highlightSection", "validationError"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_21, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sectionsConfig, (value, section) => {
              return _withDirectives((_openBlock(), _createElementBlock("div", {
                key: section,
                id: section
              }, [
                (section !== 'processes' && _ctx.reportData && _ctx.reportCustomFields)
                  ? (_openBlock(), _createBlock(_component_BaseSection, {
                      key: 0,
                      ref_for: true,
                      ref: section,
                      customFields: _ctx.reportCustomFields[section],
                      sectionConfig: _ctx.sectionsConfig[section],
                      editMode: _ctx.editMode,
                      highlightSection: _ctx.highlightedSection,
                      entries: _ctx.reportData[section],
                      section: section,
                      approved: _ctx.isApproved,
                      validationError: _ctx.validationError,
                      onActiveEditMode: _cache[9] || (_cache[9] = ($event: any) => (_ctx.editMode = true)),
                      onAddHighlight: ($event: any) => (_ctx.highlightedSection = section),
                      onAddEntry: _ctx.addSectionRowEntry,
                      onRemoveEntry: _ctx.removeSectionRowEntry,
                      onUpdateComboBox: _ctx.updateSectionComboBox,
                      onUpdateFieldValue: _ctx.updateSectionFieldValue,
                      onAddCustomField: _ctx.handleAddCustomField,
                      onDeleteCustomField: _ctx.handleDeleteCustomField,
                      onUpdateSectionData: ($event: any) => (_ctx.updateSectionData($event, section))
                    }, null, 8, ["customFields", "sectionConfig", "editMode", "highlightSection", "entries", "section", "approved", "validationError", "onAddHighlight", "onAddEntry", "onRemoveEntry", "onUpdateComboBox", "onUpdateFieldValue", "onAddCustomField", "onDeleteCustomField", "onUpdateSectionData"]))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_22)), [
                [_vShow, _ctx.reportSettings && _ctx.reportSettings.sections[section].visible]
              ])
            }), 128)),
            _withDirectives(_createElementVNode("div", _hoisted_23, [
              (_ctx.reportData && _ctx.reportCustomFields && _ctx.reportSettings)
                ? (_openBlock(), _createBlock(_component_GroupedProcesses, {
                    key: 0,
                    ref: "processes",
                    editMode: _ctx.editMode,
                    highlightSection: _ctx.highlightedSection,
                    customFields: _ctx.reportCustomFields.processes,
                    processes: _ctx.reportData.processes,
                    siteDuration: _ctx.reportData.site_duration,
                    settings: _ctx.reportSettings,
                    approved: _ctx.isApproved,
                    oaiEntries: _ctx.oaiProcesses,
                    validationError: _ctx.validationError,
                    sectionConfig: _ctx.sectionsConfig?.processes,
                    onActiveEditMode: _cache[10] || (_cache[10] = ($event: any) => (_ctx.editMode = true)),
                    onAddHighlight: _cache[11] || (_cache[11] = ($event: any) => (_ctx.highlightedSection = 'processes')),
                    onAddProcessEntry: _ctx.addSectionRowEntry,
                    onRemoveProcessEntry: _ctx.removeSectionRowEntry,
                    onUpdateFieldValue: _ctx.updateProcessFieldValue,
                    onUpdateComboBox: _ctx.updateProcessComboBox,
                    onAddCustomField: _ctx.handleAddCustomField,
                    onDeleteCustomField: _ctx.handleDeleteCustomField,
                    onUpdateSectionData: _cache[12] || (_cache[12] = ($event: any) => (_ctx.updateSectionData($event, 'processes')))
                  }, null, 8, ["editMode", "highlightSection", "customFields", "processes", "siteDuration", "settings", "approved", "oaiEntries", "validationError", "sectionConfig", "onAddProcessEntry", "onRemoveProcessEntry", "onUpdateFieldValue", "onUpdateComboBox", "onAddCustomField", "onDeleteCustomField"]))
                : _createCommentVNode("", true)
            ], 512), [
              [_vShow, _ctx.reportSettings && _ctx.reportSettings.sections.processes.visible]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_24, [
              (_ctx.reportData)
                ? (_openBlock(), _createBlock(_component_CustomGallery, {
                    key: 0,
                    ref: "gallery",
                    images: _ctx.reportData.images,
                    editMode: _ctx.editMode,
                    highlightSection: _ctx.highlightedSection,
                    onActiveEditMode: _cache[13] || (_cache[13] = ($event: any) => (_ctx.editMode = true)),
                    onAddHighlight: _cache[14] || (_cache[14] = ($event: any) => (_ctx.highlightedSection = 'gallery')),
                    onUpdateGalleryImages: _ctx.updateGalleryImages,
                    onRemoveGalleryImage: _ctx.removeGalleryImage,
                    approved: _ctx.isApproved
                  }, null, 8, ["images", "editMode", "highlightSection", "onUpdateGalleryImages", "onRemoveGalleryImage", "approved"]))
                : _createCommentVNode("", true)
            ], 512), [
              [_vShow, _ctx.reportSettings && _ctx.reportSettings.sections.gallery?.visible]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_25, [
              (_ctx.reportData)
                ? (_openBlock(), _createBlock(_component_NotesSection, {
                    key: 0,
                    ref: "notes",
                    editMode: _ctx.editMode,
                    highlightSection: _ctx.highlightedSection,
                    note: _ctx.reportData.notes,
                    approved: _ctx.isApproved,
                    validationError: _ctx.validationError,
                    onActiveEditMode: _cache[15] || (_cache[15] = ($event: any) => (_ctx.editMode = true)),
                    onAddHighlight: _cache[16] || (_cache[16] = ($event: any) => (_ctx.highlightedSection = 'notes')),
                    onUpdateSectionData: _cache[17] || (_cache[17] = ($event: any) => (_ctx.updateSectionData($event, 'notes')))
                  }, null, 8, ["editMode", "highlightSection", "note", "approved", "validationError"]))
                : _createCommentVNode("", true)
            ], 512), [
              [_vShow, _ctx.reportSettings && _ctx.reportSettings.sections.notes.visible]
            ]),
            _withDirectives(_createElementVNode("div", _hoisted_26, [
              (_ctx.reportData)
                ? (_openBlock(), _createBlock(_component_DisturbancesSection, {
                    ref: "disturbances",
                    key: _ctx.disturbancesSectionKey,
                    date: new Date(_ctx.$route.params.date as string),
                    editMode: _ctx.editMode,
                    highlightSection: _ctx.highlightedSection,
                    nonWorkingDays: _ctx.projectDurationSettings?.non_working_days || [],
                    approved: _ctx.isApproved,
                    validationError: _ctx.validationError,
                    onActiveEditMode: _cache[18] || (_cache[18] = ($event: any) => (_ctx.editMode = true)),
                    onAddHighlight: _cache[19] || (_cache[19] = ($event: any) => (_ctx.highlightedSection = 'disturbances'))
                  }, null, 8, ["date", "editMode", "highlightSection", "nonWorkingDays", "approved", "validationError"]))
                : _createCommentVNode("", true)
            ], 512), [
              [_vShow, _ctx.reportSettings && _ctx.reportSettings.sections.disturbances.visible]
            ])
          ])
        ])
      ], 2),
      (_ctx.isLoadingReport)
        ? (_openBlock(), _createBlock(_component_LoadingSection, {
            key: 1,
            loading: _ctx.isLoadingReport
          }, null, 8, ["loading"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}