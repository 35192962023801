import { RouteLocationRaw } from "vue-router";
import i18n from "shared/i18n";
import { PublicToken } from "shared/types/PublicToken";

const { localeText } = i18n.global;

const getPathname = (publicToken: PublicToken) => {
  const cameraIds = publicToken.camera_ids.join(",");
  const ids = `/${publicToken.customer_name}/${publicToken.site_id}/${encodeURIComponent(
    cameraIds,
  )}`;
  if (publicToken.claims.includes("camera_gallery")) {
    return `${ids}/gallery`;
  }
  if (publicToken.claims.includes("camera_daily_timelapse")) {
    return `${ids}/daily_timelapse`;
  }
  if (publicToken.claims.includes("camera_project_timelapse")) {
    return `${ids}/project_timelapse`;
  }
  return `${ids}/live`;
};

export const createUrl = (publicToken: PublicToken) => {
  const pathname = getPathname(publicToken);
  const token = `${publicToken._id}:${publicToken.token}`;
  const origin =
    window.location.hostname === "localhost" ? "http://localhost:8082" : "https://p.oculai.de";
  return `${origin}${pathname}?token=${encodeURIComponent(token)}&lang=${localeText}`;
};

export const createOriginRouteLocation = (publicToken: PublicToken): RouteLocationRaw => {
  if (publicToken.camera_ids.length !== 1) {
    return {
      name: "Dashboard",
      params: { customer_name: publicToken.customer_name, site_id: publicToken.site_id },
    };
  }
  const params = {
    customer_name: publicToken.customer_name,
    site_id: publicToken.site_id,
    camera_id: publicToken.camera_ids[0],
  };
  if (publicToken.claims.includes("camera_gallery")) {
    return {
      name: "Gallery",
      params,
    };
  }
  if (publicToken.claims.includes("camera_daily_timelapse")) {
    return {
      name: "DailyTimelapse",
      params,
    };
  }
  if (publicToken.claims.includes("camera_project_timelapse")) {
    return {
      name: "ProjectTimelapse",
      params,
    };
  }
  return {
    name: "Live",
    params,
  };
};

export default {
  createUrl,
  createOriginRouteLocation,
};
