<template>
  <!-- @vue-skip fix https://github.com/vuejs/language-tools/issues/3138-->
  <Sidebar>
    <div class="flex flex-col relative gap-4" style="height: 100vh">
      <PageHeader>
        <div class="flex gap-3 md:px-6 px-4">
          <div>{{ $t("analytics.planner.title") }}</div>
          <PlannerProgressBadge origin="planner" />
          <PlannerDeltaBadge origin="planner" />
        </div>
        <template #content>
          <div class="flex min-h-9 items-center flex-row gap-2" v-if="!noData">
            <div class="flex items-center">
              <button
                @click="zoomOut()"
                type="button"
                class="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-500 hover:text-yellow focus:z-10"
              >
                <MinusIcon class="h-5 w-5" aria-hidden="true" />
              </button>
              <input
                type="range"
                min="0"
                max="7"
                class="h-4 w-30 rounded bg-yellow-200 focus:bg-gray-300 hidden sm:block"
                :value="presetIndex"
                @input="handleRangeChange"
              />
              <button
                @click="zoomIn()"
                type="button"
                class="relative -ml-px inline-flex items-center rounded-r-md px-2 py-2 text-gray-500 hover:text-yellow focus:z-10"
              >
                <PlusIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
            <span class="isolate inline-flex rounded-md shadow-sm font-medium">
              <button
                @click="undoChange"
                v-if="currentMode === 'revision'"
                type="button"
                class="inline-flex items-center rounded-l-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 capitalize"
              >
                <ArrowUturnLeftIcon class="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                @click="redoChange"
                v-if="currentMode === 'revision'"
                type="button"
                class="inline-flex items-center rounded-r-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 capitalize"
              >
                <ArrowUturnRightIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </span>
            <Menu as="div">
              <MenuButton
                type="button"
                class="inline-flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 capitalize"
              >
                {{ $t(`analytics.planner.modes.${currentMode}`) }}
                <ChevronDownIcon class="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
              </MenuButton>
              <MenuItems
                class="absolute right-50 z-50 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg focus:outline-none"
              >
                <MenuItem
                  v-slot="{ active }"
                  v-for="mode in modes"
                  :key="mode"
                  @click="changeMode(mode)"
                  class="capitalize"
                >
                  <a
                    href="#"
                    :class="[
                      active ? 'bg-gray-100 text-yellow-600' : 'text-gray-700',
                      'block px-4 py-2 text-sm',
                    ]"
                    >{{ $t(`analytics.planner.modes.${mode}`) }}</a
                  >
                </MenuItem>
              </MenuItems>
            </Menu>
            <div
              v-if="currentMode === 'revision' && hasChanges"
              class="flex-row gap-2 hidden lg:flex"
            >
              <button
                type="button"
                class="focus:outline-none inline-flex items-center rounded-md border border-transparent font-medium shadow-sm px-4 py-2 text-sm bg-yellow-600 hover:bg-yellow-700 focus:ring-transparent text-white"
                @click="createNewRevision"
              >
                {{ $t("buttons.save") }}
              </button>
              <button
                type="button"
                class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 font-medium shadow-sm px-4 py-2 text-sm focus:ring-transparent"
                @click="revertChangesWithConfirmation"
              >
                {{ $t("buttons.cancel") }}
              </button>
            </div>
            <Menu as="div" class="inline-block text-left leading-none">
              <MenuButton class="flex items-center rounded-full text-gray-500 hover:text-gray-600">
                <EllipsisVerticalIcon class="h-8 w-8" aria-hidden="true" />
              </MenuButton>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="absolute right-5 z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray/5 focus:outline-none"
                >
                  <div class="py-1 divide-y">
                    <MenuItem v-slot="{ active }" @click="clickPlannerImport()">
                      <button
                        type="button"
                        :disabled="!canImport"
                        :class="[
                          active && canImport ? 'bg-gray-100 text-gray-900' : '',
                          'extraMenuItem',
                          canImport ? ' text-gray-700' : 'text-gray-300',
                        ]"
                      >
                        <ArrowUpTrayIcon class="h-4 inline-flex mr-2" aria-hidden="true" />
                        <span>{{ $t("buttons.import") }}</span>
                      </button>
                    </MenuItem>
                    <MenuItem as="template" v-slot="{ active }">
                      <Menu
                        as="div"
                        :class="[
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'extraMenuItem',
                        ]"
                      >
                        <MenuButton class="flex items-center w-full justify-between rounded-ful">
                          <div>
                            <ArrowDownTrayIcon class="h-4 inline-flex mr-2" aria-hidden="true" />
                            <span>{{ $t("buttons.export_plain") }}</span>
                          </div>
                          <ChevronRightIcon class="h-4" />
                        </MenuButton>
                        <transition
                          enter-active-class="transition ease-out du ration-100"
                          enter-from-class="transform opacity-0 scale-95"
                          enter-to-class="transform opacity-100 scale-100"
                          leave-active-class="transition ease-in duration-75"
                          leave-from-class="transform opacity-100 scale-100"
                          leave-to-class="transform opacity-0 scale-95"
                        >
                          <MenuItems
                            class="absolute right-64 mr-2 z-10 w-1/2 origin-right rounded-md bg-white shadow-lg ring-1 ring-gray/5 focus:outline-none"
                          >
                            <MenuItem v-slot="{ active }" @click="isPdfExportModalOpen = true">
                              <a
                                href="#"
                                :class="[
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'group flex items-center px-4 py-2 text-sm',
                                ]"
                              >
                                <font-awesome-icon
                                  icon="fa-solid fa-file-pdf"
                                  class="h-4 text-red"
                                />
                                <span class="ml-1"> PDF </span>
                              </a>
                            </MenuItem>
                            <MenuItem v-slot="{ active }" @click="exportXlsx">
                              <a
                                href="#"
                                :class="[
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'group flex items-center px-4 py-2 text-sm',
                                ]"
                              >
                                <font-awesome-icon
                                  icon="fa-solid fa-file-excel"
                                  class="h-4 text-green"
                                />
                                <span class="ml-1"> Excel </span>
                              </a>
                            </MenuItem>
                          </MenuItems>
                        </transition>
                      </Menu>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <button
                        @click="
                          nonWorkingDaysModalOpen = true;
                          trackEvent('planner_non-working-days-editor_click');
                        "
                        type="button"
                        :class="[
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'extraMenuItem',
                        ]"
                      >
                        <span>{{ $t("analytics.planner.non_working_days") }}</span>
                      </button>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <button
                        @click="toggleCriticalPathVisibility"
                        type="button"
                        :class="[
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'extraMenuItem',
                        ]"
                      >
                        <input
                          type="checkbox"
                          :checked="isDependencyFeatureEnabled"
                          class="h-4 w-4 rounded border-gray-300 text-orange-400 focus:bg-gray-50 pointer-events-none mr-2"
                        />
                        <span> {{ $t("analytics.planner.critical_path") }} </span>
                      </button>
                    </MenuItem>
                    <MenuItem v-slot="{ active }" v-if="currentMode === 'visitor'">
                      <button
                        @click="isFullScheduleShown = !isFullScheduleShown"
                        type="button"
                        :class="[
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'extraMenuItem',
                        ]"
                      >
                        <input
                          type="checkbox"
                          v-model="isFullScheduleShown"
                          class="h-4 w-4 rounded border-gray-300 text-orange-400 focus:bg-gray-50 pointer-events-none mr-2"
                        />
                        <span>{{ $t("analytics.planner.show_complete_planner") }}</span>
                      </button>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>
          </div>
          <div v-if="currentMode === 'revision' && hasChanges" class="flex lg:hidden gap-2">
            <button
              type="button"
              class="focus:outline-none inline-flex items-center rounded-md border border-transparent font-medium shadow-sm px-4 py-2 text-sm bg-yellow-600 hover:bg-yellow-700 focus:ring-transparent text-white"
              @click="createNewRevision"
            >
              {{ $t("buttons.save") }}
            </button>
            <button
              type="button"
              class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-50 font-medium shadow-sm px-4 py-2 text-sm focus:ring-transparent"
              @click="revertChangesWithConfirmation"
            >
              {{ $t("buttons.cancel") }}
            </button>
          </div>
        </template>
      </PageHeader>

      <Modal
        :open="openImportModal && !loading"
        @close="openImportModal = false"
        customCls="sm:w-1/2 w-1/4 pt-24 pb-32"
      >
        <template #content>
          <FileInputPlanner @updateFile="importPlanner($event)" class="w-5/6 mx-auto my-4" />
        </template>
      </Modal>
      <template v-if="loading || isProjectDurationSettingsLoading">
        <div class="absolute inset-0 bg-white opacity-60" style="z-index: 9998" />
        <div class="absolute inset-0 flex items-center justify-center" style="z-index: 9999">
          <LoadingSection :loading="true" />
        </div>
      </template>

      <NoDataYet
        v-if="noData && !loading"
        feature="planner"
        @importPlanner="importPlanner($event)"
      />
      <bryntum-scheduler-pro
        v-if="!noData || loading"
        ref="schedulerProRef"
        :key="schedulerProKey"
        :resources="initialSchedulerProData?.resources"
        :events="initialSchedulerProData?.events"
        :dependencies="initialSchedulerProData?.dependencies"
        v-bind="schedulerProConfig"
        @beforeEventAdd="beforeEventAddHandler($event)"
        @beforeEventEdit="beforeEventEditHandler($event)"
        @eventClick="handleEventClick"
        @eventDblClick="handleEventDblClick"
        @dataChange="updateHasChanges"
        @cellDblClick="handleCellDblClick"
        @cellClick="handleCellClick"
        @wheel="handleWheel"
        @eventMouseEnter="handleEventMouseEnter"
        :eventMenuFeature="{
          ...(schedulerProConfig?.eventMenuFeature ? schedulerProConfig.eventMenuFeature : {}),
          items: {
            ...(schedulerProConfig.eventMenuFeature?.items
              ? schedulerProConfig.eventMenuFeature.items
              : {}),
            showProcesses:
              currentMode === 'visitor' && hasPermission('processes_user')
                ? {
                    ...(schedulerProConfig.eventMenuFeature?.items?.showProcesses
                      ? schedulerProConfig.eventMenuFeature.items.showProcesses
                      : {}),
                    onItem: showProcessesOnEventClick,
                  }
                : false,
            hideProcesses:
              currentMode === 'visitor' && hasPermission('processes_user')
                ? {
                    ...(schedulerProConfig.eventMenuFeature?.items?.hideProcesses
                      ? schedulerProConfig.eventMenuFeature.items.hideProcesses
                      : {}),
                    onItem: hideProcessesOnEventClick,
                  }
                : false,
            addActual: {
              ...(schedulerProConfig.eventMenuFeature?.items?.addActual
                ? schedulerProConfig.eventMenuFeature.items.addActual
                : {}),
              onItem: handleAddActual,
            },
            editActual: {
              ...(schedulerProConfig.eventMenuFeature?.items?.editActual
                ? schedulerProConfig.eventMenuFeature.items.editActual
                : {}),
              onItem: handleEditActual,
            },
          },
        }"
        :scheduleMenuFeature="{
          ...(schedulerProConfig?.scheduleMenuFeature
            ? schedulerProConfig.scheduleMenuFeature
            : {}),
          items: {
            ...(schedulerProConfig?.scheduleMenuFeature?.items
              ? schedulerProConfig.scheduleMenuFeature.items
              : {}),
            showProcesses:
              currentMode === 'visitor' && hasPermission('processes_user')
                ? {
                    ...(schedulerProConfig.scheduleMenuFeature?.items?.showProcesses
                      ? schedulerProConfig.scheduleMenuFeature?.items?.showProcesses
                      : {}),
                    onItem: showProcessesOnMenu,
                  }
                : false,
            hideProcesses:
              currentMode === 'visitor' && hasPermission('processes_user')
                ? {
                    ...(schedulerProConfig.scheduleMenuFeature?.items?.hideProcesses
                      ? schedulerProConfig.scheduleMenuFeature.items.hideProcesses
                      : {}),
                    onItem: hideProcessesOnMenu,
                  }
                : false,
            addActual: {
              ...(schedulerProConfig.scheduleMenuFeature?.items?.addActual
                ? schedulerProConfig.scheduleMenuFeature.items.addActual
                : {}),
              onItem: handleAddActual,
            },
            editActual: {
              ...(schedulerProConfig.scheduleMenuFeature?.items?.editActual
                ? schedulerProConfig.scheduleMenuFeature.items.editActual
                : {}),
              onItem: handleEditActual,
            },
            reportMissingProcess: {
              ...(schedulerProConfig.scheduleMenuFeature?.items?.reportMissingProcess
                ? schedulerProConfig.scheduleMenuFeature.items.reportMissingProcess
                : {}),
              onItem: openMissingProcessModal,
            },
          },
        }"
        :cellMenuFeature="{
          ...(schedulerProConfig?.cellMenuFeature ? schedulerProConfig.cellMenuFeature : {}),
          items: {
            ...(schedulerProConfig?.cellMenuFeature?.items
              ? schedulerProConfig.cellMenuFeature.items
              : {}),
            showProcesses:
              currentMode === 'visitor' &&
              schedulerProConfig.cellMenuFeature &&
              hasPermission('processes_user')
                ? {
                    ...(schedulerProConfig.cellMenuFeature?.items.showProcesses
                      ? schedulerProConfig.cellMenuFeature.items.showProcesses
                      : {}),
                    onItem: showProcessesOnMenu,
                  }
                : false,
            hideProcesses:
              currentMode === 'visitor' && hasPermission('processes_user')
                ? {
                    ...(schedulerProConfig?.cellMenuFeature?.items?.hideProcesses
                      ? schedulerProConfig.cellMenuFeature.items.hideProcesses
                      : {}),
                    onItem: hideProcessesOnMenu,
                  }
                : false,
          },
        }"
      />
      <EventEditor
        :mode="eventEditorMode"
        :eventRecord="eventRecord"
        :scheduler="getSchedulerPro()"
        @saveEvent="saveEvent"
        @cancelEdit="cancelEdit"
        :now="eventNow"
        @commentAdded="handleCommentAdded"
        @commentDeleted="handleCommentDeleted"
        @nameEdited="handleNameEdited"
        :projectDurationSettings="projectDurationSettings"
      />
      <PdfExportModal
        :open="isPdfExportModalOpen"
        :loading="pdfExportIsLoading"
        @canceled="isPdfExportModalOpen = false"
        @confirmed="exportPdf"
      />
      <NonWorkingDaysModal
        v-if="nonWorkingDaysModalOpen"
        :open="nonWorkingDaysModalOpen"
        @close="nonWorkingDaysModalOpen = false"
      />
      <ProcessSideBar
        :open="isProcessSideBarOpen"
        @closed="isProcessSideBarOpen = false"
        :processes="processSideBarProcesses"
        :resourceFullName="processSideBarResourceFullName"
        @processUpdate="handleProcessUpdate"
      />
      <ActualSideBar
        ref="actualSideBar"
        :open="isActualSideBarOpen"
        @closed="isActualSideBarOpen = false"
        :cameraList="sourceCamerasList"
        :resourceFullName="actualSideBarResourceFullName"
        :sourceId="eventDblClickSourceId"
        :events="eventsOfResource"
        @commentAdded="handleCommentAdded"
        @commentDeleted="handleCommentDeleted"
        @actualUpdated="handleActualEventUpdate"
        :projectDurationSettings="projectDurationSettings"
      />
      <SchedulerProcessEventTooltip
        v-if="getSchedulerPro()"
        :key="hoveredProcessEvent?.eventRecord?.id"
        :scheduler="getSchedulerPro()"
        :event="hoveredProcessEvent"
        @eventMouseOut="hoveredProcessEvent = null"
      />
      <MissingProcessReportModal
        :open="isMissingProcessReportModalOpen"
        :date="missingProcessReportDate"
        :tags="missingProcessReportTags"
        @close="isMissingProcessReportModalOpen = false"
      />
    </div>
  </Sidebar>
</template>

<script lang="ts">
import {
  EventModel,
  GridColumnConfig,
  Model,
  ResourceModel,
  SchedulerPro,
  SchedulerResourceModel,
  ViewPreset,
  ViewPresetConfig,
} from "@bryntum/schedulerpro";
import { BryntumSchedulerPro } from "@bryntum/schedulerpro-vue-3";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faFileExcel, faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  EllipsisVerticalIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/vue/24/outline";
import { addDays, set } from "date-fns";
import { defineComponent } from "vue";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import * as XLSX from "xlsx";
import LoadingSection from "shared/components/loading_state/LoadingSection.vue";
import Modal from "shared/components/modals/Modal.vue";
import { useHierarchyTags } from "shared/composables/hierarchyTags";
import {
  useCreateNewPlanRevision,
  useHolidaysInScheduler,
  useNonWorkingDaysInScheduler,
  useOutages,
  usePlanConfig,
} from "shared/composables/planner";
import { useShortenedProcessesWithTags } from "shared/composables/process";
import { useProjectDurationSettings } from "shared/composables/projectDurationSettings";
import { useSectionMasks } from "shared/composables/sectionMasks";
import {
  useConfirmationModal,
  useSaveBeforeLeaveConfirmationModal,
} from "shared/composables/toast";
import { useTrackEvent } from "shared/composables/tracking";
import PlanMixins from "shared/mixins/PlanMixins";
import SchedulerProMixins from "shared/mixins/SchedulerProMixins";
import PlannerRepository from "shared/repositories/PlannerRepository";
import { apiClient } from "shared/repositories/clients";
import durationService from "shared/services/durationService";
import logger from "shared/services/logger";
import {
  EventToEdit,
  PlanConfig,
  PlannerComment,
  PlannerMode,
  SimplifiedPlannerProcess,
} from "shared/types/Plan";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { NonWorkingDay } from "shared/types/ProjectDurationSettings";
import {
  SchedulerProBaseEvent,
  SchedulerProMouseEvent,
  SchedulerProResourceItem,
} from "shared/types/SchedulerPro";
import { SectionMask } from "shared/types/SectionMask";
import { Stream } from "shared/types/Stream";
import {
  editPlannedEvent,
  resetLag,
  showInfoMenuItem,
} from "shared/views/planner/SchedulerProConfig";
import {
  updateParentEventsForDependencies,
  updateParentEventsForResourceAndItsParents,
} from "shared/views/planner/SchedulerProParentEvents";
import NoDataYet from "@/components/layout/NoDataYet.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import { useCurrentProject } from "@/composables/project";
import { useStreams } from "@/composables/stream";
import { getConfig } from "@/services/config";
import textService from "@/services/textService";
import { SchedulerProPdfExportConfig } from "@/types/SchedulerPro";
import ActualSideBar from "@/views/planner/components/ActualSideBar.vue";
import EventEditor from "@/views/planner/components/EventEditor.vue";
import FileInputPlanner from "@/views/planner/components/FileInputPlanner.vue";
import MissingProcessReportModal from "@/views/planner/components/MissingProcessReportModal.vue";
import NonWorkingDaysModal from "@/views/planner/components/NonWorkingDaysModal.vue";
import PdfExportModal from "@/views/planner/components/PdfExportModal.vue";
import PlannerDeltaBadge from "@/views/planner/components/PlannerDeltaBadge.vue";
import PlannerProgressBadge from "@/views/planner/components/PlannerProgressBadge.vue";
import ProcessSideBar from "@/views/planner/components/ProcessSideBar.vue";
import SchedulerProcessEventTooltip from "@/views/process_gantt/components/SchedulerProcessEventTooltip.vue";
import { TagGroupedProcess } from "@/views/process_gantt/groupProcesses";
import { schedulerProConfigMode } from "./SchedulerProConfigMode";

library.add(faFilePdf, faFileExcel);

const now = set(new Date(), { seconds: 0, milliseconds: 0 });

const configs = {
  visitor: schedulerProConfigMode("visitor", now),
  revision: schedulerProConfigMode("revision", now),
};

type ReducedPlannerMode = Exclude<PlannerMode, "tracker" | "hierarchy" | "process">;

export default defineComponent({
  name: "Planner",
  components: {
    MissingProcessReportModal,
    SchedulerProcessEventTooltip,
    Sidebar,
    PageHeader,
    FileInputPlanner,
    Modal,
    NoDataYet,
    ActualSideBar,
    ChevronDownIcon,
    ArrowUturnLeftIcon,
    ArrowUturnRightIcon,
    ArrowDownTrayIcon,
    EventEditor,
    BryntumSchedulerPro,
    Menu,
    MenuItem,
    MenuItems,
    MenuButton,
    LoadingSection,
    PdfExportModal,
    FontAwesomeIcon,
    PlusIcon,
    MinusIcon,
    EllipsisVerticalIcon,
    ChevronRightIcon,
    ProcessSideBar,
    ArrowUpTrayIcon,
    NonWorkingDaysModal,
    PlannerProgressBadge,
    PlannerDeltaBadge,
  },
  mixins: [SchedulerProMixins, PlanMixins],
  data() {
    return {
      initialized: false,
      planConfig: null as PlanConfig | null,
      initialPlanConfig: null as PlanConfig | null,
      eventEditorMode: "hide" as "hide" | "edit",
      eventRecord: {} as EventToEdit,
      isOperationLoading: false as boolean,
      hasChanges: false as boolean,
      isDependencyFeatureEnabled: false as boolean,
      isPdfExportModalOpen: false as boolean,
      presetIndex: 0 as number,
      isProcessSideBarOpen: false as boolean,
      processSideBarProcesses: [] as SimplifiedPlannerProcess[],
      processSideBarResourceFullName: "" as string,
      isActualSideBarOpen: false as boolean,
      actualSideBarResourceFullName: "" as string,
      sourceCamerasList: [] as string[],
      eventsOfResource: [] as EventModel[],
      eventDbClicked: {} as EventModel,
      clickTimeoutId: "" as unknown as ReturnType<typeof setTimeout>,
      eventDblClickSourceId: "",
      openImportModal: false as boolean,
      isFullScheduleShown: false,
      nonWorkingDaysModalOpen: false,
      pdfExportIsLoading: false,
      hoveredProcessEvent: null as SchedulerProMouseEvent | null,
      isMissingProcessReportModalOpen: false,
      missingProcessReportDate: null as Date | null,
      missingProcessReportTags: null as TagGroupedProcess["tags"] | null,
    };
  },
  computed: {
    noData() {
      return !this.planConfig;
    },
    eventNow() {
      return now;
    },
    modes() {
      return ["visitor", "revision"].filter((mode) => mode) as ReducedPlannerMode[];
    },
    schedulerProConfig() {
      return configs[this.currentMode];
    },
    schedulerProKey() {
      return `${this.currentMode}:${this.planConfig?.plan._id}`;
    },
    initialSchedulerProData() {
      if (!this.planConfig) {
        return {
          resources: null,
          events: null,
          dependencies: null,
        };
      }

      const isHolidayFn = this.createIsHolidayFn();
      const durationLabelFn = this.createEventDurationFn();
      const schedulerProData = this.createSchedulerProData(this.planConfig, now);
      return {
        ...schedulerProData,
        resources: this.mapIsHolidayFn(schedulerProData.resources, isHolidayFn),
        events: schedulerProData.events.map((event) => ({
          ...event,
          durationLabelFn,
          trackEvent: this.trackEvent,
        })),
      };
    },
    currentMode(): ReducedPlannerMode {
      return (
        this.modes.find((mode) => mode === (this.$route.query.mode as ReducedPlannerMode)) ||
        "visitor"
      );
    },
    loading() {
      return (
        this.isOperationLoading ||
        this.isPlanConfigLoading ||
        this.areHierarchyTagsLoading ||
        this.isCreateNewPlanRevisionLoading ||
        this.areShortenedProcessesWithTagsLoading
      );
    },
    canImport() {
      return this.hierarchyTags.length > 0;
    },
    processes() {
      if (!this.shortenedProcessesWithTags || this.hierarchyTags.length === 0) {
        return null;
      }

      const hierarchyTagMap = this.hierarchyTags.reduce((acc, current) => {
        current.source_ids.forEach((id) => {
          if (!acc[id]) {
            acc[id] = {};
          }

          if (!acc[id][current.type]) {
            acc[id][current.type] = current.name;
          }
        });

        return acc;
      }, {} as { [key: string]: { [key: string]: string } });

      return this.shortenedProcessesWithTags.map((process) => {
        const tags =
          (process.planner_item_mapping.source_id
            ? hierarchyTagMap[process.planner_item_mapping.source_id]
            : null) || {};
        return {
          ...process,
          tags,
        };
      });
    },
  },
  watch: {
    currentMode(value) {
      if (this.planConfig) {
        this.planConfig = {
          ...this.createPlanConfigFromSchedulerData(this.getSchedulerPro()),
          plan: this.planConfig.plan,
        } as PlanConfig;
      }
      this.$nextTick(() => {
        this.updateCriticalPathVisibility();
        this.isFullScheduleShown = value !== "visitor";
      });
    },
    planConfig(newValue: PlanConfig | null, oldValue: PlanConfig | null) {
      const scrollState = this.getSchedulerPro()?.storeScroll();

      const isProjectCompleted = this.currentProjectV2?.status === "completed";

      setTimeout(async () => {
        if (!this.getSchedulerPro()) {
          // component probably unmounted
          return;
        }
        this.setProjectStartEndTimeFlag(this.getSchedulerPro(), isProjectCompleted);
        this.refreshHolidaysInScheduler(this.getSchedulerPro());
        this.refreshNonWorkingDaysInScheduler(this.getSchedulerPro());

        if (oldValue && scrollState) {
          this.restoreView(this.getSchedulerPro(), this.presetIndex, scrollState);
        } else {
          this.resetView(this.getSchedulerPro(), this.schedulerProConfig.viewPreset as string);
          this.updatePresetIndex();
        }
      }, 100);
    },
    isDependencyFeatureEnabled() {
      this.getSchedulerPro().features.dependencies.disabled = !this.isDependencyFeatureEnabled;
    },
    isFullScheduleShown() {
      this.toggleNonTrackedVisibility();
    },
    projectDurationSettings() {
      if (this.getSchedulerPro()) {
        this.refreshHolidaysInScheduler(this.getSchedulerPro());
        this.refreshNonWorkingDaysInScheduler(this.getSchedulerPro());
      }
    },
    vueQueryPlanConfig() {
      this.storeVueQueryPlanConfig();
    },
  },
  mounted() {
    this.trackEvent("planner_view");
    this.storeVueQueryPlanConfig();
  },
  beforeRouteLeave(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext,
  ) {
    if (!this.hasChanges) {
      next();
    } else {
      this.showSaveBeforeLeaveConfirmationModal().then((confirmed) => {
        if (confirmed) {
          next();
        }
      });
    }
  },
  methods: {
    storeVueQueryPlanConfig() {
      this.planConfig = this.vueQueryPlanConfig ?? null;
      this.initialPlanConfig = this.vueQueryPlanConfig ?? null;
      this.resetHasChanges();

      if (!this.initialized && this.planConfig) {
        this.initialized = true;
        setTimeout(() => {
          if (!this.getSchedulerPro()) {
            // component probably unmounted
            return;
          }
          this.updatePresetIndex();
          this.updateCriticalPathVisibility();
          this.toggleNonTrackedVisibility();
          this.scrollToActiveActual(this.getSchedulerPro());
        }, 300);
      }
    },
    mapIsHolidayFn<T extends { children: T[] }>(
      resources: T[],
      isHolidayFn: (date: Date) => NonWorkingDay | undefined,
    ): T[] {
      return resources.map((resource) => ({
        ...resource,
        isHolidayFn,
        children: this.mapIsHolidayFn(resource.children, isHolidayFn),
      }));
    },
    createIsHolidayFn() {
      const getProjectDurationSettings = () => this.projectDurationSettings;
      return (date: Date) => {
        const projectDurationSettings = getProjectDurationSettings();
        if (!projectDurationSettings) {
          return undefined;
        }
        return projectDurationSettings.non_working_days.find((non_working_day) => {
          const start = non_working_day.start_date;
          const end = addDays(non_working_day.end_date, 1);
          return date >= start && date < end;
        });
      };
    },
    createEventDurationFn() {
      const getProjectDurationSettings = () => this.projectDurationSettings;
      return (event: EventModel, options?: { excludeDisturbances?: boolean }) => {
        const projectDurationSettings = getProjectDurationSettings();
        if (!projectDurationSettings) {
          return null;
        }
        const durationSettings = options?.excludeDisturbances
          ? projectDurationSettings.settingsWithoutDisturbances
          : projectDurationSettings.settings;
        const workingDays = durationService.calculateDuration(
          durationSettings,
          event.startDate as Date,
          event.endDate as Date,
        ).workingDays;
        const formattedWorkingDays = workingDays.toLocaleString(this.$i18n.locale, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 1,
        });
        return `${formattedWorkingDays} ${this.$t("analytics.planner.working_days_label", {
          count: workingDays,
        })}`;
      };
    },
    changeMode(mode: PlannerMode) {
      if (this.currentMode === mode) {
        return;
      }
      if (this.hasChanges) {
        this.showConfirmationModal({
          header: this.$t("err.save_before_leave_title"),
          message: this.$t("err.save_before_mode_switch"),
          confirmAction: this.$t("buttons.leave_page"),
          cancelAction: this.$t("buttons.stay_page"),
        }).then((confirmed) => {
          if (confirmed) {
            this.resetHasChanges();
            this.$router.replace({ query: { mode } });
          }
        });
      } else {
        this.$router.replace({ query: { mode } });
      }
    },
    getSchedulerPro(): SchedulerPro {
      const schedulerProRef = this.$refs.schedulerProRef as {
        instance: { value: SchedulerPro };
      };

      return schedulerProRef?.instance?.value;
    },
    createNewRevision() {
      if (!this.planConfig) {
        return;
      }
      this.trackEvent("planner_revision_create");
      const planConfig = {
        plan: this.planConfig.plan,
        ...this.createPlanConfigFromSchedulerData(this.getSchedulerPro()),
        planner_comments: undefined,
      };
      this.createNewPlanRevision({ plan: planConfig, mergedPlannerItems: [] })
        .then(() => {
          this.resetHasChanges();
        })
        .catch(() => undefined);
    },
    revertChangesWithConfirmation() {
      this.showConfirmationModal({
        header: this.$t("err.save_before_leave_title"),
        message: this.$t("err.save_before_mode_switch"),
        confirmAction: this.$t("buttons.revert_changes"),
        cancelAction: this.$t("buttons.stay_page"),
      }).then((confirmed) => {
        if (confirmed) {
          this.revertAllChanges(this.getSchedulerPro());
          this.resetHasChanges();
        }
      });
    },
    undoChange() {
      if (this.getSchedulerPro().project.stm.canUndo) {
        this.getSchedulerPro().project.stm.undo();
      } else {
        this.showToast("warning", this.$t("analytics.planner.nothing_to_undo_message"));
      }
    },
    redoChange() {
      if (this.getSchedulerPro().project.stm.canRedo) {
        this.getSchedulerPro().project.stm.redo();
      } else {
        this.showToast("warning", this.$t("analytics.planner.nothing_to_redo_message"));
      }
    },
    handleRangeChange(event: { target: HTMLInputElement }) {
      const newPresetsValue = parseInt(event.target.value, 10);
      if (newPresetsValue > this.presetIndex) {
        // Slider moved to the right
        this.zoomIn();
      } else if (newPresetsValue < this.presetIndex) {
        // Slider moved to the left
        this.zoomOut();
      }
      this.updatePresetIndex();
    },
    zoomIn() {
      this.getSchedulerPro().zoomIn();
      this.updatePresetIndex();
    },
    zoomOut() {
      // for some reason it's possible to zoom out also when on preset 0
      // the conditions is there to fix that
      if (this.presetIndex > 0) {
        this.getSchedulerPro().zoomOut();
        this.updatePresetIndex();
      }
    },
    beforeEventAddHandler(event: SchedulerProBaseEvent) {
      editPlannedEvent(event.source, event.eventRecord.resourceId as string);
      return false;
    },
    beforeEventEditHandler(event: SchedulerProBaseEvent) {
      if (
        this.currentMode === "visitor" &&
        (event.eventRecord.getData("type") === "actual" ||
          event.eventRecord.getData("type")?.startsWith("process_"))
      ) {
        return false;
      }

      if (this.currentMode === "visitor") {
        this.handleActualSidebarOpen(event);
      } else {
        const resourceOfEvent = event.source.project.resourceStore.getById(
          event.eventRecord.getData("resourceId"),
        );
        this.eventRecord = {
          _id: event.eventRecord.id,
          resourceId: event.eventRecord.getData("resourceId"),
          type: event.eventRecord.getData("type"),
          active: event.eventRecord.getData("active"),
          isCreating: event.eventRecord.isCreating,
          startDate: event.eventRecord.startDate,
          endDate: event.eventRecord.endDate,
          name: event.eventRecord.resource.name,
          sourceId: resourceOfEvent.getData("sourceId"),
        } as EventToEdit;
        this.eventEditorMode = "edit";
      }
      return false;
    },
    async saveEvent(startDate: Date, endDate: Date) {
      resetLag(this.getSchedulerPro(), this.eventRecord._id);
      const eventToUpdate = this.getSchedulerPro().project.eventStore.getById(
        this.eventRecord._id,
      ) as EventModel;
      if (eventToUpdate.getData("startDate").getTime() !== startDate.getTime()) {
        await eventToUpdate.setStartDate(startDate, false);
      }
      eventToUpdate.setEndDate(endDate);
      const resource = this.getSchedulerPro().resourceStore.getById(this.eventRecord.resourceId);
      this.eventEditorMode = "hide";
      await updateParentEventsForResourceAndItsParents(this.getSchedulerPro(), resource.parent);
      await updateParentEventsForDependencies(this.getSchedulerPro(), resource);
    },
    cancelEdit() {
      this.eventEditorMode = "hide";
    },
    getResourceStoreChanges() {
      const changes = this.getSchedulerPro()?.resourceStore.changes;
      if (!changes) {
        return null;
      }
      const filteredChanges = {
        ...changes,
        modified: changes.modified.filter(
          (change) =>
            // change.modifications has the modified fields and also the id field in it, thus check for length 2
            !(Object.keys(change.modifications).length === 2 && change.isFieldModified("comments")),
        ),
      };
      if (
        filteredChanges.added.length === 0 &&
        filteredChanges.modified.length === 0 &&
        filteredChanges.removed.length === 0
      ) {
        return null;
      }

      return filteredChanges;
    },
    updateHasChanges() {
      this.hasChanges =
        this.currentMode !== "visitor" &&
        (!!this.getResourceStoreChanges() ||
          !!this.getEventStoreChangesWithoutProcesses(this.getSchedulerPro()) ||
          !!this.getSchedulerPro()?.dependencyStore.changes);
    },
    resetHasChanges() {
      this.hasChanges = false;
    },

    exportPdf(config: SchedulerProPdfExportConfig) {
      this.trackEvent("planner_export-pdf_click", {
        scheduleRange: config.scheduleRange,
        rowsRange: config.rowsRange,
      });

      const appConfig = getConfig();
      this.pdfExportIsLoading = true;

      setTimeout(async () => {
        const scrollState = this.getSchedulerPro().storeScroll();
        try {
          if (config.scheduleRange === "completeview") {
            await this.resetView(
              this.getSchedulerPro(),
              this.schedulerProConfig.viewPreset as string,
            );
          }
          const { customer_name, site_id } = this.$route.params;
          this.getSchedulerPro().features.pdfExport.setConfig({
            scheduleRange: config.scheduleRange,
            rowsRange: config.rowsRange,
            exportServer: `${appConfig.API_BASE}planner-v2/plans/${customer_name}/${site_id}/export-pdf`,
            fetchOptions: {
              headers: {
                "Content-Type": "application/json",
                Authorization: apiClient.defaults.headers.common["Authorization"],
              },
            },
          });

          const { response } = (await this.getSchedulerPro().features.pdfExport.export({
            columns: (this.getSchedulerPro().columns as GridColumnConfig[]).map(
              (column) => column.id as string,
            ),
          })) as { response: { status: number } };
          if (response.status !== 200) {
            throw new Error("Unsuccessful response code");
          }
        } catch (error) {
          logger.error("Unable to generate PDF", error as Error);
          this.showToast(
            "error",
            this.$t("analytics.planner.unable_to_generate_pdf_error_message"),
          );
        } finally {
          this.isPdfExportModalOpen = false;
          await this.restoreView(this.getSchedulerPro(), this.presetIndex, scrollState);
          !this.isPdfExportModalOpen ? (this.pdfExportIsLoading = false) : "";
        }
      }, 100);
    },
    toggleCriticalPathVisibility() {
      this.isDependencyFeatureEnabled = !this.isDependencyFeatureEnabled;
      this.getSchedulerPro().features.dependencies.disabled = !this.isDependencyFeatureEnabled;
    },

    updateCriticalPathVisibility() {
      this.isDependencyFeatureEnabled = !this.getSchedulerPro().features.dependencies.disabled;
    },

    toggleNonTrackedVisibility() {
      if (this.isFullScheduleShown) {
        this.getSchedulerPro().resourceStore.clearFilters();
      } else {
        this.getSchedulerPro().resourceStore.filter((resource: SchedulerResourceModel) =>
          resource.getData("trackingEnabled"),
        );
      }
    },
    handleCommentAdded(comment: PlannerComment) {
      const resource = this.getSchedulerPro().resourceStore.allRecords.find(
        (resourceRecord) => resourceRecord.getData("sourceId") === comment.source_id,
      );
      if (resource) {
        resource.set("comments", [
          ...(resource.getData("comments") || []),
          { id: comment._id, source_id: comment.source_id },
        ]);
        this.trackEvent("planner_comment_create");
      }
    },
    handleCommentDeleted(comment: PlannerComment) {
      const resource = this.getSchedulerPro().resourceStore.allRecords.find(
        (resourceRecord) => resourceRecord.getData("sourceId") === comment.source_id,
      );
      if (resource) {
        resource.set(
          "comments",
          resource.getData("comments").filter(({ _id }: PlannerComment) => _id !== comment._id),
        );
      }
    },
    handleCellDblClick(event: SchedulerProResourceItem) {
      if (this.currentMode === "visitor") {
        showInfoMenuItem(event.source, event.record.id as string);
      } else {
        editPlannedEvent(event.source, event.record.id as string);
      }
    },
    handleCellClick(event: SchedulerProResourceItem & { target: HTMLElement }) {
      if (event.target.classList.contains("plannerItemWithComments")) {
        editPlannedEvent(event.source, event.record.id as string);
      }
    },
    handleNameEdited({ eventRecord, name }: { eventRecord: EventToEdit; name: string }) {
      this.eventRecord.name = name;
      const resource = this.getSchedulerPro().resourceStore.getById(eventRecord.resourceId);
      if (!resource) {
        throw new Error("Missing resource for name editing");
      }
      resource.set("name", name);
    },
    exportXlsx() {
      this.isOperationLoading = true;
      const { customer_name, site_id } = this.$route.params;
      this.trackEvent("planner_export-excel_click");
      PlannerRepository.loadPlanExportData(customer_name as string, site_id as string)
        .then((data) => {
          const workbook = XLSX.utils.book_new();
          const worksheetPlannerExport = XLSX.utils.json_to_sheet(data);
          XLSX.utils.book_append_sheet(workbook, worksheetPlannerExport, "planner data");
          XLSX.writeFile(
            workbook,
            `oculai_${textService.toNormalizedFileName(
              this.$t("analytics.planner.export_name"),
            )}.xlsx`,
          );
        })
        .catch((error) => {
          if (error?.response?.status !== 404) {
            logger.error(error);
            this.showToast("error", this.$t("analytics.planner.excel_export_error_message"));
          }
        })
        .finally(() => {
          this.isOperationLoading = false;
        });
    },
    updatePresetIndex() {
      const viewPresetName = (this.getSchedulerPro().viewPreset as ViewPreset).id as string;
      this.presetIndex = [...(this.getSchedulerPro().presets as ViewPresetConfig[])].findIndex(
        (preset) => preset.id === viewPresetName,
      );
    },
    handleWheel(e: WheelEvent) {
      if (e.ctrlKey) {
        setTimeout(() => {
          this.updatePresetIndex();
        }, 100);
      }
    },
    openResource(resource: SchedulerResourceModel, expanded: boolean) {
      this.getSchedulerPro().toggleCollapse(resource, expanded);
      for (const child of resource.children as SchedulerResourceModel[]) {
        if (
          !child.isExpanded(this.getSchedulerPro().resourceStore) &&
          child.getData("children").length
        ) {
          if (child.getData("children").length) this.openResource(child, expanded);
        }
      }
    },
    openParentsResources(resource: SchedulerResourceModel | Model) {
      if (resource.parent) {
        this.openParentsResources(resource.parent);
      }
      this.getSchedulerPro().toggleCollapse(resource, false);
    },
    toggleProcessEventsForResourceInScheduler(
      resource: SchedulerResourceModel,
      processes: SimplifiedPlannerProcess[],
      show?: boolean,
    ) {
      const streamsByCameraId = this.streams.reduce((acc, stream) => {
        acc[stream.camera_id] = stream;
        return acc;
      }, {} as Record<string, Stream>);

      const sectionMaskById = this.sectionMasks?.reduce((acc, sectionMask) => {
        acc[sectionMask._id] = sectionMask;
        return acc;
      }, {} as Record<string, SectionMask>);

      return this.toggleProcessEventsForResource(
        this.getSchedulerPro(),
        resource,
        processes,
        show,
        (id: string, processes: SimplifiedPlannerProcess[]) => ({
          stream: streamsByCameraId[processes[0].camera_id],
          sectionMask:
            sectionMaskById?.[
              (processes[0] as ShortenedProcessWithTags).section_mask_mapping.id || ""
            ],
          trackEvent: this.trackEvent,
        }),
      );
    },
    showProcessesForEachChild(resource: SchedulerResourceModel, show: boolean) {
      if (this.processes) {
        if (
          (resource.children as Model[]).length === 0 ||
          (resource.children as Model[]).every((child) => !child.getData("trackingEnabled"))
        ) {
          this.toggleProcessEventsForResourceInScheduler(resource, this.processes, show);
        }

        for (const child of resource.children as SchedulerResourceModel[]) {
          this.showProcessesForEachChild(child, show);
        }
      }
    },
    handleEventClick(e: SchedulerProBaseEvent) {
      clearTimeout(this.clickTimeoutId);
      this.clickTimeoutId = setTimeout(() => {
        if (e.eventRecord.resource.getData("children").length) {
          this.openResource(
            e.eventRecord.resource,
            e.eventRecord.resource.isExpanded(e.eventRecord.resourceStore),
          );
        }
        if (
          this.currentMode === "visitor" &&
          this.processes &&
          e.eventRecord.getData("type") === "actual" &&
          ((e.eventRecord.resource.children as Model[]).length === 0 ||
            (e.eventRecord.resource.children as Model[]).every(
              (child) => !child.getData("trackingEnabled"),
            )) &&
          this.hasPermission("processes_user")
        ) {
          const result = this.toggleProcessEventsForResourceInScheduler(
            e.eventRecord.resource,
            this.processes,
          );
          if (result === "added") {
            this.trackEvent("planner_processes_show");
          } else if (result === "removed") {
            this.trackEvent("planner_processes_hide");
          }
          this.toggleOutages(this.getSchedulerPro(), e.eventRecord);
        }
        if (e.eventRecord.getData("type")?.startsWith("process_")) {
          this.trackEvent("planner_process_click");
          const processSideBarProcesses = e.eventRecord
            .getData("processes")
            .map((process: SimplifiedPlannerProcess) => {
              const level = this.hierarchyTags.find(
                (tag) =>
                  tag.type === "level" &&
                  process.planner_item_mapping.source_id &&
                  tag.source_ids.includes(process.planner_item_mapping.source_id),
              );
              return { ...process, tags: { level: level?.name } };
            });
          this.processSideBarProcesses = processSideBarProcesses;
          this.processSideBarResourceFullName = this.getResourceFullName(e.eventRecord.resource);
          this.isProcessSideBarOpen = true;
        }
      }, 200);
    },
    handleEventDblClick(e: SchedulerProBaseEvent) {
      if (this.currentMode === "visitor") {
        this.handleActualSidebarOpen(e);
      }
    },
    handleActualSidebarOpen(e: SchedulerProBaseEvent, events?: EventModel[]) {
      clearTimeout(this.clickTimeoutId);
      this.eventDblClickSourceId = e.source.project.resourceStore
        .getById(e.eventRecord.getData("resourceId"))
        .getData("sourceId");
      this.actualSideBarResourceFullName = this.getResourceFullName(e.eventRecord.resource);
      this.eventsOfResource =
        events ||
        (
          this.getSchedulerPro().eventStore.getEventsForResource(
            e.eventRecord.getData("resourceId"),
          ) as EventModel[]
        ).filter((event) => !event.getData("type")?.startsWith("process_"));
      if (this.hasPermission(["planner_user"])) {
        const { customer_name, site_id } = this.$route.params;
        PlannerRepository.loadResourceCameraIds(
          customer_name as string,
          site_id as string,
          this.eventDblClickSourceId as string,
        ).then((response) => {
          this.sourceCamerasList = response;
          this.isActualSideBarOpen = true;
        });
      } else {
        this.sourceCamerasList = [];
        this.isActualSideBarOpen = true;
      }
    },
    showProcessesOnEventClick(e: SchedulerProBaseEvent) {
      this.showProcessesForEachChild(e.eventRecord.resource, true);
      const expanded = e.eventRecord.resource.isExpanded(e.eventRecord.resourceStore);
      if (!expanded) {
        this.openResource(e.eventRecord.resource, expanded);
      }
    },
    hideProcessesOnEventClick(e: SchedulerProBaseEvent) {
      this.showProcessesForEachChild(e.eventRecord.resource, false);
    },
    showProcessesOnMenu(e: SchedulerProResourceItem) {
      this.showProcessesForEachChild(e.record, true);
      const expanded = e.record.isExpanded(this.getSchedulerPro().resourceStore);

      if (!expanded) {
        this.openResource(e.record, expanded);
      }
    },
    hideProcessesOnMenu(e: SchedulerProResourceItem) {
      this.showProcessesForEachChild(e.record, false);
    },
    handleAddActual(e: SchedulerProBaseEvent & { date: Date }) {
      const plannedEvent = e.resourceRecord.events.find(
        (event) => event.getData("type") === "planned",
      ) as EventModel;
      const newActualEvent = new EventModel({
        id: "",
        startDate: e.date,
        endDate: undefined,
        type: "actual",
        active: true,
        source_id: e.resourceRecord.getData("sourceId"),
      });

      const event = {
        ...e,
        eventRecord: e.resourceRecord.events[0],
      } as unknown as SchedulerProBaseEvent;

      this.handleActualSidebarOpen(event, [plannedEvent, newActualEvent]);
      const actualSideBar = this.$refs.actualSideBar as typeof ActualSideBar;
      actualSideBar.setEditMode();
    },
    handleEditActual(e: SchedulerProBaseEvent & { date: Date }) {
      const plannedEvent = e.resourceRecord.events.find(
        (event) => event.getData("type") === "planned",
      );
      const event = { ...e, eventRecord: plannedEvent } as unknown as SchedulerProBaseEvent;
      this.handleActualSidebarOpen(event);
      const actualSideBar = this.$refs.actualSideBar as typeof ActualSideBar;
      actualSideBar.setEditMode();
    },
    openMissingProcessModal({ record, date }: { record: ResourceModel; date: Date }) {
      const componentsEntries = this.hierarchyTags
        .filter((tag) => tag.source_ids.includes(record.getData("sourceId")))
        .map((tag) => [tag.type, tag.name]);
      const component = Object.fromEntries(componentsEntries);

      this.missingProcessReportDate = date;
      this.missingProcessReportTags = component;
      this.isMissingProcessReportModalOpen = true;
    },
    importPlanner(file: File) {
      const { customer_name, site_id } = this.$route.params;
      if (!file) {
        return;
      }

      PlannerRepository.parseXML(customer_name as string, site_id as string, file, "1")
        .then((planConfig) => {
          this.planConfig = planConfig;
          if (this.currentMode !== "revision") {
            this.changeMode("revision");
          }
          setTimeout(() => {
            this.hasChanges = true;
          }, 300);
        })
        .catch((error) => {
          logger.error(error);
          if (error?.response?.status === 400) {
            this.showToast("error", this.$t("analytics.planner.wrong_file_format"));
          }
        })
        .finally(() => {
          if (this.openImportModal) this.openImportModal = false;
        });
    },
    clickPlannerImport() {
      if (this.canImport) {
        this.openImportModal = true;
      } else {
        this.showToast("default", this.$t("analytics.planner.no_planner_import_possible"));
      }
    },
    async handleProcessUpdate(
      process: SimplifiedPlannerProcess & {
        name?: string;
      },
    ) {
      await Promise.all([
        this.refetchPlanConfig().catch(() => undefined),
        this.refetchShortenedProcessesWithTags().catch(() => undefined),
      ]);

      setTimeout(() => {
        const actualEventFromProcess = this.planConfig?.actual_events?.find((event) => {
          return event.source_id === process.planner_item_mapping.source_id;
        });

        if (actualEventFromProcess) {
          const event = this.getSchedulerPro().eventStore.getById(
            actualEventFromProcess?._id,
          ) as EventModel;
          this.processSideBarResourceFullName = this.getResourceFullName(event.resource);
          this.processSideBarProcesses = [process];

          this.openParentsResources(event.resource.parent);

          this.showProcessesForEachChild(event.resource, true);
        } else {
          this.isProcessSideBarOpen = false;
        }

        this.updatePresetIndex();
        this.updateCriticalPathVisibility();
        this.toggleNonTrackedVisibility();
      }, 300);
    },
    handleActualEventUpdate(actualId?: string) {
      setTimeout(() => {
        if (!actualId) {
          const plannedEvent = this.eventsOfResource.find((event) => {
            return event.getData("type") === "planned";
          });

          if (!plannedEvent) {
            return;
          }

          this.openParentsResources(plannedEvent.resource.parent);
          this.eventsOfResource = [plannedEvent];
        } else {
          const actualEvent = this.getSchedulerPro().eventStore.getById(actualId) as EventModel;
          this.openParentsResources(actualEvent.resource.parent);
        }

        this.updatePresetIndex();
        this.updateCriticalPathVisibility();
        this.toggleNonTrackedVisibility();
      }, 300);
    },
    handleEventMouseEnter(event: SchedulerProMouseEvent) {
      const isProcessEvent = event.eventRecord?.getData("type")?.startsWith("process_");
      if (isProcessEvent) {
        this.hoveredProcessEvent = event;
      }
    },
  },
  setup() {
    const { projectDurationSettings, isProjectDurationSettingsLoading } =
      useProjectDurationSettings();
    const { streams } = useStreams();
    const { sectionMasks } = useSectionMasks();
    const isCompleted = useCurrentProject()?.status === "completed";
    const toggleOutages = useOutages(streams, isCompleted);
    const refreshHolidaysInScheduler = useHolidaysInScheduler();
    const refreshNonWorkingDaysInScheduler = useNonWorkingDaysInScheduler();
    const showSaveBeforeLeaveConfirmationModal = useSaveBeforeLeaveConfirmationModal();
    const showConfirmationModal = useConfirmationModal();
    const trackEvent = useTrackEvent();
    const { planConfig, isLoading: isPlanConfigLoading, refetchPlanConfig } = usePlanConfig();
    const { hierarchyTags, isLoading: areHierarchyTagsLoading } = useHierarchyTags();
    const { createNewPlanRevision, isLoading: isCreateNewPlanRevisionLoading } =
      useCreateNewPlanRevision();
    const {
      shortenedProcessesWithTags,
      isLoading: areShortenedProcessesWithTagsLoading,
      refetchShortenedProcessesWithTags,
    } = useShortenedProcessesWithTags();

    return {
      sectionMasks,
      streams,
      projectDurationSettings,
      isProjectDurationSettingsLoading,
      refreshHolidaysInScheduler,
      toggleOutages,
      refreshNonWorkingDaysInScheduler,
      showSaveBeforeLeaveConfirmationModal,
      showConfirmationModal,
      trackEvent,
      vueQueryPlanConfig: planConfig,
      isPlanConfigLoading,
      refetchPlanConfig,
      hierarchyTags,
      areHierarchyTagsLoading,
      createNewPlanRevision,
      isCreateNewPlanRevisionLoading,
      shortenedProcessesWithTags,
      areShortenedProcessesWithTagsLoading,
      refetchShortenedProcessesWithTags,
    };
  },
});
</script>

<style>
.extraMenuItem {
  @apply flex w-full items-center px-4 py-2 text-sm !important;
}

.b-toast,
.b-mask {
  display: none !important;
}

.plannerItemWithComments::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 7px;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="rgb(121, 123, 125)" stroke-width="1.5" class="w-6 h-6" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"/></svg>');
  cursor: pointer;
}

/** **/
input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 16px;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 15px;
  background: #ccc;
  border-radius: 16px;
}

input[type="range"]::-moz-range-track {
  height: 15px;
  background: #ccc;
  border-radius: 16px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #e0a93a;
  box-shadow: -407px 0 0 400px #e0a93a;
}

input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #e0a93a;
}

.extraMenuItem .group::after {
  content: "";
  position: absolute;
  top: 20%;
  right: -8px;
  transform: translateY(-20%);
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #fff;
}
</style>
