import { ref } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import ForgotPassword from "shared/views/auth/ForgotPassword.vue";
import ResetPassword from "shared/views/auth/ResetPassword.vue";
import store from "@/store";
import LogIn from "@/views/auth/LogIn.vue";
import SignUp from "@/views/auth/SignUp.vue";
import Compare from "@/views/camera/Compare.vue";
import CustomizableTimelapse from "@/views/camera/CustomizableTimelapse.vue";
import DailyTimelapse from "@/views/camera/DailyTimelapse.vue";
import Gallery from "@/views/camera/Gallery.vue";
import Live from "@/views/camera/Live.vue";
import Period from "@/views/camera/Period.vue";
import ProjectTimelapse from "@/views/camera/ProjectTimelapse.vue";
import PublicGallery from "@/views/camera/public/PublicGallery.vue";
import PublicLive from "@/views/camera/public/PublicLive.vue";
import PublicLiveImage from "@/views/camera/public/PublicLiveImage.vue";
import PublicProjectTimelapse from "@/views/camera/public/PublicProjectTimelapse.vue";
import DailyReportOverview from "@/views/daily_report/DailyReportOverview.vue";
import SingleDailyReport from "@/views/daily_report/SingleDailyReport.vue";
import Home from "@/views/dashboard/Home.vue";
import ProjectOverviewV2 from "@/views/dashboard/ProjectOverviewV2.vue";
import OrganizationsView from "@/views/organizations/OrganizationsView.vue";
import PlannerV2 from "@/views/planner/Planner.vue";
import ProcessGantt from "@/views/process_gantt/ProcessGantt.vue";
import ProcessTable from "@/views/process_table/ProcessTable.vue";
import Reports from "@/views/reports/Reports.vue";
import ProjectSettings from "@/views/settings_project/ProjectSettings.vue";
import SettingsLanguage from "@/views/settings_user/SettingsLanguage.vue";
import SettingsUser from "@/views/settings_user/SettingsUser.vue";
import SiteActivity from "@/views/site_activity/SiteActivity.vue";
import UnitValuesEditor from "@/views/unit_values/UnitValuesEditor.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requireLogin: true,
    },
    params: true,
  },
  {
    path: "/:catchAll(.*)",
    component: Home,
    name: "NotFound",
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/log-in",
    name: "LogIn",
    component: LogIn,
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/:customer_name/:site_id/:camera_id/live-image/:token",
    name: "PublicLiveImage",
    component: PublicLiveImage,
    meta: {
      requireLogin: false,
    },
  },
  {
    path: "/:customer_name/:site_id/:camera_id/live/:token",
    name: "PublicLive",
    component: PublicLive,
    meta: {
      requireLogin: false,
    },
  },
  {
    path: "/:customer_name/:site_id/:camera_id/live",
    name: "Live",
    component: Live,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:customer_name/:site_id/:camera_id/period",
    name: "Period",
    component: Period,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:customer_name/:site_id/:camera_id/project_timelapse/:token",
    name: "PublicProjectTimelapse",
    component: PublicProjectTimelapse,
    meta: {
      requireLogin: false,
    },
  },
  {
    path: "/:customer_name/:site_id/:camera_id/project_timelapse",
    name: "ProjectTimelapse",
    component: ProjectTimelapse,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:customer_name/:site_id/:camera_id/daily_timelapse",
    name: "DailyTimelapse",
    component: DailyTimelapse,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:customer_name/:site_id/:camera_id/customizable_timelapse",
    name: "CustomizableTimelapse",
    component: CustomizableTimelapse,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:customer_name/:site_id/:camera_id/gallery/:token",
    name: "PublicGallery",
    component: PublicGallery,
    meta: {
      requireLogin: false,
    },
  },
  {
    path: "/:customer_name/:site_id/:camera_id/gallery",
    name: "Gallery",
    component: Gallery,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:customer_name/:site_id/:camera_id/compare",
    name: "Compare",
    component: Compare,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:customer_name/:site_id/processes",
    name: "ProcessGantt",
    component: ProcessGantt,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:customer_name/:site_id/processes/table",
    name: "ProcessesTable",
    component: ProcessTable,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:customer_name/:site_id/reports",
    name: "Reports",
    component: Reports,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:customer_name/:site_id/labour/site_activity",
    name: "Site Activity",
    component: SiteActivity,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/settings/user",
    name: "SettingsUser",
    component: SettingsUser,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/settings/language",
    name: "SettingsLanguage",
    component: SettingsLanguage,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:customer_name/:site_id/daily_report/overview",
    name: "DailyReportOverview",
    component: DailyReportOverview,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:customer_name/:site_id/daily_report/:date",
    name: "SingleDailyReport",
    component: SingleDailyReport,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:customer_name/:site_id/planner",
    name: "PlannerV2",
    component: PlannerV2,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:customer_name/:site_id/dashboard",
    name: "ProjectOverview",
    component: ProjectOverviewV2,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:customer_name/:site_id/settings/:tab?",
    name: "ProjectSettings",
    component: ProjectSettings,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/:customer_name/:site_id/unit_values_editor",
    name: "UnitValuesEditor",
    component: UnitValuesEditor,
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/organizations/:tab?",
    name: "OrganizationsView",
    component: OrganizationsView,
    meta: {
      requireLogin: true,
    },
  },
];

export const isInitialRoute = ref<boolean | null>(null);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  isInitialRoute.value = isInitialRoute.value === null;
  if (store.state.userLoaded && !store.state.user && to.meta.requireLogin) {
    next("/log-in");
  } else {
    next();
  }
});

export default router;
