<template>
  <Sidebar>
    <CameraItemLayout :noData="noData">
      <template #contentLeft>
        <DailyTimelapseContent
          :date="date"
          :timelapse="timelapse"
          :loading="loading"
          class="col-span-6"
          contentClass="lg:max-w-screen-md 2xl:max-w-screen-2xl lg:mr-6"
          @dateChange="handleDateChange"
          @downloadClick="trackEvent('camera_daily-timelapse_download_click')"
        />
      </template>
      <template #contentRight>
        <div class="col-span-4 lg:col-span-2" v-if="!loading">
          <div class="space-y-4 divide-y divide-gray-200">
            <h3 class="text-md 2xl:text-lg leading-6 font-medium text-gray-900">
              {{ $t("camera.daily_timelapse.pick_a_date") }}
            </h3>
            <Form
              @submit="onSubmit"
              :validationSchema="schema"
              class="space-y-3 sm:space-y-5 pt-3 sm:pt-0"
            >
              <div class="mt-1 pt-4">
                <DateField
                  name="dailyTimelapseDate"
                  :label="$t('camera.period.date')"
                  :maxDate="maxDate"
                  :inlineField="true"
                  :date="date"
                />
              </div>
              <div class="flex sm:pt-5">
                <MainButton
                  :label="$t('camera.daily_timelapse.search')"
                  type="submit"
                  color="yellow"
                  class="mx-0 sm:mr-0 mt-0"
                  @click="buttonLoad = true"
                >
                  <LoadingSpinner v-if="buttonLoad" size="w-5 h-5" color="white" class="mr-2" />
                </MainButton>
              </div>
            </Form>
            <WeatherContainer
              ref="weatherContainerComponent"
              :date="dateToString"
              :loadMain="loading"
              v-if="timelapse"
            />
          </div>
        </div>
      </template>
    </CameraItemLayout>
  </Sidebar>
</template>

<script lang="ts" setup>
import axios from "axios";
import { format, getDay, parseISO, subDays } from "date-fns";
import { Form } from "vee-validate";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import * as yup from "yup";
import DailyTimelapseContent from "shared/components/camera/DailyTimelapseContent.vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { useCustomToast } from "shared/composables/toast";
import { useTrackEvent } from "shared/composables/tracking";
import CameraRepository from "shared/repositories/CameraRepository";
import logger from "shared/services/logger";
import { DailyTimelapseResponse } from "shared/types/Camera";
import DateField from "@/components/forms/DateField.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import MainButton from "@/components/other/MainButton.vue";
import CameraItemLayout from "@/views/camera/components/CameraItemLayout.vue";
import WeatherContainer from "@/views/camera/components/WeatherContainer.vue";

const timelapse = ref(null) as Ref<DailyTimelapseResponse | null>;
const date = ref(null) as Ref<null | Date>;
const loading = ref(false) as Ref<boolean>;
const buttonLoad = ref(false) as Ref<boolean>;
const route = useRoute();
const toast = useCustomToast();
const { t } = useI18n();
const noData = ref(false) as Ref<boolean>;

const trackEvent = useTrackEvent();

onMounted(() => {
  trackEvent("camera_daily-timelapse_view");
  loadDailyTimelapse(dateToString.value);
});

watch(loading, (newLoadingValue) => {
  if (!newLoadingValue) {
    buttonLoad.value = newLoadingValue;
  }
});

const dateToString = computed(() => {
  return date.value ? format(date.value, "yyyy-MM-dd") : "";
});

const schema = computed(() =>
  yup.object({
    dailyTimelapseDate: yup
      .date()
      .nullable()
      .required(t("err.required"))
      .max(
        maxDate.value,
        t("err.err_future_date", { maxDate: format(maxDate.value, "yyyy-MM-dd") }),
      ),
  }),
);

const maxDate = computed(() => {
  const today = new Date();
  if (getDay(today) === 1) {
    return subDays(today, 2);
  } else {
    return subDays(today, 1);
  }
});

const loadDailyTimelapse = async (value: string | null | undefined) => {
  loading.value = true;
  const { customer_name, site_id, camera_id } = route.params;

  try {
    date.value = value ? parseISO(value) : null;
    loading.value = true;
    timelapse.value = await CameraRepository.loadDailyTimelapse(
      customer_name as string,
      site_id as string,
      camera_id as string,
      value || (route.query.date as string) || null,
    );

    // Check if timelapse is not null before accessing its properties
    if (timelapse.value) {
      date.value = new Date(timelapse.value.date);
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error?.response?.status !== 404) {
        logger.error(error);
      }
      date.value = null;
      timelapse.value = null;
      if (error?.response?.data?.code === "NO_DATA_YET") {
        noData.value = true;
      } else {
        toast.warning(t("err.no_data_found"));
      }
    }
  } finally {
    loading.value = false;
    if (buttonLoad.value) buttonLoad.value = false;
  }
};

const onSubmit = async (values: Record<string, number | Date>) => {
  const formattedValue = format(values.dailyTimelapseDate, "yyyy-MM-dd");
  await loadDailyTimelapse(formattedValue);
};

const handleDateChange = (date: string | null) => {
  loadDailyTimelapse(date);
};
</script>
