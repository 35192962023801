<template>
  <SignUpComponent :socialLogins="[microsoftLogin]" />
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import UserRepository from "shared/repositories/UserRepository";
import logger from "shared/services/logger";
import SignUpComponent from "shared/views/auth/SignUp.vue";
import { useLoadUserData } from "@/composables/auth";
import { useMicrosoftSignIn } from "@/composables/auth";

const loadUserData = useLoadUserData();
const microsoftSignIn = useMicrosoftSignIn();

const { t } = useI18n();

const handleMicrosoftLogin = async (email: string) => {
  try {
    const name = await microsoftSignIn(email);
    await UserRepository.completeSignUp(email, name, null, null, "sso");
    await loadUserData();
  } catch (e) {
    const error = e as Error;
    if (
      error?.message !== "Different email in logged in account" &&
      error?.message !== "No current user" &&
      error?.message !== "user_cancelled: User cancelled the flow." &&
      !error?.message.includes("interaction_in_progress")
    ) {
      logger.error(error);
    }
    throw e;
  }
};

const microsoftLogin = {
  label: t("authentication.sign_up.sign_up_with_microsoft"),
  logoData:
    "data:image/svg+xml,%3csvg%20width='21'%20height='21'%20viewBox='0%200%2021%2021'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3crect%20x='11.375'%20y='11.375'%20width='8.75'%20height='8.75'%20fill='%23FEBA08'/%3e%3crect%20x='0.875'%20y='11.375'%20width='8.75'%20height='8.75'%20fill='%2305A6F0'/%3e%3crect%20x='11.375'%20y='0.875'%20width='8.75'%20height='8.75'%20fill='%2380BC06'/%3e%3crect%20x='0.875'%20y='0.875'%20width='8.75'%20height='8.75'%20fill='%23F25325'/%3e%3c/svg%3e",
  handleLogin: handleMicrosoftLogin,
};
</script>
