<template>
  <div
    @click="editMode ? $emit('addHighlight') : ''"
    :class="[
      { 'shadow-lg mix-blend-normal bg-blend-color-dodge': editMode },
      'my-4 bg-white shadow sm:rounded-lg md:px-5 px-1 py-6 border',
      { 'border-yellow': highlightSection === 'gallery' },
    ]"
  >
    <div class="flex justify-between px-3">
      <h3 class="md:text-lg leading-4 font-semibold text-gray-900">
        {{ $t(`report.section_title.gallery`) }}
      </h3>
      <div v-if="!approved">
        <PencilIcon
          v-if="!editMode"
          class="w-4 h-4 text-oaiGray-300 cursor-pointer"
          @click="activeEditMode()"
        />
        <PlusIcon v-else class="w-5 h-5 text-orange cursor-pointer" @click="openModal" />
      </div>
    </div>
    <div class="mt-4 divide-y">
      <masonry-wall
        :items="images"
        :gap="16"
        :ssr-columns="1"
        :column-width="300"
        class="gallery-images"
      >
        <template #default="{ item }">
          <div class="relative">
            <div class="absolute right-0 top-0 pr-2 pt-2 z-10">
              <button
                type="button"
                class="rounded-md bg-gray-50 p-1 text-gray-500 hover:text-red-700"
                @click="$emit('removeGalleryImage', item.id)"
                v-if="editMode"
              >
                <TrashIcon class="lg:h-5 h-4" aria-hidden="true" />
              </button>
            </div>

            <a
              :href="item.url"
              target="_blank"
              rel="noreferrer"
              :data-pswp-width="item.width"
              :data-pswp-height="item.height"
              :style="{ aspectRatio: item.width / item.height }"
            >
              <img
                :src="item.url"
                alt="img"
                :width="item.width"
                :height="item.height"
                class="rounded-t-md w-full object-cover"
                :style="{ aspectRatio: item.width / item.height }"
              />
            </a>
          </div>
          <div
            class="flex justify-between border border-gray-200 py-1 px-2 sm:text-sm text-xs rounded-b-md"
            v-if="item?.name && !(editMode && item.source === 'custom')"
          >
            <span>
              {{ item?.name }}
            </span>
            <span v-if="item.timestamp">
              {{ formatDate(item.timestamp) }}
            </span>
          </div>
          <div v-if="item.source == 'custom' && editMode">
            <input
              v-model="item.name"
              type="text"
              class="w-full border-2 border-t-0 border-yellow-300 focus:border-yellow-500 focus:ring-0 focus:border-t-0 sm:text-sm text-xs"
              :placeholder="$t('report.sections.gallery.add_note')"
            />
          </div>
        </template>
      </masonry-wall>
    </div>
    <div class="mt-4 flex justify-center">
      <button
        type="button"
        @click="openModal"
        v-if="!approved && (editMode || images.length === 0)"
        class="relative inline-flex items-center p-2 shadow-sm border-2 border-yellow rounded-full text-yellow-700 hover:bg-yellow-700 hover:text-white focus:outline-none"
      >
        <PlusIcon class="w-6" />
      </button>
      <p
        v-if="approved && images.length === 0"
        class="md:text-center pt-2 pl-3 md:p-0 md:text-base text-sm"
      >
        {{ $t("report.general.section_empty") }}
      </p>
    </div>
  </div>

  <BasicModal :open="open" @close="closeModal()" customClass="md:w-9/12 h-5/6 overflow-visible">
    <div class="py-6 grid lg:grid-cols-2 divide-x">
      <div class="grid-cols-1 p-4">
        <h3 class="text-lg leading-4 font-semibold text-gray-900">
          {{ $t("report.sections.gallery.oai_images_title") }}
        </h3>
        <div v-if="loadingImages" class="flex items-center justify-center h-full">
          <LoadingSpinner />
        </div>
        <div
          v-else-if="streams.length === 0 && !loadingImages"
          class="flex items-center justify-center h-full"
        >
          {{ $t("err.error_streams_not_found") }}
        </div>
        <div
          v-else
          v-for="(stream, streamIndex) in sortedStreams"
          :key="streamIndex"
          class="grid xl:grid-cols-3 sm:grid-cols-2 gap-4 mx-auto mt-4 mb-6"
        >
          <template v-if="galleryMap[stream.camera_id]">
            <div
              v-for="(image, index) in galleryMap[stream.camera_id]"
              :key="index"
              class="border rounded-md"
              @click="toggleImageSelection(image)"
            >
              <div
                :class="[
                  'relative rounded-md  aspect-w-4 aspect-h-3 bg-gray-200',
                  { 'border-2 border-yellow-500': image?.selected },
                ]"
              >
                <img :src="image?.url" alt="Image" />
                <CheckCircleIcon
                  :class="[
                    !image?.selected ? 'invisible' : '',
                    'h-5 w-5 top-2 left-2 text-yellow-500 absolute',
                  ]"
                  aria-hidden="true"
                />
                <span
                  :class="[
                    image?.selected ? 'border-yellow-500' : 'border-transparent',
                    'pointer-events-none absolute rounded-lg border',
                  ]"
                  aria-hidden="true"
                />
              </div>
              <div class="flex justify-between py-1 lg:px-2 px-1 lg:text-sm text-xs flex-wrap">
                <span>
                  {{ image?.name }}
                </span>
                <span>
                  {{ formatDate(image.timestamp) }}
                </span>
              </div>
            </div>
          </template>
          <template v-else>
            <div
              class="col-span-3 p-2 text-center rounded border-2 border-gray-200 my-2 text-xs sm:text-base flex items-center justify-center h-32"
            >
              {{ $t("report.sections.gallery.no_images") }} {{ stream.name }}
            </div>
          </template>
        </div>
      </div>

      <div class="grid-cols-1 p-4">
        <GalleryImageUpload @addCustom="addCustom($event)" :customImages="customImages" />
      </div>
    </div>
    <div class="mt-6 sm:mt-4 sm:flex sm:flex-row-reverse w-full mx-auto">
      <button
        type="button"
        class="inline-flex w-full justify-center rounded-md bg-yellow-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-500 sm:ml-3 sm:w-auto"
        @click="updateGalleryImages()"
      >
        {{ $t("buttons.add_images") }}
      </button>
      <button
        type="button"
        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        @click="open = false"
        ref="cancelButtonRef"
      >
        {{ $t("buttons.cancel") }}
      </button>
    </div>
  </BasicModal>
</template>

<script lang="ts">
import { CheckCircleIcon, PencilIcon, PlusIcon, TrashIcon } from "@heroicons/vue/24/solid";
import MasonryWall from "@yeger/vue-masonry-wall";
import { parseISO, format } from "date-fns";
import { toZonedTime, fromZonedTime } from "date-fns-tz";
import { Padding } from "photoswipe";
import { PhotoSwipe } from "photoswipe/dist/types/ui/ui-element";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import { defineComponent, PropType } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import BasicModal from "shared/components/modals/BasicModal.vue";
import { useTrackEvent } from "shared/composables/tracking";
import CameraRepository from "shared/repositories/CameraRepository";
import logger from "shared/services/logger";
import { CameraImageItem } from "shared/types/Camera";
import { Stream } from "shared/types/Stream";
import { useStreams } from "@/composables/stream";
import { ReportImageEntry, ReportImageEntrySelected } from "@/types/DailyReport";
import GalleryImageUpload from "@/views/daily_report/components/GalleryImageUpload.vue";

export default defineComponent({
  name: "ReportGallery",
  props: {
    images: {
      type: Object as PropType<ReportImageEntry[]>,
      required: true,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
    highlightSection: {
      type: String,
      required: true,
    },
    approved: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  components: {
    PencilIcon,
    PlusIcon,
    CheckCircleIcon,
    BasicModal,
    MasonryWall,
    TrashIcon,
    GalleryImageUpload,
    LoadingSpinner,
  },
  emits: ["activeEditMode", "addHighlight", "updateGalleryImages", "removeGalleryImage"],

  data() {
    return {
      open: false as boolean,
      galleryMap: {} as Record<string, ReportImageEntrySelected[]>,
      date: this.$route.params.date,
      clickedImg: "",
      customImages: [] as ReportImageEntry[],
      lightbox: null as PhotoSwipeLightbox | null,
      error: false as boolean,
      noStreamData: false as boolean,
      loadingImages: true as boolean,
    };
  },
  mounted() {
    if (!this.lightbox) {
      const lightbox = new PhotoSwipeLightbox({
        gallery: ".gallery-images",
        children: "a",
        pswpModule: () => import("photoswipe"),
        padding: { top: 10, bottom: 10 } as Padding,
      });
      lightbox.on("uiRegister", function () {
        lightbox.pswp?.ui?.registerElement({
          name: "download-button",
          order: 8,
          isButton: true,
          tagName: "a",

          // SVG with outline
          html: {
            isCustomSVG: true,
            inner:
              '<path d="M20.5 14.3 17.1 18V10h-2.2v7.9l-3.4-3.6L10 16l6 6.1 6-6.1-1.5-1.6ZM23 23H9v2h14" id="pswp__icn-download"/>',
            outlineID: "pswp__icn-download",
          },

          onInit: (el: HTMLElement, pswp: PhotoSwipe) => {
            el.setAttribute("download", "");
            el.setAttribute("target", "_blank");
            el.setAttribute("rel", "noopener");

            pswp.on("change", () => {
              el.setAttribute("href", pswp.currSlide?.data.src as string);
            });
          },
        });
      });
      lightbox.init();
      this.lightbox = lightbox;
      lightbox.init();
      this.lightbox = lightbox;
    }
    this.resizeImagesLightbox();
  },
  unmounted() {
    if (this.lightbox) {
      this.lightbox?.destroy();
      this.lightbox = null;
    }
  },
  setup() {
    const { streams, isLoading } = useStreams();
    const trackEvent = useTrackEvent();
    return { streams, isLoading, trackEvent };
  },
  watch: {
    images() {
      this.resizeImagesLightbox();
    },
  },
  computed: {
    sortedStreams() {
      return this.streams.slice().sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    resizeImagesLightbox() {
      this.images.forEach((img: ReportImageEntry) => {
        const adjustedImage = img as ReportImageEntry;
        const imgElement = new Image();
        imgElement.src = String(img.url);
        imgElement.addEventListener("load", () => {
          adjustedImage.width = imgElement.naturalWidth;
          adjustedImage.height = imgElement.naturalHeight;
        });
      });
    },
    closeModal() {
      this.open = false;
      this.customImages = [];
    },
    addCustom(value: ReportImageEntry[]) {
      this.trackEvent("dcr_image_upload_apply");
      this.customImages = value;
    },
    activeEditMode() {
      this.$emit("activeEditMode");
      this.$emit("addHighlight");
    },
    getImages(stream: Stream) {
      return CameraRepository.getPaginatedGalleryImages(
        stream.customer_name,
        stream.site_id,
        stream.camera_id,
        this.date as string,
      )
        .then((response) => {
          if (response.items.length > 0) {
            const middleIndex = Math.floor(response.items.length / 2);
            const cameraImg: CameraImageItem[] =
              response.items.length < 4
                ? response.items
                : [
                    response.items[0],
                    response.items[middleIndex],
                    response.items[response.items.length - 1],
                  ];
            const imgs: ReportImageEntrySelected[] = cameraImg.map((img) => {
              return {
                url: img.url,
                timestamp: this.convertToISO(img.timestamp),
                camera_id: stream.camera_id,
                name: stream.name,
                selected: false,
              };
            });
            this.galleryMap[stream.camera_id] = imgs;
          }
        })
        .catch((e) => {
          if (e?.response?.status !== 404) {
            logger.error(e);
          }
        });
    },

    formatDate(value: string) {
      const parseUtcDate = toZonedTime(parseISO(value), "UTC");
      return format(parseUtcDate, "HH:mm");
    },

    convertToISO(timestamp: string) {
      const dateItem = timestamp !== "" ? new Date(timestamp) : "";
      return dateItem !== ""
        ? fromZonedTime(dateItem, "UTC").toISOString().replace("Z", "+00:00")
        : "";
    },
    async openModal() {
      this.trackEvent("dcr_image_view");
      this.activeEditMode();
      this.loadingImages = true;
      this.open = true;
      this.galleryMap = {};
      await Promise.all(this.streams.map((stream) => this.getImages(stream)));
      for (const key in this.galleryMap) {
        this.galleryMap[key].forEach(
          (img) =>
            (img.selected = this.images.some((item: ReportImageEntry) => {
              return img.timestamp === item.timestamp && img.camera_id === item.camera_id;
            })),
        );
      }
      this.loadingImages = false;
    },

    updateGalleryImages() {
      this.$emit("updateGalleryImages", {
        galleryMap: this.galleryMap,
        customImages: this.customImages,
      });
      this.customImages = [];
      this.resizeImagesLightbox();
      this.$emit("activeEditMode");
      this.open = false;
    },
    toggleImageSelection(image: ReportImageEntrySelected) {
      image.selected = !image.selected;
    },
  },
});
</script>
