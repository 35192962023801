import { eachDayOfInterval, format, startOfDay } from "date-fns";
import durationService from "shared/services/durationService";
import { HierarchyTagStore } from "shared/types/HierarchyTag";
import { PlanConfig, PlannerItem } from "shared/types/Plan";
import { ShortenedProcessWithTags } from "shared/types/Process";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import { createPlanContext, filterPlanConfigByLocation } from "shared/views/planner/planner";
import { PlannerItemProgress, PlanProgressContext } from "@/types/Plan";

const sum = (numbers: number[]): number => numbers.reduce((acc, number) => acc + number, 0);

const calculatePercentage = (finished: number, total: number) =>
  total !== 0 ? finished / total : null;

export const createPlanProgressContext = (
  planConfig: PlanConfig,
  processes: ShortenedProcessWithTags[] | undefined,
  projectDurationSettings: ProjectDurationSettings,
  hierarchyTags: HierarchyTagStore[],
): PlanProgressContext => {
  const planContext = createPlanContext(planConfig, hierarchyTags);

  const processDaysBySourceId = processes
    ? processes.reduce((acc, process) => {
        const key = process.planner_item_mapping?.source_id;
        if (key) {
          if (!acc[key]) {
            acc[key] = new Set<string>();
          }
          acc[key].add(process.date);
        }
        return acc;
      }, {} as Record<string, Set<string>>)
    : undefined;

  const totalWorkingDaysByPlannedEvent = planConfig.planned_events.reduce((acc, plannedEvent) => {
    const { workingDays: totalWorkingDays } = durationService.calculateDuration(
      projectDurationSettings.settings,
      plannedEvent.start,
      plannedEvent.end,
    );
    acc[plannedEvent._id] = totalWorkingDays;
    return acc;
  }, {} as Record<string, number | undefined>);

  return { ...planContext, processDaysBySourceId, totalWorkingDaysByPlannedEvent };
};

const calculateProjectProgressForPlannerItem = (
  plannerItem: PlannerItem,
  planProgressContext: PlanProgressContext,
  projectDurationSettings: ProjectDurationSettings,
  now: Date,
): Pick<
  PlannerItemProgress,
  | "total_working_days"
  | "finished_working_days"
  | "actual_finished_working_days"
  | "ignore_for_completion_check"
> => {
  const {
    processDaysBySourceId,
    plannedEventsByPlannerItemId,
    totalWorkingDaysByPlannedEvent,
    actualEventsBySourceId,
  } = planProgressContext;

  const plannedEvent = plannedEventsByPlannerItemId[plannerItem._id];

  if (!plannedEvent) {
    return {
      total_working_days: 0,
      finished_working_days: 0,
      actual_finished_working_days: 0,
      ignore_for_completion_check: false,
    };
  }

  const totalWorkingDays = totalWorkingDaysByPlannedEvent[plannedEvent._id];

  if (totalWorkingDays === undefined) {
    return {
      total_working_days: 0,
      finished_working_days: 0,
      actual_finished_working_days: 0,
      ignore_for_completion_check: false,
    };
  }

  const actualEvent = actualEventsBySourceId[plannerItem.source_id];

  if (!actualEvent) {
    return {
      total_working_days: totalWorkingDays,
      finished_working_days: 0,
      actual_finished_working_days: 0,
      ignore_for_completion_check: false,
    };
  }

  if (now < actualEvent.start) {
    return {
      total_working_days: totalWorkingDays,
      finished_working_days: 0,
      actual_finished_working_days: 0,
      ignore_for_completion_check: false,
    };
  }

  if (actualEvent.end && now >= startOfDay(actualEvent.end)) {
    return {
      total_working_days: totalWorkingDays,
      finished_working_days: totalWorkingDays,
      actual_finished_working_days: totalWorkingDays,
      // Handle case when calculated planned working days are 0 because of non working days settings
      ignore_for_completion_check: totalWorkingDays === 0,
    };
  }

  const processDays =
    (processDaysBySourceId && processDaysBySourceId[plannerItem.source_id]) || new Set<string>();

  const finishedWorkingDays = processDaysBySourceId
    ? eachDayOfInterval({ start: actualEvent.start, end: now }).reduce((acc, day) => {
        const formattedDate = format(day, "yyyy-MM-dd");
        return processDays.has(formattedDate) ? acc + 1 : acc;
      }, 0)
    : durationService.calculateDuration(projectDurationSettings.settings, actualEvent.start, now)
        .workingDays;

  return {
    total_working_days: totalWorkingDays,
    finished_working_days: Math.min(finishedWorkingDays, totalWorkingDays * 0.95),
    actual_finished_working_days: finishedWorkingDays,
    ignore_for_completion_check: false,
  };
};

const calculateProjectProgressItems = (
  planConfig: PlanConfig,
  processes: ShortenedProcessWithTags[] | undefined,
  projectDurationSettings: ProjectDurationSettings,
  hierarchyTags: HierarchyTagStore[],
  date?: Date,
  location?: string[],
): PlannerItemProgress[] => {
  const now = startOfDay(new Date());
  const filteredPlan = filterPlanConfigByLocation(planConfig, hierarchyTags, location || []);
  const planProgressContext = createPlanProgressContext(
    filteredPlan,
    processes,
    projectDurationSettings,
    hierarchyTags,
  );

  const result: Omit<PlannerItemProgress, "weight">[] = [];

  const traverse = (plannerItems: PlannerItem[]): Omit<PlannerItemProgress, "weight">[] => {
    const traverseResult: Omit<PlannerItemProgress, "weight">[] = [];
    for (const plannerItem of plannerItems) {
      const childPlannerItems = planProgressContext.plannerItemsByParentId[plannerItem._id] || [];

      if (childPlannerItems.length === 0) {
        traverseResult.push({
          _id: plannerItem._id,
          ...calculateProjectProgressForPlannerItem(
            plannerItem,
            planProgressContext,
            projectDurationSettings,
            date || now,
          ),
          root: !plannerItem.parent_id,
        });
      } else {
        const childPlannerItemProgresses = traverse(childPlannerItems);
        traverseResult.push({
          _id: plannerItem._id,
          total_working_days: sum(
            childPlannerItemProgresses.map((item) => item.total_working_days),
          ),
          finished_working_days: sum(
            childPlannerItemProgresses.map((item) => item.finished_working_days),
          ),
          actual_finished_working_days: sum(
            childPlannerItemProgresses.map((item) => item.actual_finished_working_days),
          ),
          ignore_for_completion_check: false,
          root: !plannerItem.parent_id,
        });
      }
    }
    traverseResult.forEach((item) => result.push(item));
    return traverseResult;
  };

  traverse(filteredPlan.planner_items.filter((plannerItem) => !plannerItem.parent_id));

  const totalWorkingDays = sum(
    result.filter(({ root }) => root).map(({ total_working_days }) => total_working_days),
  );

  return result.map((plannerItemProgress) => ({
    ...plannerItemProgress,
    weight: calculatePercentage(plannerItemProgress.total_working_days, totalWorkingDays),
  }));
};

const calculateProjectProgress = (plannerItemProgresses: PlannerItemProgress[]) => {
  const rootPlannerItemProgresses = plannerItemProgresses.filter(
    (progressItem) => progressItem.root,
  );
  const totalWorkingDays = sum(
    rootPlannerItemProgresses.map((progressItem) => progressItem.total_working_days),
  );
  const finishedWorkingDays = sum(
    rootPlannerItemProgresses.map((progressItem) => progressItem.finished_working_days),
  );

  return {
    totalWorkingDays,
    finishedWorkingDays,
  };
};

const calculateProjectProgressPercentage = (
  planConfig: PlanConfig,
  processes: ShortenedProcessWithTags[] | undefined,
  projectDurationSettings: ProjectDurationSettings,
  hierarchyTags: HierarchyTagStore[],
  date?: Date,
  location?: string[],
): number | null => {
  const plannerItemProgresses = calculateProjectProgressItems(
    planConfig,
    processes,
    projectDurationSettings,
    hierarchyTags,
    date,
    location,
  );
  const { totalWorkingDays, finishedWorkingDays } = calculateProjectProgress(plannerItemProgresses);

  return calculatePercentage(finishedWorkingDays, totalWorkingDays);
};

const calculatePlanForPlannedProgress = (planConfig: PlanConfig) => {
  const plannerItemsById = planConfig.planner_items.reduce((acc, plannerItem) => {
    acc[plannerItem._id] = plannerItem;
    return acc;
  }, {} as Record<string, PlannerItem>);
  const planWithoutActuals: PlanConfig = {
    ...planConfig,
    actual_events: planConfig.planned_events
      .filter((plannedEvent) => plannerItemsById[plannedEvent.planner_item_id])
      .map((plannedEvent) => ({
        _id: `actual_${plannedEvent._id}`,
        source_id: plannerItemsById[plannedEvent.planner_item_id].source_id,
        start: plannedEvent.start,
        end: plannedEvent.end,
        customer_name: plannedEvent.customer_name,
        site_id: plannedEvent.site_id,
        created: plannedEvent.created || "",
        created_by: plannedEvent.created_by || "",
        last_updated: plannedEvent.created || "",
        last_updated_by: plannedEvent.created_by || "",
      })),
  };
  return planWithoutActuals;
};

export default {
  createPlanProgressContext,
  calculatePercentage,
  calculateProjectProgressItems,
  calculateProjectProgress,
  calculateProjectProgressPercentage,
  calculatePlanForPlannedProgress,
  calculateProjectProgressForPlannerItem,
};
