import { AxiosResponse, isAxiosError } from "axios";
import { parseISO } from "date-fns";
import { apiClient } from "shared/repositories/clients";
import { PublicToken, PublicTokenToCreate, PublicTokenToUpdate } from "shared/types/PublicToken";

const PublicTokenBearerAuthPrefix = "oai_public_token:";

const mapPublicToken = (publicToken: PublicToken<string>): PublicToken => ({
  ...publicToken,
  expires: publicToken.expires ? parseISO(publicToken.expires) : null,
  created: parseISO(publicToken.created),
});

const formatPublicTokenToCreate = (
  publicTokenToCreate: PublicTokenToCreate,
): PublicTokenToCreate<string> => ({
  ...publicTokenToCreate,
  expires: publicTokenToCreate.expires ? publicTokenToCreate.expires.toISOString() : null,
});

const loadPublicTokens = (customerName: string, siteId: string): Promise<PublicToken[]> =>
  apiClient
    .get<void, AxiosResponse<PublicToken<string>[]>>(`/public-tokens/${customerName}/${siteId}`)
    .then((response) => response.data.map((publicToken) => mapPublicToken(publicToken)));

const loadPublicTokenByToken = (customerName: string, siteId: string): Promise<PublicToken> =>
  apiClient
    .get<void, AxiosResponse<PublicToken<string>>>(`/public-tokens/${customerName}/${siteId}/token`)
    .then((response) => mapPublicToken(response.data));

const deletePublicToken = (
  customerName: string,
  siteId: string,
  publicTokenId: string,
): Promise<void> =>
  apiClient
    .delete(`/public-tokens/${customerName}/${siteId}/${publicTokenId}`)
    .then((response) => response.data);

const createPublicToken = (
  customerName: string,
  siteId: string,
  publicTokenToCreate: PublicTokenToCreate,
): Promise<PublicToken> =>
  apiClient
    .post<void, AxiosResponse<PublicToken<string>>, PublicTokenToCreate<string>>(
      `/public-tokens/${customerName}/${siteId}`,
      formatPublicTokenToCreate(publicTokenToCreate),
    )
    .then((response) => mapPublicToken(response.data));

const updatePublicToken = (
  customerName: string,
  siteId: string,
  publicTokenToUpdate: PublicTokenToUpdate,
): Promise<PublicToken> => {
  const { _id, ...rest } = publicTokenToUpdate;
  return apiClient
    .patch<void, AxiosResponse<PublicToken<string>>, PublicTokenToCreate<string>>(
      `/public-tokens/${customerName}/${siteId}/${_id}`,
      formatPublicTokenToCreate(rest),
    )
    .then((response) => mapPublicToken(response.data));
};

const isPublicTokenAuthError = (error: unknown) =>
  isAxiosError(error) &&
  error.response?.status === 401 &&
  `${error.config?.headers.Authorization}`.startsWith(`Bearer ${PublicTokenBearerAuthPrefix}`);

export default {
  loadPublicTokens,
  loadPublicTokenByToken,
  deletePublicToken,
  createPublicToken,
  updatePublicToken,
  isPublicTokenAuthError,
  PublicTokenBearerAuthPrefix,
};
