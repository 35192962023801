<template>
  <div class="px-2 py-0.5 rounded text-xs w-min whitespace-nowrap" :class="badgeClass">
    {{ formattedExpiration }}
  </div>
</template>

<script lang="ts" setup>
import { format } from "date-fns";
import { computed, defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { PublicToken } from "shared/types/PublicToken";

const props = defineProps<{ publicToken: PublicToken; disabled?: boolean }>();

const { t } = useI18n();

const isExpired = computed(() => {
  if (!props.publicToken.expires) {
    return false;
  }
  return props.publicToken.expires < new Date();
});

const formattedExpiration = computed(() => {
  if (!props.publicToken.expires) {
    return t("public_tokens.never");
  }
  if (isExpired.value) {
    return t("public_tokens.expired");
  }
  return format(props.publicToken.expires, "dd.MM.yyyy HH:mm");
});

const badgeClass = computed(() => {
  if (props.disabled) {
    return "bg-gray-50 text-gray-300";
  }
  return isExpired.value ? "bg-red-100 text-red-800" : "bg-gray-100 text-gray-800";
});
</script>
