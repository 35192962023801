<template>
  <PublicTokenExpirationOptions
    :hideLabel="true"
    :dontHighlightSelected="true"
    :expirationDate="publicToken.expires"
    popoverClass="top-[25px] right-0 w-[150px]"
    @change="handleExpirationChange"
  >
    <PopoverButton class="flex gap-1 items-center" :disabled="isLoading">
      <PublicTokenExpirationBadge :publicToken="publicToken" :disabled="isLoading" />
      <ChevronDownIcon class="h-4 w-4 shrink-0" :class="isLoading ? 'text-gray-300' : ''" />
    </PopoverButton>
  </PublicTokenExpirationOptions>
</template>

<script setup lang="ts">
import { PopoverButton } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/24/outline";
import { PublicToken, PublicTokenToUpdate } from "shared/types/PublicToken";
import PublicTokenExpirationBadge from "@/components/public_token/PublicTokenExpirationBadge.vue";
import PublicTokenExpirationOptions from "@/components/public_token/PublicTokenExpirationOptions.vue";
import { useUpdatePublicToken } from "@/composables/publicToken";

const props = defineProps<{ publicToken: PublicToken }>();

const { updatePublicToken, isLoading } = useUpdatePublicToken();

const handleExpirationChange = (newExpirationDate: Date | null) => {
  const updateTokenToUpdate: PublicTokenToUpdate = {
    _id: props.publicToken._id,
    camera_ids: props.publicToken.camera_ids,
    expires: newExpirationDate,
    claims: props.publicToken.claims,
  };
  if (
    isLoading.value ||
    props.publicToken.expires?.getTime() === updateTokenToUpdate.expires?.getTime()
  ) {
    return;
  }
  updatePublicToken(updateTokenToUpdate).catch(() => undefined);
};
</script>
