<template>
  <div>
    <div class="loadingCard is-loading h-full" v-if="loading">
      <div
        class="image group block h-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-50 overflow-hidden"
      />
    </div>
    <div :class="contentClass" v-if="!loading">
      <OaiVideoPlayer
        :loading="loading"
        :src="timelapse?.url"
        :noSrcMessage="noSrcMessage || t('err.no_timelapse_found')"
      />
      <div class="grid grid-cols-4 items-center mn-h-6 mt-2">
        <div class="flex justify-center col-span-2 col-start-2 items-center">
          <button
            @click="emit('dateChange', timelapse?.previous ?? null)"
            type="button"
            :disabled="!(timelapse && timelapse.previous)"
            class="border-transparent inline-flex items-center px-4 text-sm font-medium"
          >
            <ChevronLeftIcon
              class="h-6 w-6 text-oaiGray-300"
              :class="{ 'text-transparent': !(timelapse && timelapse.previous) }"
              aria-hidden="true"
            />
          </button>
          <h2
            class="pl-8 pr-8 2xl:pl-12 2xl:pr-12 text-sm 2xl:text-lg font-extrabold text-oaiGray-300"
            v-if="date && !enableHeaderDateField"
          >
            {{ formatDate(date) }}
          </h2>
          <input
            type="date"
            :value="date ? format(date, 'yyyy-MM-dd') : ''"
            v-if="enableHeaderDateField"
            @change="handleHeaderDatePickerChange"
            :disabled="loading"
            :max="format(maxDate, 'yyyy-MM-dd')"
            @keydown.prevent
            class="w-full h-[20px] rounded-md border-gray-300 text-xs shadow-sm focus:border-yellow-500 focus:ring-yellow-500"
            style="width: min-content !important"
          />
          <button
            @click="emit('dateChange', timelapse?.next ?? null)"
            :disabled="!(timelapse && timelapse.next)"
            type="button"
            class="border-transparent inline-flex items-center px-4 text-sm font-medium"
          >
            <ChevronRightIcon
              class="h-6 w-6 text-oaiGray-300"
              aria-hidden="true"
              :class="{ 'text-transparent': !(timelapse && timelapse.next) }"
            />
          </button>
        </div>
        <div class="flex justify-end">
          <button type="button" v-if="timelapse" @click="emit('downloadClick')">
            <a :href="timelapse.url">
              <ArrowDownTrayIcon class="h-6 w-6 hover:text-yellow" />
            </a>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ArrowDownTrayIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/solid";
import { format, getDay, subDays } from "date-fns";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import OaiVideoPlayer from "shared/components/camera/OaiVideoPlayer.vue";
import { DailyTimelapseResponse } from "shared/types/Camera";

defineProps<{
  enableHeaderDateField?: boolean;
  date: Date | null;
  timelapse: DailyTimelapseResponse | null;
  loading: boolean;
  contentClass?: string;
  noSrcMessage?: string;
}>();

const emit = defineEmits<{
  (eventName: "dateChange", payload: string | null): void;
  (eventName: "downloadClick"): void;
}>();

const { t } = useI18n();

const maxDate = computed(() => {
  const today = new Date();
  const monday = 1;
  if (getDay(today) === monday) {
    return subDays(today, 2);
  } else {
    return subDays(today, 1);
  }
});

const formatDate = (dateString: Date) => format(new Date(dateString), "dd.MM.yyyy");

const handleHeaderDatePickerChange = (event: Event) => {
  const target = event.target as HTMLInputElement;
  emit("dateChange", target.value);
};
</script>
