import { differenceInDays, format, startOfDay, subDays } from "date-fns";
import i18n from "shared/i18n";
import { createPlanContext, filterPlanConfigByLocation } from "shared/views/planner/planner";
import projectProgressService from "@/services/projectProgressService";
import { QueryValueContext, QueryValueSingleValue } from "@/types/reports/PlotQueryValue";
import { formatValue } from "@/views/reports/plots/query_value/queryValue";

const { t } = i18n.global;

const convertToValue = (value: number | null) => (value !== null ? value * 100 : null);

const calculateActualValue = (
  context: QueryValueContext,
  currentDate: Date,
  previousDate: Date,
) => {
  const { planConfig, processes, projectDurationSettings, hierarchyTags, filters } = context;

  if (!planConfig || !processes || !projectDurationSettings || hierarchyTags.length === 0) {
    return null;
  }

  const currentValue = projectProgressService.calculateProjectProgressPercentage(
    planConfig,
    processes,
    projectDurationSettings,
    hierarchyTags,
    currentDate,
    filters.location,
  );

  const previousValue = projectProgressService.calculateProjectProgressPercentage(
    planConfig,
    processes,
    projectDurationSettings,
    hierarchyTags,
    previousDate,
    filters.location,
  );

  if (currentValue === null || previousValue === null) {
    return null;
  }

  return currentValue - previousValue;
};

const calculatePlannedInterval = (context: QueryValueContext) => {
  const { planConfig, processes, projectDurationSettings, hierarchyTags, filters } = context;

  if (!planConfig || !processes || !projectDurationSettings || hierarchyTags.length === 0) {
    return null;
  }

  const filteredPlan = filterPlanConfigByLocation(planConfig, hierarchyTags, filters.location);

  const planContext = createPlanContext(filteredPlan, hierarchyTags);

  const leafPlannerItems = new Set(
    filteredPlan.planner_items
      .filter((plannerItem) => {
        const children = planContext.plannerItemsByParentId[plannerItem._id] || [];
        return children.length === 0;
      })
      .map((plannerItem) => plannerItem._id),
  );

  const { earliest, latest } = filteredPlan.planned_events.reduce(
    (acc, plannedEvent) => {
      if (!leafPlannerItems.has(plannedEvent.planner_item_id)) {
        return acc;
      }
      if (!acc.earliest || plannedEvent.start < acc.earliest) {
        acc.earliest = plannedEvent.start;
      }
      if (!acc.latest || plannedEvent.end > acc.latest) {
        acc.latest = plannedEvent.end;
      }
      return acc;
    },
    { earliest: null, latest: null } as { earliest: Date | null; latest: Date | null },
  );

  if (!earliest || !latest) {
    return null;
  }

  return { earliest, latest };
};

const calculatePlannedValue = (plannedIntervalDays: number, previousPeriodDays: number) => {
  if (plannedIntervalDays === 0) {
    return null;
  }
  return previousPeriodDays / plannedIntervalDays;
};

const getPreviousPeriodDaysFromFilters = (context: QueryValueContext) => {
  if (context.filters.daterange.type === "last_week") {
    return 7;
  }
  if (context.filters.daterange.type === "last_2_weeks") {
    return 14;
  }
  return 30;
};

const calculateProjectProgressMetric = (
  context: QueryValueContext,
): QueryValueSingleValue | null => {
  const plannedInterval = calculatePlannedInterval(context);
  if (plannedInterval === null) {
    return { value: null };
  }

  const plannedIntervalDays = differenceInDays(plannedInterval.latest, plannedInterval.earliest);

  const firstProcessStartDate =
    context.processes && context.processes.length > 0 ? context.processes[0].start_time : null;

  const previousPeriodDays = Math.min(
    plannedIntervalDays,
    getPreviousPeriodDaysFromFilters(context),
  );

  const actualValueEnd = startOfDay(subDays(new Date(), 1));
  const actualValueStart = subDays(actualValueEnd, previousPeriodDays);
  const previousPeriodEnd = subDays(actualValueStart, 1);
  const previousPeriodStart = subDays(previousPeriodEnd, previousPeriodDays);

  const value = calculateActualValue(context, actualValueEnd, actualValueStart);
  const previousPeriodValue = calculateActualValue(context, previousPeriodEnd, previousPeriodStart);

  const plannedValue = calculatePlannedValue(plannedIntervalDays, previousPeriodDays);

  const plannedValuePercentage = convertToValue(plannedValue);

  return {
    value: convertToValue(value),
    previous_period: {
      value: convertToValue(previousPeriodValue),
      label: t(
        `analytics.reports.query_value_progress.velocity_range.${context.filters.daterange.type}`,
      ),
      show_change_than_text: true,
      null_out_change: !firstProcessStartDate || firstProcessStartDate > previousPeriodStart,
    },
    extra_value: {
      value: plannedValuePercentage,
      label: t("analytics.reports.query_value_progress.planned_velocity"),
      show_change_than_text: true,
      null_out_change: !firstProcessStartDate || firstProcessStartDate > actualValueStart,
      tooltip: t("analytics.reports.query_value_progress.velocity_planned_tooltip", {
        formattedStartDate: format(plannedInterval.earliest, "dd.MM.yyyy"),
        formattedEndDate: format(plannedInterval.latest, "dd.MM.yyyy"),
        plannedIntervalDays,
        formattedVelocity: formatValue(plannedValuePercentage, "velocity"),
        previousPeriodDays,
      }),
    },
    reportSummaryContext: {
      start_date: actualValueStart,
      end_date: actualValueEnd,
      previous_period_start_date: previousPeriodStart,
      previous_period_end_date: previousPeriodEnd,
    },
  };
};

export default calculateProjectProgressMetric;
