<template>
  <div class="flex flex-col gap-12">
    <div class="flex flex-col gap-4">
      <div class="flex items-center gap-2">
        <h1 class="text-xl font-bold px-1 text-gray-900 truncate">
          {{ t("organizations.users") }} ({{ usersForOrganizationAndChildren.length }})
        </h1>
        <div class="flex-1" />
        <input
          type="text"
          v-model="userFilterText"
          autocomplete="one-time-code"
          :placeholder="t('organizations.filter_for_users')"
          class="px-2 py-1 h-[28px] rounded text-xs border border-gray-300 focus:border-yellow-500 focus:outline-none bg-white min-w-0 max-w-[150px]"
          :style="{ boxShadow: 'none' }"
        />
        <button
          @click.stop="isInviteUsersModalOpen = true"
          v-if="hasOrganizationAdminPermission"
          type="button"
          class="min-w-0 flex items-center gap-1 rounded bg-yellow-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-yellow-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-600"
        >
          <PlusIcon class="h-5 w-5" />
          <span class="truncate">{{ t("organizations.invite_members") }}</span>
        </button>
      </div>
      <div
        class="bg-white border rounded flex flex-col min-h-[200px]"
        :class="isFetching ? 'loadingEl' : ''"
      >
        <OrganizationTabUsersList
          v-if="childOrganizations.length === 0"
          :users="usersForOrganization"
          :organization="organization"
          :organizationsByParentId="organizationsByParentId"
        />
        <OrganizationTabUsersHierarchyItem
          v-for="(childOrganization, index) in organizationsForHierarchyItem"
          :key="childOrganization._id"
          :organization="childOrganization"
          :organizationsByParentId="organizationsByParentId"
          :usersByOrganizationId="usersByOrganizationId"
          :hideFirstTopBorder="index === 0 && usersForOrganization.length === 0"
        />
      </div>
    </div>
    <InviteUsersModal
      v-if="isInviteUsersModalOpen"
      :existingUsers="usersForOrganization"
      :organizationId="organization._id"
      @close="isInviteUsersModalOpen = false"
    />
  </div>
</template>

<script lang="ts" setup>
import { PlusIcon } from "@heroicons/vue/24/solid";
import { computed, defineProps, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useHasOrganizationPermission } from "shared/composables/organization";
import { Organization } from "shared/types/Organization";
import { User } from "shared/types/User";
import InviteUsersModal from "@/components/users/InviteUsersModal.vue";
import { useUsersForOrganization } from "@/composables/user";
import textService from "@/services/textService";
import OrganizationTabUsersHierarchyItem from "@/views/organizations/components/OrganizationTabUsersHierarchyItem.vue";
import OrganizationTabUsersList from "@/views/organizations/components/OrganizationTabUsersList.vue";
import { getUsersForOrganizationAndChildren } from "@/views/organizations/organizations";

const { t } = useI18n();

const props = defineProps<{
  organization: Organization;
  organizationsByParentId: Record<string, Organization[] | undefined>;
}>();

const isInviteUsersModalOpen = ref(false);
const userFilterText = ref("");

const hasOrganizationAdminPermission = useHasOrganizationPermission(
  "organization_admin",
  props.organization._id,
);

const { users, isFetching } = useUsersForOrganization(computed(() => props.organization._id));

const childOrganizations = computed(
  () => props.organizationsByParentId[props.organization._id] || [],
);

const organizationsForHierarchyItem = computed(() =>
  childOrganizations.value.length > 0 ? [props.organization] : [],
);

const filteredUsers = computed(() => {
  if (!userFilterText.value) {
    return users.value;
  }

  const searchItems = textService
    .normalize(userFilterText.value)
    .toLowerCase()
    .split(" ")
    .map((item) => item.trim())
    .filter((item) => item);

  return users.value.filter((user) => {
    const name = textService.normalize(user.name || "").toLowerCase();
    return searchItems.every((searchItem) => name.includes(searchItem));
  });
});

const usersByOrganizationId = computed(() =>
  filteredUsers.value.reduce((acc, user) => {
    for (const organization of user.organizations) {
      if (organization.explicit_groups.length > 0) {
        if (!acc[organization.organization_id]) {
          acc[organization.organization_id] = [];
        }
        (acc[organization.organization_id] as User[]).push(user);
      }
    }
    return acc;
  }, {} as Record<string, User[] | undefined>),
);

const usersForOrganization = computed(
  () => usersByOrganizationId.value[props.organization._id] || [],
);

const usersForOrganizationAndChildren = computed(() =>
  getUsersForOrganizationAndChildren(
    props.organization._id,
    props.organizationsByParentId,
    usersByOrganizationId.value,
  ),
);
</script>
