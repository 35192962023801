<template>
  <div
    class="inline-flex rounded-full px-2 text-xs font-semibold leading-5"
    :class="
      projectPermissionGroup in colors
        ? colors[projectPermissionGroup]
        : 'bg-yellow-300 text-yellow-800'
    "
  >
    {{ t(`permissions.${projectPermissionGroup}`) }}
  </div>
</template>

<script lang="ts" setup>
import { defineProps } from "vue";
import { useI18n } from "vue-i18n";
import { UserProjectPermissionGroup } from "shared/types/User";

const colors: Partial<Record<UserProjectPermissionGroup, string>> = {
  project_admin: "bg-green-100 text-green-800",
  all_camera_features_user: "bg-gray-300 text-gray-800",
  live_camera_feature_user: "bg-orange-200 text-yellow-800",
};

defineProps<{ projectPermissionGroup: UserProjectPermissionGroup }>();

const { t } = useI18n();
</script>
