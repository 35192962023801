<template>
  <LoginComponent :userPasswordLogin="signIn" :socialLogins="[microsoftLogin]" />
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { useTrackEvent } from "shared/composables/tracking";
import LoginComponent from "shared/views/auth/Login.vue";
import { useLoadUserData } from "@/composables/auth";
import { useMicrosoftSignIn, useSignIn } from "@/composables/auth";

const trackEvent = useTrackEvent();
const loadUserData = useLoadUserData();
const microsoftSignIn = useMicrosoftSignIn();
const signIn = useSignIn();

const { t } = useI18n();

const handleMicrosoftLogin = async () => {
  trackEvent("user_sso_microsoft_click");
  await microsoftSignIn();
  await loadUserData();
  trackEvent("user_sso_microsoft_success");
};

const microsoftLogin = {
  label: t("authentication.login.sign_in_with_microsoft"),
  logoData:
    "data:image/svg+xml,%3csvg%20width='21'%20height='21'%20viewBox='0%200%2021%2021'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3crect%20x='11.375'%20y='11.375'%20width='8.75'%20height='8.75'%20fill='%23FEBA08'/%3e%3crect%20x='0.875'%20y='11.375'%20width='8.75'%20height='8.75'%20fill='%2305A6F0'/%3e%3crect%20x='11.375'%20y='0.875'%20width='8.75'%20height='8.75'%20fill='%2380BC06'/%3e%3crect%20x='0.875'%20y='0.875'%20width='8.75'%20height='8.75'%20fill='%23F25325'/%3e%3c/svg%3e",
  handleLogin: handleMicrosoftLogin,
};
</script>
