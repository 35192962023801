<template>
  <div v-if="users.length > 0">
    <OrganizationTabUsersItem
      v-for="(user, index) in sortedUsers"
      :key="user._id"
      :user="user"
      :organization="organization"
      :showCompanyTypeBadge="getShouldShowCompanyTypeBadge(user, index)"
      :indentation="indentation"
      :hideBorderBottom="index === sortedUsers.length - 1"
      :organizationsByParentId="organizationsByParentId"
    />
  </div>
  <div
    v-if="sortedUsers.length === 0"
    class="px-2 py-2 text-gray-400 text-xs h-[50px] flex items-center"
    :style="{ paddingLeft: `${calculateIndentation(indentation)}px` }"
  >
    <span class="truncate">{{ t("organizations.no_users") }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { Organization } from "shared/types/Organization";
import {
  User,
  UserCompanyType,
  UserOrganization,
  UserOrganizationPermissionGroup,
} from "shared/types/User";
import textService from "@/services/textService";
import OrganizationTabUsersItem from "@/views/organizations/components/OrganizationTabUsersItem.vue";
import { calculateIndentation } from "@/views/organizations/organizations";

const props = withDefaults(
  defineProps<{
    users: User[];
    organization: Organization;
    indentation?: number;
    organizationsByParentId: Record<string, Organization[] | undefined>;
  }>(),
  { indentation: 0 },
);

const { t } = useI18n();

const organizationByUsers = computed(() =>
  props.users.reduce((acc, user) => {
    acc[user._id] = user.organizations.find(
      (organization) => organization.organization_id === props.organization._id,
    );
    return acc;
  }, {} as Record<string, UserOrganization | undefined>),
);

const groupWeight: Record<UserOrganizationPermissionGroup, number> = {
  organization_base: 0,
  organization_admin: 100,
};

const userStatusOrder: Record<User["user_status"], number> = {
  confirmed: 0,
  force_change_password: 100,
};

const getGroupWeight = (groups?: UserOrganizationPermissionGroup[]) =>
  (groups || []).map((group) => groupWeight[group] || 0).reduce((acc, item) => acc + item, 0);

const sortedUsers = computed(() =>
  props.users.slice().sort((a, b) => {
    const organizationA = organizationByUsers.value[a._id];
    const organizationB = organizationByUsers.value[b._id];

    const companyTypeA = organizationA?.company_type || "";
    const companyTypeB = organizationB?.company_type || "";

    const groupsA = getGroupWeight(organizationA?.groups);
    const groupsB = getGroupWeight(organizationB?.groups);

    const userStatusOrderA = userStatusOrder[a.user_status] || 0;
    const userStatusOrderB = userStatusOrder[b.user_status] || 0;

    const nameA = a.name || "";
    const nameB = b.name || "";

    if (companyTypeA === companyTypeB) {
      if (groupsA === groupsB) {
        if (userStatusOrderA === userStatusOrderB) {
          return textService.localeCompareWithEmptyStringAtEnd(nameA, nameB);
        }
        return userStatusOrderA - userStatusOrderB;
      }
      return groupsB - groupsA;
    }
    return textService.localeCompareWithEmptyStringAtEnd(companyTypeA, companyTypeB);
  }),
);

const companyTypes = computed(
  () =>
    new Set<UserCompanyType>(
      props.users
        .map((user) => organizationByUsers.value[user._id]?.company_type)
        .filter((item) => item) as UserCompanyType[],
    ),
);

const getShouldShowCompanyTypeBadge = (user: User, index: number) => {
  if (companyTypes.value.size < 2) {
    return false;
  }
  const previousUser = sortedUsers.value[index - 1];
  if (!previousUser) {
    return true;
  }
  const userCompanyType = organizationByUsers.value[user._id]?.company_type ?? null;
  const previousUserCompanyType = organizationByUsers.value[previousUser._id]?.company_type ?? null;
  return userCompanyType !== previousUserCompanyType;
};
</script>
