<template>
  <SlideOver
    :open="mode !== 'hide'"
    @closed="!isConfirmationModalOpen && $emit('cancelEdit')"
    maxWidth="1000px"
  >
    <template #title>
      <div class="flex flex-row gap-2 items-center" v-if="!isEditingName">
        <span class="flex items-center">{{ eventRecord.name }}</span>
        <PencilIcon
          class="w-4 h-4 text-gray-600 cursor-pointer"
          @click="handleEditNameClick"
          style="flex-shrink: 0"
        />
      </div>
      <div v-if="isEditingName" class="flex gap-2 items-center">
        <input
          type="text"
          v-model="editingName"
          ref="editingNameRef"
          @keydown.enter="handleNameEditingConfirm"
          @keydown.esc.stop="isEditingName = false"
          class="px-2 py-1 border-gray-300 rounded focus:border-yellow-500 focus:ring-0"
        />
        <CheckIcon class="w-5 h-5 cursor-pointer" @click="handleNameEditingConfirm" />
        <XMarkIcon class="w-5 h-5 cursor-pointer" @click="isEditingName = false" />
      </div>
      <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
    </template>
    <template #content>
      <div class="flex flex-col gap-10 mt-2 mx-2 mb-12">
        <div v-if="mode === 'edit'">
          <div class="flex justify-between items-center">
            <div
              class="flex items-center flex-1 py-3"
              @click="toggleMilestone"
              :class="{
                'cursor-pointer': eventRecord.type === 'planned',
              }"
            >
              <input
                type="checkbox"
                :checked="isMilestone"
                class="h-4 w-4 rounded border-gray-300 text-gray-600"
                :disabled="eventRecord.type === 'actual'"
              />
              <div class="ml-3 text-sm font-medium text-gray-900">
                {{ $t("analytics.planner.milestone") }}
              </div>
            </div>
            <div
              class="px-2 py-1 text-white text-xs"
              :style="`background-color: ${eventTitleColor}`"
            >
              {{ eventTitle }}
            </div>
          </div>
          <div>
            <DateTime
              name="startDate"
              :value="startDate"
              @changed="
                startDate = $event;
                calculateWorkingDays();
              "
              :timeDisabled="true"
              :dateLabel="$t('analytics.planner.start_date')"
              :timeLabel="$t('analytics.planner.start_time')"
              cls="py-3"
              :disabled="eventRecord.type === 'actual'"
            />
            <div class="py-3">
              <div class="text-sm font-medium leading-6 text-gray-900">
                {{ $t("analytics.planner.working_days_input_label") }}
              </div>
              <input
                :disabled="isMilestone || eventRecord.type === 'actual'"
                type="number"
                v-model="workingDays"
                @input="handleWorkingDaysInput"
                step="0.5"
                style="width: 182px"
                class="rounded border-gray-300 focus:border-yellow-500 focus:ring-0 customInput disabled:bg-gray-100"
              />
            </div>
            <DateTime
              name="endDate"
              :value="endDate"
              @changed="
                endDate = $event;
                calculateWorkingDays();
              "
              :disabled="isMilestone || eventRecord.type === 'actual'"
              :timeDisabled="true"
              :dateLabel="$t('analytics.planner.end_date')"
              :timeLabel="$t('analytics.planner.end_time')"
              cls="py-3"
            />
          </div>
          <div v-if="validationErrorMessage" class="py-2">
            <small class="text-red-500">{{ validationErrorMessage }}</small>
          </div>
          <div class="flex pt-4 gap-2 justify-end" v-if="eventRecord.type === 'planned'">
            <button
              @click="saveEvent()"
              type="button"
              :class="{ error: 'bg-gray-100' }"
              class="inline-flex items-center rounded-md border border-transparent bg-green-400 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              :disabled="!!validationErrorMessage"
            >
              {{ $t("buttons.apply") }}
            </button>
          </div>
        </div>
        <div v-if="mode === 'edit'" class="border-t border-solid border-gray-200" />
        <PlannerComments
          :sourceId="eventRecord.sourceId"
          :key="plannerCommentsKey"
          @confirmationModalIsOpen="isConfirmationModalOpen = true"
          @confirmationModalIsClosed="isConfirmationModalOpen = false"
          @commentAdded="$emit('commentAdded', $event)"
          @commentDeleted="$emit('commentDeleted', $event)"
        />
      </div>
    </template>
  </SlideOver>
</template>

<script lang="ts">
import { PencilIcon, CheckIcon, XMarkIcon } from "@heroicons/vue/24/solid";
import { set } from "date-fns";
import { defineComponent, PropType } from "vue";
import PlanMixins from "shared/mixins/PlanMixins";
import durationService from "shared/services/durationService";
import { EventToEdit, PlannerComment } from "shared/types/Plan";
import { ProjectDurationSettings } from "shared/types/ProjectDurationSettings";
import DateTime from "@/views/planner/components/DateTime.vue";
import PlannerComments from "@/views/planner/components/PlannerComments.vue";
import SlideOver from "@/views/planner/components/SlideOver.vue";

export default defineComponent({
  name: "EventEditor",
  props: {
    mode: {
      type: String as PropType<"hide" | "edit">,
      required: true,
    },
    eventRecord: {
      type: Object as PropType<EventToEdit>,
      required: true,
    },
    now: {
      type: Date,
      required: true,
    },
    projectDurationSettings: {
      type: Object as PropType<ProjectDurationSettings>,
      required: false,
    },
  },
  emits: ["saveEvent", "cancelEdit", "commentAdded", "commentDeleted", "nameEdited"],
  components: {
    SlideOver,
    DateTime,
    PlannerComments,
    PencilIcon,
    CheckIcon,
    XMarkIcon,
  },
  data() {
    return {
      startDate: null as Date | null,
      endDate: null as Date | null,
      isMilestone: false as boolean,
      comments: [] as PlannerComment[],
      isEditingName: false,
      editingName: "",
      workingDays: 0,
      isConfirmationModalOpen: false as boolean,
    };
  },
  mixins: [PlanMixins],
  computed: {
    eventTitleColor() {
      return this.eventRecord.type === "planned" ? "#bccfcc" : "#336260";
    },
    eventTitle() {
      const translatedEventTypeLabels: Record<string, string> = {
        actual: this.$t("analytics.planner.actual_event_name").toUpperCase(),
        planned: this.$t("analytics.planner.planned_event_name").toUpperCase(),
      };

      return translatedEventTypeLabels[this.eventRecord.type] || "";
    },
    validationErrorMessage() {
      return this.getStartEndDateValidationMessageForPlannedEvent(
        this.startDate,
        this.endDate,
        this.isMilestone,
      );
    },
    plannerCommentsKey() {
      return `${this.currentCustomerName}-${this.currentSiteId}-${this.eventRecord.sourceId}`;
    },
  },
  watch: {
    mode() {
      this.initialize();
    },
    startDate() {
      if (this.isMilestone) {
        this.endDate = this.startDate;
      }
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.eventRecord && this.mode !== "hide") {
        this.startDate = this.eventRecord.startDate;
        this.endDate = this.eventRecord.endDate;
        this.isMilestone =
          this.eventRecord.startDate?.getTime() === this.eventRecord.endDate?.getTime();
      }
      this.isEditingName = false;
      this.calculateWorkingDays();
    },
    saveEvent() {
      if (!this.validationErrorMessage) {
        this.$emit("saveEvent", this.startDate, this.endDate);
      }
    },
    toggleMilestone() {
      if (this.eventRecord.type === "planned") {
        this.isMilestone = !this.isMilestone;
        this.endDate = this.isMilestone ? this.startDate : set(this.now, { hours: 17, minutes: 0 });
        this.calculateWorkingDays();
      }
    },
    handleEditNameClick() {
      this.isEditingName = true;
      this.editingName = this.eventRecord.name;
      setTimeout(() => {
        (this.$refs.editingNameRef as HTMLInputElement)?.focus();
      }, 50);
    },
    handleNameEditingConfirm() {
      this.isEditingName = false;
      this.$emit("nameEdited", { eventRecord: this.eventRecord, name: this.editingName });
    },
    calculateWorkingDays() {
      const durationSettings =
        this.eventRecord.type === "planned"
          ? this.projectDurationSettings?.settingsWithoutDisturbances
          : this.projectDurationSettings?.settings;
      if (durationSettings && this.startDate && this.endDate) {
        this.workingDays = durationService.calculateDuration(
          durationSettings,
          this.startDate,
          this.endDate,
        ).workingDays;
      }
    },
    handleWorkingDaysInput() {
      if (this.projectDurationSettings && this.startDate && typeof this.workingDays === "number") {
        const endDate = durationService.calculateEndDate(
          this.projectDurationSettings.settings,
          this.startDate,
          this.workingDays,
        );
        this.endDate = set(endDate || this.startDate, { hours: 17, minutes: 0 });
      }
    },
  },
});
</script>
