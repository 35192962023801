<template>
  <CameraItemLayout :onlyLeft="isPublic" :noData="noData && !loading">
    <template #contentLeft>
      <GalleryContentImages
        :enableHeaderDateField="isPublic"
        :date="date"
        :data="data"
        :loading="loading"
        :showDefaultImages="showDefaultImages"
        :cameraId="route.params.camera_id as string"
        :hasAdminPermissions="hasAdminPermissions"
        @dateChange="handleDateChange"
      />
    </template>
    <template #contentRight>
      <div
        class="flex items-center justify-end"
        v-if="!isPublic && hasFilePicker && !isMobileDevice"
      >
        <BasicMenu position="left">
          <template #icon>
            <EllipsisVerticalIcon class="h-6 w-6" aria-hidden="true" />
          </template>
          <template #content="{ item }">
            <component :is="item" v-slot="{ active }" @click="galleryDownloadModalOpen = true">
              <p
                :class="[
                  active ? 'text-yellow-600' : 'text-gray-700',
                  'px-4 py-2 cursor-pointer whitespace-nowrap flex items-center',
                ]"
              >
                <ArrowDownTrayIcon class="h-5 w-5 mr-2" aria-hidden="true" />

                <span> {{ $t("camera.gallery.download.menu_title") }} </span>
              </p>
            </component>
          </template>
        </BasicMenu>
      </div>
      <!-- datepicker is cropped without padding -->
      <div class="col-span-4 lg:col-span-2 pb-72">
        <div class="space-y-4 divide-y divide-gray-200">
          <h3 class="text-md 2xl:text-lg leading-6 font-medium text-gray-900">
            {{ $t("camera.gallery.pick_a_date") }}
          </h3>
          <Form
            @submit="onSubmit"
            :validationSchema="schema"
            class="space-y-3 sm:space-y-5 py-3 sm:py-0"
          >
            <div class="mt-1 pt-4">
              <DateField
                name="galleryDate"
                :label="$t('camera.period.date')"
                :maxDate="maxDate"
                :inlineField="true"
                :date="date"
              />
            </div>

            <div class="flex sm:pt-5">
              <MainButton
                :label="$t('camera.gallery.search')"
                type="submit"
                color="yellow"
                @click="buttonLoad = true"
                class="mx-0 sm:mr-0 mt-0"
              >
                <LoadingSpinner v-if="buttonLoad" size="w-5 h-5" color="white" class="mr-2" />
              </MainButton>
            </div>
          </Form>

          <!-- Weather Container -->
          <WeatherContainer
            ref="weatherContainerComponent"
            :date="dateToString"
            :loadMain="loading"
            v-if="data"
          />
        </div>
      </div>
    </template>
  </CameraItemLayout>
  <GalleryDownload
    v-if="galleryDownloadModalOpen"
    @close="galleryDownloadModalOpen = false"
  ></GalleryDownload>
</template>

<script setup lang="ts">
import { EllipsisVerticalIcon, ArrowDownTrayIcon } from "@heroicons/vue/24/solid";
import axios from "axios";
import { format, parseISO } from "date-fns";
import mobile from "is-mobile";
import { Form } from "vee-validate";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import * as yup from "yup";
import GalleryContentImages from "shared/components/camera/GalleryContentImages.vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { useHasPermission } from "shared/composables/project";
import { useCustomToast } from "shared/composables/toast";
import CameraRepository from "shared/repositories/CameraRepository";
import logger from "shared/services/logger";
import { GalleryImagesPaginatedResponse } from "shared/types/Camera";
import DateField from "@/components/forms/DateField.vue";
import BasicMenu from "@/components/other/BasicMenu.vue";
import MainButton from "@/components/other/MainButton.vue";
import CameraItemLayout from "@/views/camera/components/CameraItemLayout.vue";
import GalleryDownload from "@/views/camera/components/GalleryDownload.vue";
import WeatherContainer from "@/views/camera/components/WeatherContainer.vue";

const props = defineProps({
  isPublic: {
    type: Boolean,
    required: false,
  },
});

const isMobileDevice = ref<boolean>(mobile());
const hasFilePicker = !!window.showSaveFilePicker;

const data = ref(null) as Ref<null | GalleryImagesPaginatedResponse>;
const date = ref(null) as Ref<Date | null>;
const loading = ref(false) as Ref<boolean>;
const buttonLoad = ref(false) as Ref<boolean>;
const showDefaultImages = ref(false) as Ref<boolean>;
const galleryDownloadModalOpen = ref(false) as Ref<boolean>;
const route = useRoute();
const { t } = useI18n();
const noData = ref(true) as Ref<boolean>;
const toast = useCustomToast();
const hasAdminPermissions = useHasPermission(["app_admin", "pct_admin"]);

const schema = computed(() =>
  yup.object({
    galleryDate: yup
      .date()
      .nullable()
      .required(t("err.required"))
      .max(
        maxDate.value,
        t("err.err_future_date", { maxDate: format(maxDate.value, "yyyy-MM-dd") }),
      ),
  }),
);

onMounted(() => {
  getGalleryImages(dateToString.value);
});

const maxDate = computed(() => {
  return new Date();
});

const dateToString = computed(() => {
  return date.value ? format(date.value, "yyyy-MM-dd") : "";
});

watch(loading, (newLoadingValue) => {
  if (!newLoadingValue) {
    buttonLoad.value = newLoadingValue;
  }
});

const getGalleryImages = async (value: string | null | undefined) => {
  try {
    noData.value = false;

    date.value = value ? parseISO(value) : null;
    loading.value = true;

    const { customer_name, site_id, camera_id } = route.params;

    data.value = props.isPublic
      ? await CameraRepository.getPublicPaginatedGalleryImages(
          customer_name as string,
          site_id as string,
          camera_id as string,
          value || (route.query.date as string) || null,
          route.params.token as string,
        )
      : await CameraRepository.getPaginatedGalleryImages(
          customer_name as string,
          site_id as string,
          camera_id as string,
          value || (route.query.date as string) || null,
        );
    date.value = new Date(data.value.date);
    showDefaultImages.value = false;

    data.value.items.forEach((img) => {
      const imgElement = new Image();
      imgElement.src = img.url;
      imgElement.addEventListener("load", () => {
        img.width = imgElement.naturalWidth;
        img.height = imgElement.naturalHeight;
      });
    });

    loading.value = false;
    if (buttonLoad.value) {
      buttonLoad.value = false;
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error?.response?.status !== 404) {
        logger.error(error);
      }
      data.value = null;
      if (error?.response?.data?.code === "NO_DATA_YET") {
        noData.value = true;
      } else {
        toast.error(t("err.no_data_found"));
      }
      showDefaultImages.value = true;
      loading.value = false;
      if (buttonLoad.value) {
        buttonLoad.value = false;
      }
      loading.value = false;
    }
  } finally {
    loading.value = false;
    if (buttonLoad.value) {
      buttonLoad.value = false;
    }
  }
};

const onSubmit = async (values: Record<string, number | Date>) => {
  const formattedValue = format(values.galleryDate, "yyyy-MM-dd");
  await getGalleryImages(formattedValue);
};

const handleDateChange = (date: string | null) => {
  getGalleryImages(date);
};
</script>
