import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { computed, nextTick, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useCustomToast } from "shared/composables/toast";
import OrganizationRepository from "shared/repositories/OrganizationRepository";
import logger from "shared/services/logger";
import {
  CreateOrganizationResponse,
  Organization,
  OrganizationToCreate,
  OrganizationToUpdate,
  OrganizationToUpdateBase,
} from "shared/types/Organization";
import {
  User,
  UserEveryGroupForOrganizations,
  UserGroup,
  UserOrganizationPermissionGroup,
} from "../types/User";
import { useLoadAppUser } from "./auth";

export const useOrganizations = () => {
  const { t } = useI18n();
  const { data, isLoading } = useQuery<Organization[]>({
    queryKey: ["organizations"],
    queryFn: () => OrganizationRepository.loadOrganizations(),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("organizations.load_error"));
      return false;
    },
  });
  const organizations = computed(() => data.value || []);
  return { organizations, isLoading };
};

export const useCreateOrganization = () => {
  const queryClient = useQueryClient();
  const loadAppUser = useLoadAppUser();

  const { t } = useI18n();

  const { mutateAsync: createOrganization, isLoading } = useMutation<
    CreateOrganizationResponse,
    Error,
    OrganizationToCreate
  >({
    mutationFn: (payload) => OrganizationRepository.createOrganization(payload),
    onSuccess: (result) => {
      queryClient.setQueryData(["organizations"], result.organizations);
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === "users-for-organization",
      });
      loadAppUser();
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("organizations.add_organization_error"));
      return false;
    },
  });

  return { createOrganization, isLoading };
};

export const useUpdateOrganization = () => {
  const queryClient = useQueryClient();

  const { t } = useI18n();

  const { mutateAsync: updateOrganization, isLoading } = useMutation<
    Organization[],
    Error,
    OrganizationToUpdate
  >({
    mutationFn: (payload) => OrganizationRepository.updateOrganization(payload),
    onSuccess: (result) => {
      queryClient.setQueryData(["organizations"], result);
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === "users-for-organization",
      });
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("organizations.edit_organization_error"));
      return false;
    },
  });

  return { updateOrganization, isLoading };
};

export const useUpdateOrganizationBase = () => {
  const queryClient = useQueryClient();

  const { t } = useI18n();

  const { mutateAsync: updateOrganization, isLoading } = useMutation<
    Organization[],
    Error,
    OrganizationToUpdateBase
  >({
    mutationFn: (payload) => OrganizationRepository.updateOrganizationBase(payload),
    onSuccess: (result) => {
      queryClient.setQueryData(["organizations"], result);
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === "users-for-organization",
      });
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("organizations.edit_organization_error"));
      return false;
    },
  });

  return { updateOrganization, isLoading };
};

export const useDeleteOrganization = () => {
  const queryClient = useQueryClient();

  const { t } = useI18n();

  const { mutateAsync: deleteOrganization, isLoading } = useMutation<Organization[], Error, string>(
    {
      mutationFn: (organizationId) => OrganizationRepository.deleteOrganization(organizationId),
      onSuccess: (result) => {
        queryClient.setQueryData(["organizations"], result);
        nextTick(() => {
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === "users-for-organization",
          });
        });
      },
      useErrorBoundary: (error) => {
        logger.error(error);
        useCustomToast().error(t("organizations.delete_organization_error"));
        return false;
      },
    },
  );

  return { deleteOrganization, isLoading };
};

const hasOrganizationPermissionForUser = (
  user: User,
  groups:
    | UserEveryGroupForOrganizations
    | UserEveryGroupForOrganizations[]
    | (UserEveryGroupForOrganizations[] | UserEveryGroupForOrganizations)[],
  organizationId: string,
) => {
  const requiredGroups:
    | UserEveryGroupForOrganizations[]
    | (UserEveryGroupForOrganizations[] | UserEveryGroupForOrganizations)[] = Array.isArray(groups)
    ? groups
    : [groups];

  if (
    requiredGroups.some((requiredGroup) =>
      Array.isArray(requiredGroup)
        ? requiredGroup.every((userGroup) => user.groups.includes(userGroup as UserGroup))
        : user.groups.includes(requiredGroup as UserGroup),
    )
  ) {
    return true;
  }

  const organization = user.organizations.find(
    (project) => project.organization_id === organizationId,
  );

  if (
    requiredGroups.some((requiredGroup) =>
      Array.isArray(requiredGroup)
        ? requiredGroup.every((userOrganizationPermissionGroup) =>
            organization?.groups.includes(
              userOrganizationPermissionGroup as UserOrganizationPermissionGroup,
            ),
          )
        : organization?.groups.includes(requiredGroup as UserOrganizationPermissionGroup),
    )
  ) {
    return true;
  }

  return false;
};

export const useHasOrganizationPermission = (
  groups:
    | UserEveryGroupForOrganizations
    | UserEveryGroupForOrganizations[]
    | (UserEveryGroupForOrganizations[] | UserEveryGroupForOrganizations)[],
  organizationId: string,
) => {
  const store = useStore();
  return computed(() => {
    const user = store.state.user as User | undefined;
    if (!user) {
      return false;
    }
    return hasOrganizationPermissionForUser(user, groups, organizationId);
  });
};

export const useLogoUrl = (organizationId: Ref<string>) => {
  const hasOrganizationBasePermission = useHasOrganizationPermission(
    "organization_base",
    organizationId.value,
  );
  const {
    data,
    isLoading: isLogoUrlLoading,
    isFetching: isLogoUrlFetching,
  } = useQuery<string | null>({
    queryKey: ["organization-logo-url", organizationId],
    queryFn: () => OrganizationRepository.loadLogoUrl(organizationId.value),
    staleTime: 2 * 60 * 60 * 1000,
    refetchOnMount: true,
    enabled: hasOrganizationBasePermission,
  });

  const logoUrl = computed(() => data.value ?? null);

  return { logoUrl, isLogoUrlLoading, isLogoUrlFetching };
};

export const useDeleteLogo = (organizationId: Ref<string>) => {
  const queryClient = useQueryClient();

  const {
    mutate: deleteLogo,
    isLoading: isLogoDeleting,
    isError: isDeleteError,
  } = useMutation<void, Error>({
    mutationFn: () => OrganizationRepository.deleteLogo(organizationId.value),
    onSuccess: () => {
      queryClient.setQueryData(["organization-logo-url", organizationId], null);
    },
  });

  return { deleteLogo, isLogoDeleting, isDeleteError };
};

export const useUploadLogo = (organizationId: Ref<string>) => {
  const queryClient = useQueryClient();

  const {
    mutate: uploadLogo,
    isLoading: isLogoUploading,
    isError: isLogoUploadError,
  } = useMutation<Record<string, string>, Error, string>({
    mutationFn: (fileString) => OrganizationRepository.uploadLogo(organizationId.value, fileString),
    onSuccess: (logoUrls) => {
      // delay it a bit to wait for cloudfront cache to be invalidated
      setTimeout(() => {
        Object.entries(logoUrls).forEach(([key, logoUrl]) => {
          queryClient.setQueryData(["organization-logo-url", key], logoUrl);
        });
      }, 2000);
    },
  });
  return { uploadLogo, isLogoUploading, isLogoUploadError };
};
