import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import {
  useCurrentCustomerName,
  useCurrentSiteId,
  useHasPermission,
} from "shared/composables/project";
import { useCustomToast } from "shared/composables/toast";
import { useUser } from "shared/composables/user";
import StreamRepository from "shared/repositories/StreamRepository";
import logger from "shared/services/logger";
import { Stream, StreamsToUpdateAsProjectAdmin } from "shared/types/Stream";

export const useStreams = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const hasPermission = useHasPermission("live_camera_feature_user");
  const hasAllCameraPermission = useHasPermission("all_camera_features_user");

  const { data, isLoading, error } = useQuery<Stream[]>({
    queryKey: ["streams", currentCustomerName, currentSiteId],
    queryFn: async () => {
      if (hasPermission) {
        const streams = await StreamRepository.loadStreams(currentCustomerName, currentSiteId);
        return streams.filter((stream) => hasAllCameraPermission || stream.aws_stream_id);
      }
      return [];
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      return false;
    },
  });

  const streams = computed(() => data.value || []);

  return { streams, isLoading, error };
};

export const useUpdateStreams = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const queryClient = useQueryClient();
  const { t } = useI18n();

  const {
    mutateAsync: updateStreams,
    isLoading: isUpdateLoading,
    isError: isUpdateError,
  } = useMutation<Stream[], Error, StreamsToUpdateAsProjectAdmin>({
    mutationFn: (streamsToUpdate) =>
      StreamRepository.updateStreamsForProjectAdmin(
        currentCustomerName,
        currentSiteId,
        streamsToUpdate,
      ),
    onSuccess: (streams) => {
      queryClient.setQueryData(["streams", currentCustomerName, currentSiteId], streams);
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("camera.update_error"));
      return false;
    },
  });

  return { updateStreams, isUpdateLoading, isUpdateError };
};

const useLiveImageSettingPersistor = () => {
  const user = useUser();
  const store = useStore();
  const localStorageKey = `oculai_useLiveImageByProjects_${user?._id}`;

  const persist = () => {
    window.localStorage.setItem(
      localStorageKey,
      JSON.stringify(store.state.useLiveImageByProjects),
    );
  };

  return { persist };
};

export const useLiveImageSetting = () => {
  const customerName = useCurrentCustomerName();
  const siteId = useCurrentSiteId();
  const store = useStore();
  const persistor = useLiveImageSettingPersistor();

  const setUseLiveImage = (useLiveImage: boolean) => {
    store.commit("setUseLiveImage", {
      customerName,
      siteId,
      useLiveImage,
    });
    persistor.persist();
  };

  const useLiveImage = computed(() => store.getters.useLiveImage(customerName, siteId));

  return {
    useLiveImage,
    setUseLiveImage,
  };
};
