import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useCurrentCustomerName, useCurrentSiteId } from "shared/composables/project";
import { useCustomToast } from "shared/composables/toast";
import PublicTokenRepository from "shared/repositories/PublicTokenRepository";
import logger from "shared/services/logger";
import publicTokenService from "shared/services/publicTokenService";
import { PublicToken, PublicTokenToCreate, PublicTokenToUpdate } from "shared/types/PublicToken";
import { Stream } from "shared/types/Stream";
import { useStreams } from "@/composables/stream";

export const usePublicTokens = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();
  const { t } = useI18n();

  const { data: publicTokens, isLoading: arePublicTokensLoading } = useQuery<PublicToken[]>({
    queryKey: ["public-tokens", currentCustomerName, currentSiteId],
    queryFn: () => PublicTokenRepository.loadPublicTokens(currentCustomerName, currentSiteId),
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("public_tokens.loading_error"));
      return false;
    },
  });

  return { publicTokens, arePublicTokensLoading };
};

export const useDeletePublicToken = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const { t } = useI18n();

  const { mutateAsync: deletePublicToken, isLoading } = useMutation<void, Error, string>({
    mutationFn: (publicTokenId) =>
      PublicTokenRepository.deletePublicToken(currentCustomerName, currentSiteId, publicTokenId),
    onSuccess: (result, publicTokenId) => {
      queryClient.setQueryData(
        ["public-tokens", currentCustomerName, currentSiteId],
        (publicTokens: PublicToken[] | undefined) =>
          publicTokens && publicTokens.filter((publicToken) => publicToken._id !== publicTokenId),
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("public_tokens.delete_error"));
      return false;
    },
  });

  return { deletePublicToken, isLoading };
};

export const useCreatePublicToken = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const { t } = useI18n();

  const { mutateAsync: createPublicToken, isLoading } = useMutation<
    PublicToken,
    Error,
    PublicTokenToCreate
  >({
    mutationFn: (publicTokenToCreate) =>
      PublicTokenRepository.createPublicToken(
        currentCustomerName,
        currentSiteId,
        publicTokenToCreate,
      ),
    onSuccess: (result) => {
      queryClient.setQueryData(
        ["public-tokens", currentCustomerName, currentSiteId],
        (publicTokens: PublicToken[] | undefined) => [...(publicTokens || []), result],
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("public_tokens.create_error"));
      return false;
    },
  });

  return { createPublicToken, isLoading };
};

export const useUpdatePublicToken = () => {
  const currentCustomerName = useCurrentCustomerName();
  const currentSiteId = useCurrentSiteId();

  const queryClient = useQueryClient();

  const { t } = useI18n();

  const { mutateAsync: updatePublicToken, isLoading } = useMutation<
    PublicToken,
    Error,
    PublicTokenToUpdate
  >({
    mutationFn: (publicTokenToUpdate) =>
      PublicTokenRepository.updatePublicToken(
        currentCustomerName,
        currentSiteId,
        publicTokenToUpdate,
      ),
    onSuccess: (result) => {
      queryClient.setQueryData(
        ["public-tokens", currentCustomerName, currentSiteId],
        (publicTokens: PublicToken[] | undefined) =>
          (publicTokens || []).map((publicToken) =>
            publicToken._id === result._id ? result : publicToken,
          ),
      );
    },
    useErrorBoundary: (error) => {
      logger.error(error);
      useCustomToast().error(t("public_tokens.update_error"));
      return false;
    },
  });

  return { updatePublicToken, isLoading };
};

export const useGetPublicTokenName = () => {
  const { t } = useI18n();
  const { streams } = useStreams();
  const streamsByCameraId = computed(() =>
    streams.value.reduce((acc, stream) => {
      acc[stream.camera_id] = stream;
      return acc;
    }, {} as Record<string, Stream>),
  );
  return (publicToken: PublicToken, options?: { hideCameraName?: boolean }) => {
    const claimNames = publicToken.claims
      .map((claim) => t(`public_tokens.claims.${claim}`))
      .join(", ");
    const cameraNames = publicToken.camera_ids
      .map((cameraId) => streamsByCameraId.value[cameraId]?.name)
      .filter((name) => name)
      .join(", ");
    if (cameraNames && !options?.hideCameraName) {
      return `${claimNames} (${cameraNames})`;
    }
    return claimNames;
  };
};

export const useCopyPublicTokenLink = () => {
  const { t } = useI18n();
  const toast = useCustomToast();
  return async (publicToken: PublicToken) => {
    const url = publicTokenService.createUrl(publicToken);
    try {
      await navigator.clipboard.writeText(url);
      toast.success(t("public_tokens.link_copied"));
    } catch (error) {
      logger.error(error);
      toast.error(t("public_tokens.link_copy_error"));
    }
  };
};
