<template>
  <div
    v-for="entry in timelapseVideos"
    :key="entry._id"
    class="divide-y border-b mb-3 lg:hidden border-r border-l"
  >
    <div
      v-for="header in headers"
      :key="header"
      class="grid grid-cols-2 divide-x first:border-t items-center first:font-bold first:capitalize"
    >
      <div class="col-span-1 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
        {{ $t(`camera.customizable_timelapse.table.${header}`) }}
      </div>
      <div class="col-span-1 justify-self-end px-6 py-2 text-sm text-gray-500 w-full">
        <template v-if="['start_date', 'end_date'].includes(header)">
          {{ formatDate(entry[header as keyof CustomizableTimelapse] as unknown as Date) }}
        </template>
        <template v-else-if="header === 'created'">
          {{ formatDateWithTime(entry[header as keyof CustomizableTimelapse] as unknown as Date) }}
        </template>
        <template v-else-if="header === 'camera'">
          {{
            streams.find((stream) => stream["camera_id"] === entry.camera_id)?.name ||
            entry.camera_id
          }}
        </template>
        <template v-else-if="header === 'play'">
          <LoadingSpinner v-if="entry.status === 'generating'" class="h-4 w-4" />
          <div
            v-else-if="entry.status === 'ready'"
            class="py-2 whitespace-nowrap text-sm text-gray-500"
          >
            <button
              @click="$emit('playVideo', entry.url)"
              type="button"
              class="inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-yellow-500 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              <PlayIcon class="-ml-1 mr-3 h-5 w-5" aria-hidden="true" />
              {{ $t("camera.customizable_timelapse.table.play_button") }}
            </button>
          </div>
          <div v-else class="px-14 py-2 whitespace-nowrap text-sm text-gray-500">
            <XCircleIcon class="-ml-1 mr-3 h-6 w-6 text-orange-400" aria-hidden="true" />
          </div>
        </template>
        <template v-else-if="header === 'extended'">
          <Menu as="div" class="relative inline-block text-center">
            <MenuButton
              class="rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-yellow-300"
            >
              <span class="sr-only">Open options</span>
              <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
            </MenuButton>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-2 ring-gray/5 focus:outline-none"
              >
                <div class="py-1">
                  <MenuItem v-slot="{ active }">
                    <a
                      href="#"
                      @click.prevent="$emit('deleteTimelapseVideo', entry)"
                      :class="[
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm',
                      ]"
                      >{{ $t("camera.customizable_timelapse.table.extended_remove") }}</a
                    >
                  </MenuItem>
                  <div v-if="entry.url">
                    <MenuItem v-slot="{ active }">
                      <a
                        :href="entry.url"
                        :class="[
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                        ]"
                        >{{ $t("camera.customizable_timelapse.table.extended_download") }}</a
                      >
                    </MenuItem>
                  </div>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </template>
        <template v-else>
          {{ entry[header as keyof CustomizableTimelapse] }}
          <span v-if="header === 'duration'">{{
            $t("camera.customizable_timelapse.table.duration_min")
          }}</span>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { EllipsisVerticalIcon, PlayIcon, XCircleIcon } from "@heroicons/vue/24/solid";
import { format } from "date-fns";
import { PropType, ref } from "vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { CustomizableTimelapse } from "shared/types/CustomizableTimelapse";
import { useStreams } from "@/composables/stream";

defineProps({
  timelapseVideos: {
    type: Array as PropType<CustomizableTimelapse[]>,
  },
});
defineEmits(["playVideo", "deleteTimelapseVideo"]);

const headers = ref<Array<string>>([
  "name",
  "camera",
  "start_date",
  "end_date",
  "duration",
  "created",
  "play",
  "extended",
]);

const { streams } = useStreams();

const formatDate = (date: Date): string => {
  return format(date, "dd.MM.yyyy");
};
const formatDateWithTime = (date: Date): string => {
  return format(date, "dd.MM.yyyy HH:mm:ss");
};
</script>
