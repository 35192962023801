<template>
  <div>
    <div :class="{ 'sm:grid sm:grid-cols-3 sm:gap-x-4 sm:items-center': inlineField }">
      <label v-if="label" class="block text-sm font-medium leading-6 text-gray-900">{{
        label
      }}</label>
      <div
        :class="[
          'relative mt-2 sm:mt-0 rounded-md shadow-sm',
          { 'sm:col-span-2 max-w-lg sm:max-w-xs': inlineField },
        ]"
      >
        <OaiCombobox
          :options="options"
          :onUpdate:selected="handleChange"
          :selected="value"
          :readonly="readonly"
          :minWidth="minWidth"
          :placeholder="placeholder"
          :inputFieldClass="
            [
              'relative outline-none block w-full text-left rounded-md border-0 py-1.5 pl-3 ring-1 ring-inset focus:ring-2 focus:ring-inset text-sm sm:leading-6',
              errorMessage
                ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500 pr-3'
                : 'text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-orange-300 pr-3',
            ].join(' ')
          "
        />
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
          v-if="errorMessage"
        >
          <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
      </div>
    </div>

    <div
      v-if="errorMessage"
      class="text-xs mt-1 text-red-600 sm:col-span-2 sm:items-end sm:col-start-2"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ExclamationCircleIcon } from "@heroicons/vue/24/solid";
import { useField, useFieldError } from "vee-validate";
import { PropType } from "vue";
import OaiCombobox from "shared/components/other/OaiCombobox.vue";

const props = defineProps({
  name: {
    type: String as PropType<string>,
    required: true,
  },
  inlineField: {
    type: Boolean as PropType<boolean>,
    required: false,
  },
  options: {
    type: Array as PropType<{ value: unknown; name: string }[]>,
    required: true,
  },
  label: {
    type: String,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "",
  },
  minWidth: {
    type: Number,
    required: false,
    default: 180,
  },
});

const errorMessage = useFieldError(props.name);
const { value, setValue } = useField<string>(props.name);

const handleChange = (newValue: string) => {
  setValue(newValue);
};
</script>
