<template>
  <div class="flex min-h-screen flex-col justify-center bg-gray-50 py-8 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" :src="logoMultipleNoPaddingSrc" alt="Workflow" />
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
        <Form
          @submit="handleSubmitSignUp"
          :initialValues="initialValues"
          :validationSchema="signUpSchema"
          class="flex flex-col gap-2 w-full"
        >
          <InputField
            v-if="showEmailAndCodeField"
            name="email"
            :label="$t('authentication.sign_up.email')"
          />
          <InputField name="firstName" :label="$t('authentication.sign_up.first_name')" />
          <InputField name="lastName" :label="$t('authentication.sign_up.last_name')" />
          <InputField name="company" :label="$t('authentication.sign_up.company')" />
          <SelectListField
            name="role"
            :label="$t('authentication.sign_up.your_role')"
            :options="roleTypes"
            :minWidth="250"
            :placeholder="`--
              ${$t('form.select_option')} --`"
          />
          <PasswordField
            v-if="showEmailAndCodeField"
            name="passwordTemporary"
            :label="$t('authentication.sign_up.temporary_password')"
          />
          <PasswordField name="password1" :label="$t('authentication.sign_up.new_password')" />
          <PasswordField
            name="password2"
            :label="$t('authentication.sign_up.repeat_new_password')"
          />

          <div class="rounded-md bg-red-50 p-4" v-show="invalidInputAlert">
            <div class="flex">
              <div class="shrink-0">
                <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">
                  {{ errorMessageInvalidInput }}
                </h3>
              </div>
            </div>
          </div>

          <div class="rounded-md bg-yellow-100 p-4" v-show="userExistsAlert">
            <div class="flex">
              <div class="shrink-0">
                <ExclamationTriangleIcon class="h-5 w-5 text-yellow-400" aria-hidden="true" />
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-yellow-800">Der Benutzer existiert bereits</h3>
                <div class="mt-2 text-sm text-yellow-700">
                  <p>
                    Bitte logge dich über die
                    <u>
                      <router-link to="/log-in">Login-Seite</router-link>
                    </u>
                    ein.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center text-sm">
            {{ $t("authentication.sign_up.terms_conditions_1") }}
            <a href="https://www.oculai.de/agb"
              ><span class="text-yellow-800 font-medium">
                {{ $t("authentication.sign_up.terms_conditions_2") }}
              </span></a
            >
            {{ $t("authentication.sign_up.terms_conditions_3") }}
          </div>

          <div class="pt-4 flex items-center justify-center">
            <button
              type="submit"
              :disabled="isLoading"
              class="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-yellow-600 px-20 py-2 text-base font-medium text-white shadow-sm hover:bg-yellow-600 focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
            >
              <div v-if="isLoading">
                <LoadingSpinner v-if="isLoading" size="w-5 h-5" color="white" />
              </div>
              <div v-else>Sign Up</div>
            </button>
          </div>
        </Form>

        <div
          class="flex items-center gap-3 text-sm text-gray-400 my-6"
          v-if="(props.socialLogins?.length ?? 0) > 0"
        >
          <div class="border-t flex-1" />
          {{ $t("authentication.login.or") }}
          <div class="border-t flex-1" />
        </div>

        <div class="rounded-md bg-red-50 p-4 mb-6" v-if="socialAlert">
          <div class="flex">
            <div class="shrink-0">
              <XCircleIcon class="h-5 w-5 text-red-400" aria-hidden="true" />
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">
                {{ $t("authentication.sign_up.social_error") }}
              </h3>
            </div>
          </div>
        </div>

        <div v-for="(socialLogin, index) in props.socialLogins" :key="`social-login-${index}`">
          <button
            class="border mt-4 px-5 py-2 rounded-lg flex gap-3 w-full justify-center"
            type="button"
            style="font-family: Montserrat-SemiBold, system-ui"
            @click="() => triggerSocialLogin(socialLogin.handleLogin)"
          >
            <img
              :alt="`company logo for ${socialLogin.label}`"
              :src="socialLogin.logoData"
            /><span>{{ socialLogin.label }}</span>
          </button>
        </div>

        <div class="pt-4 text-center text-sm text-yellow-900">
          <router-link to="/log-in" class="font-sm hover:text-yellow-700">
            {{ $t("authentication.sign_up.already_registered") }}
          </router-link>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script lang="ts" setup>
import { XCircleIcon, ExclamationTriangleIcon } from "@heroicons/vue/24/solid";
import { Auth } from "aws-amplify";
import { Form, SubmissionHandler } from "vee-validate";
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import * as yup from "yup";
import logoMultipleNoPaddingSrc from "shared/assets/imgs/logo/logo-multiple-no-padding.svg";
import InputField from "shared/components/forms/InputField.vue";
import PasswordField from "shared/components/forms/PasswordField.vue";
import SelectListField from "shared/components/forms/SelectListField.vue";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import { userRoles } from "shared/constants/user";
import UserRepository from "shared/repositories/UserRepository";
import logger from "shared/services/logger";
import Footer from "shared/views/auth/components/Footer.vue";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();

const props = defineProps<{
  socialLogins?: {
    label: string;
    logoData: string;
    handleLogin: (email: string) => Promise<void>;
  }[];
  hideFooter?: boolean;
}>();

const roleTypes = userRoles.map((role) => ({
  value: role,
  name: t(`admin.project_participants.user_roles.${role}`),
}));

const errorMessageInvalidInput = ref("");
const userExistsAlert = ref(false);
const invalidInputAlert = ref(false);
const isLoading = ref(false);
const socialAlert = ref(false);

const initialValues = computed(() => ({
  email: (route.query.email as string) || "",
  firstName: "",
  lastName: "",
  company: "",
  role: "",
  passwordTemporary: atob((route.query.code as string) || ""),
  password1: "",
  password2: "",
}));

const signUpSchema = yup.object().shape({
  email: yup
    .string()
    .email(t("authentication.validation.validation_email_invalid"))
    .required(t("authentication.validation.validation_field_required")),
  firstName: yup.string().required(t("authentication.validation.validation_field_required")),
  lastName: yup.string().required(t("authentication.validation.validation_field_required")),
  company: yup.string().required(t("authentication.validation.validation_field_required")),
  role: yup.string().required(t("authentication.validation.validation_field_required")),
  passwordTemporary: yup
    .string()
    .required(t("authentication.validation.validation_field_required")),
  password1: yup
    .string()
    .min(10, t("authentication.validation.validation_password_characters"))
    .required(t("authentication.validation.validation_field_required")),
  password2: yup
    .string()
    .oneOf([yup.ref("password1")], t("authentication.validation.validation_password_not_equal"))
    .required(t("authentication.validation.validation_field_required")),
});

const showEmailAndCodeField = computed(() => {
  return !(route.query.email as string) || !(route.query.code as string);
});

const getCleanedName = (name: string) => {
  // Remove whitespaces and special characters.
  return name.trim().replace(/[&/\\#,+()$~%.'":*?<>{}]/g, "");
};

const handleSubmitSignUp: SubmissionHandler = async (genericObject) => {
  isLoading.value = true;
  invalidInputAlert.value = false;
  userExistsAlert.value = false;

  try {
    const { email, firstName, lastName, company, passwordTemporary, password1, role } =
      genericObject;
    const name = `${getCleanedName(firstName)} ${getCleanedName(lastName)}`;

    const user = await Auth.signIn(email, passwordTemporary);

    const requiredAttributes = {
      name: name,
      "custom:company": company,
    };

    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      try {
        await Auth.completeNewPassword(user, password1, requiredAttributes);
        await UserRepository.completeSignUp(email, name, company, role);
        router.push("/log-in");
      } catch (e) {
        const error = e as Error;
        handleSignUpError(error);
      }
    } else {
      userExistsAlert.value = true;
    }
  } catch (e) {
    const error = e as Error;
    if (error.message === "Incorrect username or password.") {
      errorMessageInvalidInput.value = t("authentication.sign_up.error_mail_password");
      invalidInputAlert.value = true;
    } else {
      logger.error("Unable to sign up", error);
      invalidInputAlert.value = true;
    }
  } finally {
    isLoading.value = false;
  }
};

const handleSignUpError = (e: Error) => {
  switch (e.message) {
    case "Username should be either an email or a phone number.":
      errorMessageInvalidInput.value = t("authentication.sign_up.error_must_be_mail");
      break;
    case "User is not confirmed.":
      errorMessageInvalidInput.value = t("authentication.sign_up.error_user_not_confirmed");
      break;
    case "Incorrect username or password.":
      errorMessageInvalidInput.value = t("authentication.sign_up.error_mail_password");
      break;
    case "User does not exist.":
      errorMessageInvalidInput.value = t("authentication.sign_up.error_user_not_existing");
      break;
    default:
      errorMessageInvalidInput.value = t("authentication.sign_up.error_unknown");
  }
  invalidInputAlert.value = true;
};

const triggerSocialLogin = async (handleLogin: (email: string) => Promise<void>) => {
  isLoading.value = true;
  errorMessageInvalidInput.value = "";
  userExistsAlert.value = false;
  invalidInputAlert.value = false;
  socialAlert.value = false;

  try {
    await handleLogin(route.query.email as string);
    router.push("/");
  } catch (e) {
    socialAlert.value = true;
  } finally {
    isLoading.value = false;
  }
};
</script>
