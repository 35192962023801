<template>
  <div class="flex flex-col" :class="hideBorderBottom ? '' : 'border-b'">
    <div
      :key="project._id"
      v-for="(project, index) in projectsForUser"
      class="grid items-center gap-4 py-2 pr-4 hover:bg-yellow-100"
      :class="index === projectsForUser.length - 1 ? '' : 'border-b'"
      :style="{
        gridTemplateColumns: '1fr 4fr 40px',
        paddingLeft: `${calculateIndentation(indentation)}px`,
      }"
    >
      <OaiTooltip cls="font-semibold truncate text-xs">
        {{ project.name
        }}<span class="font-normal" v-if="isUserOrganizationAdmin">
          ({{ projectChildOrganizationNames[getProjectKey(project)] }})</span
        >
        <template #tooltip>
          <div>
            {{ project.name
            }}<span v-if="isUserOrganizationAdmin"
              >({{ projectChildOrganizationNames[getProjectKey(project)] }})</span
            >
          </div>
        </template>
      </OaiTooltip>
      <div
        class="flex flex-wrap items-center whitespace-nowrap gap-1 px-3 text-sm text-gray-500 min-w-0"
      >
        <ProjectPermissionBadge
          v-for="permission in sortedProjectPermissionGroupsByProject[getProjectKey(project)]"
          :key="permission"
          :projectPermissionGroup="permission"
        />
      </div>
      <div class="flex gap-2 justify-end">
        <PencilIcon
          v-if="!isUserOrganizationAdmin"
          class="w-4 h-4 shrink-0 text-gray-400 cursor-pointer hover:text-gray-500"
          @click.stop="editProjectPermissionModalProject = project"
        />
        <TrashIcon
          v-if="!isUserOrganizationAdmin"
          class="w-4 h-4 shrink-0 text-gray-400 cursor-pointer hover:text-gray-500"
          @click.stop="deleteUserModalProject = project"
        />
      </div>
    </div>
    <OrganizationTabUsersAddToProject
      v-if="!isUserOrganizationAdmin"
      :user="user"
      :organization="organization"
      :indentation="indentation"
      :projectsForUser="projectsForUser"
      :projectsByKey="projectsByKey"
    />
    <div
      v-if="isUserOrganizationAdmin"
      class="px-2 text-gray-400 text-xs py-1 h-[50px] flex items-center"
      :class="projectsForUser.length > 0 ? 'border-t' : ''"
      :style="{
        paddingLeft: `${calculateIndentation(indentation)}px`,
      }"
    >
      <span class="truncate">{{ t("organizations.admin_access_to_all_projects") }}</span>
    </div>
  </div>
  <ProjectProvider
    v-if="editProjectPermissionModalProject"
    :customerName="editProjectPermissionModalProject.customer_name"
    :siteId="editProjectPermissionModalProject.site_id"
  >
    <EditProjectPermissionModal
      :user="user"
      @close="editProjectPermissionModalProject = null"
      :hideCompanyType="true"
    />
  </ProjectProvider>
  <ProjectProvider
    v-if="deleteUserModalProject"
    :customerName="deleteUserModalProject.customer_name"
    :siteId="deleteUserModalProject.site_id"
  >
    <RemoveUserModal
      v-if="deleteUserModalProject"
      :user="user"
      @close="deleteUserModalProject = null"
    />
  </ProjectProvider>
</template>

<script lang="ts" setup>
import { PencilIcon, TrashIcon } from "@heroicons/vue/24/solid";
import { computed, defineProps, ref } from "vue";
import { useI18n } from "vue-i18n";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import ProjectProvider from "shared/components/other/ProjectProvider.vue";
import { Organization } from "shared/types/Organization";
import { Project } from "shared/types/Project";
import { User, UserProjectPermissionGroup } from "shared/types/User";
import EditProjectPermissionModal from "@/components/users/EditProjectPermissionModal.vue";
import ProjectPermissionBadge from "@/components/users/ProjectPermissionBadge.vue";
import RemoveUserModal from "@/components/users/RemoveUserModal.vue";
import { useGetSortedProjectPermissionGroups } from "@/composables/user";
import OrganizationTabUsersAddToProject from "@/views/organizations/components/OrganizationTabUsersAddToProject.vue";
import { calculateIndentation } from "@/views/organizations/organizations";

const props = withDefaults(
  defineProps<{
    user: User;
    organization: Organization;
    hideBorderBottom?: boolean;
    indentation?: number;
    projectsByKey: Record<string, Project>;
    projectsForUser: Project[];
    isUserOrganizationAdmin: boolean;
    organizationsByParentId: Record<string, Organization[] | undefined>;
  }>(),
  {
    indentation: 0,
  },
);

const { t } = useI18n();

const editProjectPermissionModalProject = ref<Project | null>(null);
const deleteUserModalProject = ref<Project | null>(null);

const getSortedProjectPermissionGroups = useGetSortedProjectPermissionGroups();

const getProjectKey = (project: Pick<Project, "customer_name" | "site_id">) =>
  `${project.customer_name}_${project.site_id}`;

const getGroupsForAdmin = (groups: UserProjectPermissionGroup[]) => {
  if (groups.includes("all_camera_features_user")) {
    return groups.filter((group) => group !== "live_camera_feature_user");
  }
  if (groups.includes("live_camera_feature_user")) {
    return groups.filter((group) => group !== "all_camera_features_user");
  }
  return groups;
};

const sortedProjectPermissionGroupsByProject = computed(() =>
  props.user.projects.reduce((acc, project) => {
    const key = getProjectKey(project);
    const groups = props.isUserOrganizationAdmin
      ? getGroupsForAdmin(project.groups)
      : project.explicit_groups;
    acc[key] = getSortedProjectPermissionGroups(groups.filter((group) => group !== "project_base"));
    return acc;
  }, {} as Record<string, UserProjectPermissionGroup[]>),
);

const traverseAndBuildProjectsMap = (
  organizations: Organization[],
  result: Record<string, Organization[]>,
) => {
  for (const organization of organizations) {
    for (const project of organization.explicit_projects) {
      const key = getProjectKey(project);
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(organization);
    }
    const children = props.organizationsByParentId[organization._id] || [];
    traverseAndBuildProjectsMap(children, result);
  }
};

const projectChildOrganizationNames = computed(() => {
  const result: Record<string, Organization[]> = {};
  traverseAndBuildProjectsMap([props.organization], result);
  return Object.fromEntries(
    Object.entries(result).map(([key, organizations]) => {
      const names = organizations
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((organization) => organization.name)
        .join(", ");
      return [key, names];
    }),
  );
});
</script>
