<template>
  <div class="w-full flex justify-center items-center h-full" v-if="arePublicTokensLoading">
    <LoadingSpinner />
  </div>
  <div v-else-if="publicTokens" class="flex flex-col gap-12">
    <div class="flex flex-col gap-4">
      <h1 class="text-xl font-bold text-gray-900 px-1 flex items-center gap-2">
        {{ t("admin.project_settings.tab_public_tokens") }}
        <OaiTooltip cls="shrink-0">
          <InformationCircleIcon class="w-5 h-5 cursor-pointer text-gray-600" />
          <template #tooltip>
            <div class="text-xs max-w-[300px]">
              {{ t("public_tokens.tab_tooltip") }}
            </div>
          </template>
        </OaiTooltip>
      </h1>
      <table class="min-w-full divide-y divide-gray-300 border">
        <thead class="bg-gray-50">
          <tr>
            <th
              scope="col"
              class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              {{ t("public_tokens.page") }}
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {{ t("public_tokens.published_by") }}
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {{ t("public_tokens.published_date") }}
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
              {{ t("public_tokens.expires") }}
            </th>
            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900" />
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-200 bg-white">
          <tr v-for="publicToken in publicTokens" :key="publicToken._id">
            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
              <RouterLink :to="publicTokenService.createOriginRouteLocation(publicToken)">{{
                getPublicTokenName(publicToken)
              }}</RouterLink>
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{ publicToken.created_by }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {{ formatDate(publicToken.created) }}
            </td>
            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <PublicTokenExpirationBadge :publicToken="publicToken" />
            </td>
            <td class="whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
              <div class="flex gap-1 justify-end">
                <DocumentDuplicateIcon
                  class="w-5 h-5 text-gray-600 cursor-pointer shrink-0"
                  @click="copyPublicTokenLink(publicToken)"
                />
                <button
                  class="ml-2 truncate min-w-0 rounded px-2 py-1 text-xs border shadow-sm"
                  :class="
                    isDeletePublicTokenLoading
                      ? 'bg-gray-100 text-gray-400'
                      : 'cursor-pointer bg-white text-gray-600 hover:bg-gray-100'
                  "
                  @click="handleDeleteTokenClick(publicToken)"
                >
                  {{ t("public_tokens.unpublish") }}
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="publicTokens.length === 0">
            <td colspan="5" class="py-3.5 pl-4 pr-3 sm:pl-6 text-sm text-gray-600">
              {{ t("public_tokens.no_links_found") }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { DocumentDuplicateIcon, InformationCircleIcon } from "@heroicons/vue/24/outline";
import { format } from "date-fns";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import OaiTooltip from "shared/components/other/OaiTooltip.vue";
import { useConfirmationModal } from "shared/composables/toast";
import publicTokenService from "shared/services/publicTokenService";
import { PublicToken } from "shared/types/PublicToken";
import PublicTokenExpirationBadge from "@/components/public_token/PublicTokenExpirationBadge.vue";
import {
  useCopyPublicTokenLink,
  useDeletePublicToken,
  useGetPublicTokenName,
  usePublicTokens,
} from "@/composables/publicToken";

const { t } = useI18n();

const { publicTokens, arePublicTokensLoading } = usePublicTokens();
const showConfirmationModal = useConfirmationModal();
const { deletePublicToken, isLoading: isDeletePublicTokenLoading } = useDeletePublicToken();
const getPublicTokenName = useGetPublicTokenName();
const copyPublicTokenLink = useCopyPublicTokenLink();

const formatDate = (date: Date) => format(date, "dd.MM.yyyy HH:mm");

const handleDeleteTokenClick = (publicToken: PublicToken) => {
  if (isDeletePublicTokenLoading.value) {
    return;
  }
  showConfirmationModal({
    confirmAction: t("public_tokens.delete_confirmation.confirm"),
    cancelAction: t("public_tokens.delete_confirmation.cancel"),
    message: t("public_tokens.delete_confirmation.message", {
      name: getPublicTokenName(publicToken),
    }),
    header: t("public_tokens.delete_confirmation.header"),
  }).then((confirmed) => {
    if (confirmed) {
      deletePublicToken(publicToken._id).catch(() => undefined);
    }
  });
};
</script>
