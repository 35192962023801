<template>
  <Combobox
    as="div"
    v-model="model"
    :style="{ minWidth: `${minWidth}px` }"
    :disabled="readonly"
    :multiple="multiple"
    @update:modelValue="emits('update:selected', $event)"
  >
    <ComboboxLabel class="text-sm" v-if="label">{{ label }}</ComboboxLabel>
    <div class="relative">
      <ComboboxInput
        :class="[inputFieldClass, readonly ? 'bg-gray-50 cursor-default' : 'bg-white']"
        @change="query = $event.target.value"
        autocomplete="one-time-code"
        :displayValue="(value: unknown) => options.find((option) => option.value === value)?.name as string || query"
        :placeholder="placeholder"
      />
      <ComboboxButton
        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
      >
        <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>
      <ComboboxOptions
        v-if="filteredOptions.length > 0 || noOptionsText"
        class="absolute z-50 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-yellow-500/5 focus:outline-none sm:text-sm w-full"
      >
        <ComboboxOption
          v-for="option in filteredOptions"
          :key="option.value as string"
          :value="option.value as string"
          as="template"
          v-slot="{ active, selected }"
        >
          <li
            :class="[
              (active || selected) && 'bg-yellow-100',
              'relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900',
              optionClass,
            ]"
          >
            <span :class="['block truncate', selected && 'font-semibold']">
              {{ option.name }}
            </span>
          </li>
        </ComboboxOption>
        <div
          v-if="filteredOptions.length === 0 && noOptionsText"
          class="px-2 py-1"
          :class="noOptionsClass"
        >
          {{ noOptionsText }}
        </div>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script setup lang="ts">
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  ComboboxLabel,
} from "@headlessui/vue";
import { ChevronUpDownIcon } from "@heroicons/vue/24/outline";
import { ref, PropType, computed, onMounted } from "vue";

const props = defineProps({
  options: {
    type: Array as PropType<{ value: unknown; name: string }[]>,
    required: true,
  },
  selected: {
    type: [String, Number, Object, Array] as unknown as PropType<unknown>,
    default: "",
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
  },
  placeholder: {
    type: String,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  minWidth: {
    type: Number,
    required: false,
    default: 180,
  },
  inputFieldClass: {
    type: String,
    default:
      "w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-orange-300 sm:text-sm sm:leading-6",
  },
  optionClass: {
    type: String,
    required: false,
  },
  noOptionsText: {
    type: String,
    required: false,
  },
  noOptionsClass: {
    type: String,
    required: false,
  },
});

const emits = defineEmits(["update:selected"]);
const query = ref("");

const model = defineModel<
  | string
  | number
  | boolean
  | object
  | null
  | undefined
  | (string | number | boolean | object | null | undefined)[]
>();

onMounted(() => {
  if (props.selected) {
    model.value = props.selected;
  }
});

const filteredOptions = computed(() =>
  query.value
    ? props.options.filter((option) =>
        option.name.toLowerCase().includes(query.value.toLowerCase()),
      )
    : props.options,
);
</script>
