<template>
  <div
    v-if="shouldShow"
    class="px-2 py-2 text-gray-400 text-xs flex gap-2 items-center h-[50px]"
    :style="{ paddingLeft: `${calculateIndentation(indentation)}px` }"
  >
    <OaiCombobox
      :key="selectedProjectKey"
      :options="addProjectOptions"
      :selected="selectedProjectKey"
      :onUpdate:selected="handleAddToProjectOptionsChange"
      :minWidth="0"
      :readonly="isInviteLoading"
      :placeholder="t('organizations.add_to_project')"
      optionClass="text-xs"
      inputFieldClass="sm:w-[250px] truncate relative outline-none block text-left rounded-md border-0 py-0.5 pl-3 ring-1 ring-inset focus:ring-2 focus:ring-inset text-xs sm:leading-6 text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-orange-300 pr-6"
      :noOptionsText="t('organizations.no_projects')"
      noOptionsClass="text-xs"
    />
    <LoadingSpinner class="w-5 h-5" v-if="isInviteLoading" />
  </div>
  <div
    v-if="projectsForUser.length === 0 && !shouldShow"
    class="px-2 py-2 text-gray-400 text-xs h-[50px] flex items-center"
    :style="{ paddingLeft: `${calculateIndentation(indentation)}px` }"
  >
    <span class="truncate">{{ t("organizations.no_projects") }}</span>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import LoadingSpinner from "shared/components/loading_state/LoadingSpinner.vue";
import OaiCombobox from "shared/components/other/OaiCombobox.vue";
import { useHasPermission } from "shared/composables/project";
import { Organization } from "shared/types/Organization";
import { Project } from "shared/types/Project";
import { InviteUser, User } from "shared/types/User";
import { useBulkInviteUsersToProject } from "@/composables/user";
import { calculateIndentation } from "@/views/organizations/organizations";

const props = withDefaults(
  defineProps<{
    user: User;
    organization: Organization;
    projectsForUser: Project[];
    projectsByKey: Record<string, Project>;
    indentation?: number;
  }>(),
  { indentation: 0 },
);

const { t } = useI18n();

const selectedProjectKey = ref<string>("");

const hasAdminPermission = useHasPermission("app_admin");

const selectedProject = computed(() =>
  selectedProjectKey.value ? props.projectsByKey[selectedProjectKey.value] : null,
);

const { bulkInviteUsersToProject, isInviteLoading } = useBulkInviteUsersToProject(selectedProject);

const getProjectKey = (project: Pick<Project, "customer_name" | "site_id">) =>
  `${project.customer_name}_${project.site_id}`;

const addProjectOptions = computed(() => {
  const currentUserProjectKeys = new Set(
    props.projectsForUser.map((project) => getProjectKey(project)),
  );
  const projects = props.organization.projects
    .filter(
      (organizationProject) => !currentUserProjectKeys.has(getProjectKey(organizationProject)),
    )
    .map((organizationProject) => props.projectsByKey[getProjectKey(organizationProject)])
    .filter((project) => project) as Project[];
  return projects.map((project) => ({
    value: getProjectKey(project),
    name: hasAdminPermission
      ? `${project.name.trim()} (${project.customer_name}/${project.site_id})`
      : project.name,
  }));
});

const companyType = computed(() => {
  const userOrganization = props.user.organizations.find(
    (organization) => organization.organization_id === props.organization._id,
  );
  return userOrganization?.company_type ?? null;
});

const handleAddToProjectOptionsChange = async (newValue: string) => {
  selectedProjectKey.value = newValue;

  if (!selectedProjectKey.value || !companyType.value || isInviteLoading.value) {
    return;
  }
  const usersToInvite: InviteUser[] = [
    { email: props.user.email, company_type: companyType.value },
  ];
  await bulkInviteUsersToProject(usersToInvite)
    .catch(() => undefined)
    .finally(() => {
      selectedProjectKey.value = "";
    });
};

const shouldShow = computed(() => companyType.value);
</script>
