<template>
  <Disclosure v-slot="{ open }" :defaultOpen="false">
    <DisclosureButton
      class="grid gap-4 text-left items-center px-4 w-full relative h-[50px] disclosureButton hover:bg-yellow-100"
      :class="[
        open || !hideBorderBottom ? 'border-b' : 'border-b border-b-transparent',
        hasOrganizationAdminPermission ? 'isOrganizationAdmin' : '',
      ]"
      :disabled="!hasOrganizationAdminPermission"
      :style="{
        paddingLeft: `${calculateIndentation(indentation)}px`,
      }"
    >
      <div
        v-if="companyType && showCompanyTypeBadge"
        class="absolute -top-[9px] left-0 text-[10px] font-semibold bg-white rounded px-2 border truncate"
        :style="{ marginLeft: `${calculateIndentation(indentation)}px` }"
      >
        {{ t(`admin.project_participants.company_types.${companyType}`) }}
      </div>
      <div
        v-if="user.user_status !== 'confirmed'"
        class="inline-flex items-center gap-x-1.5 rounded-full bg-orange-100 px-2 py-1 text-xs font-medium text-orange-600 max-w-max min-w-0"
      >
        <svg class="h-1.5 w-1.5 fill-orange-400" viewBox="0 0 6 6" aria-hidden="true">
          <circle cx="3" cy="3" r="3" />
        </svg>
        <span class="truncate">
          {{ t("admin.project_participants.pending_invite") }}
        </span>
      </div>
      <div v-if="user.user_status === 'confirmed'" class="flex gap-3 items-center min-w-0">
        <div class="truncate">{{ user.name || "&lt;unknown&gt;" }}</div>
      </div>
      <div class="text-gray-500 text-xs truncate">{{ user.email }}</div>
      <div class="text-gray-500 text-xs truncate">
        {{ props.user.role ? t(`admin.project_participants.user_roles.${props.user.role}`) : "" }}
      </div>
      <div class="flex items-center gap-1 min-w-0" v-if="hasOrganizationAdminPermission">
        <div class="text-gray-500 text-xs truncate">
          {{ t("organizations.projects") }} ({{ projectsForUser.length }})
        </div>
        <ChevronRightIcon
          :class="{ 'rotate-90': open, invisible: !open }"
          class="w-4 h-4 shrink-0 text-gray-500 chevronIcon"
        />
      </div>
      <div class="flex items-center gap-1 min-w-0">
        <div
          v-for="permission in userGroups"
          :key="permission"
          class="rounded-full px-2 text-xs font-semibold truncate"
          :class="permissionLabelsColors[permission] ?? 'bg-gray-300 text-gray-800'"
        >
          {{ t(`permissions.${permission}`) }}
        </div>
      </div>
      <div class="flex gap-2 justify-end">
        <PencilIcon
          v-if="hasOrganizationAdminPermission"
          class="w-4 h-4 shrink-0 text-gray-400 cursor-pointer hover:text-gray-500"
          @click.stop="isEditOrganizationPermissionModalOpen = true"
        />
        <TrashIcon
          v-if="hasOrganizationAdminPermission"
          class="w-4 h-4 shrink-0 text-gray-400 cursor-pointer hover:text-gray-500"
          @click.stop="isDeleteUserModalOpen = true"
        />
      </div>
    </DisclosureButton>
    <DisclosurePanel>
      <OrganizationTabUsersProjectPermissions
        :user="user"
        :organization="organization"
        :hideBorderBottom="hideBorderBottom"
        :indentation="indentation + 1"
        :projectsByKey="projectsByKey"
        :projectsForUser="projectsForUser"
        :isUserOrganizationAdmin="isUserOrganizationAdmin"
        :organizationsByParentId="organizationsByParentId"
      />
    </DisclosurePanel>
  </Disclosure>
  <RemoveUserModal
    v-if="isDeleteUserModalOpen"
    :user="user"
    :organizationId="organization._id"
    @close="isDeleteUserModalOpen = false"
  />
  <EditOrganizationPermissionModal
    v-if="isEditOrganizationPermissionModalOpen"
    :user="user"
    :organizationId="organization._id"
    @close="isEditOrganizationPermissionModalOpen = false"
  />
</template>

<script lang="ts" setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { TrashIcon, PencilIcon } from "@heroicons/vue/24/solid";
import { computed, defineProps, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useHasOrganizationPermission } from "shared/composables/organization";
import { Organization } from "shared/types/Organization";
import { Project } from "shared/types/Project";
import { User, UserOrganizationPermissionGroup } from "shared/types/User";
import EditOrganizationPermissionModal from "@/components/users/EditOrganizationPermissionModal.vue";
import RemoveUserModal from "@/components/users/RemoveUserModal.vue";
import OrganizationTabUsersProjectPermissions from "@/views/organizations/components/OrganizationTabUsersProjectPermissions.vue";
import { calculateIndentation } from "@/views/organizations/organizations";

const permissionLabelsColors: Partial<Record<UserOrganizationPermissionGroup, string>> = {
  organization_admin: "bg-green-100 text-green-800",
};

const permissionSortOrder: Record<UserOrganizationPermissionGroup, number> = {
  organization_base: 1,
  organization_admin: 2,
};

const props = withDefaults(
  defineProps<{
    user: User;
    organization: Organization;
    showCompanyTypeBadge: boolean;
    indentation?: number;
    hideBorderBottom?: boolean;
    organizationsByParentId: Record<string, Organization[] | undefined>;
  }>(),
  { indentation: 0 },
);

const { t } = useI18n();

const isDeleteUserModalOpen = ref(false);
const isEditOrganizationPermissionModalOpen = ref(false);

const store = useStore();

const hasOrganizationAdminPermission = useHasOrganizationPermission(
  "organization_admin",
  props.organization._id,
);

const userGroups = computed(() => {
  const userOrganization = props.user.organizations.find(
    (user) => user.organization_id === props.organization._id,
  );
  if (!userOrganization) {
    return [];
  }
  const isOrganizationAdmin = userOrganization.explicit_groups.includes("organization_admin");
  return userOrganization.explicit_groups
    .filter(
      (group) => !isOrganizationAdmin || (isOrganizationAdmin && group !== "organization_base"),
    )
    .sort((a, b) => permissionSortOrder[a] - permissionSortOrder[b]);
});

const companyType = computed(
  () =>
    props.user.organizations.find(
      (organization) => organization.organization_id === props.organization._id,
    )?.company_type,
);

const isUserOrganizationAdmin = computed(() => userGroups.value.includes("organization_admin"));

const getProjectKey = (project: Pick<Project, "customer_name" | "site_id">) =>
  `${project.customer_name}_${project.site_id}`;

const projectsByKey = computed(() => {
  const projects = (store.state.projects || []) as Project[];
  return projects.reduce((acc, project) => {
    const key = getProjectKey(project);
    acc[key] = project;
    return acc;
  }, {} as Record<string, Project>);
});

const projectsForUser = computed(() => {
  const organizationProjectKeys = new Set(
    props.organization.projects.map((project) => getProjectKey(project)),
  );
  return props.user.projects
    .filter((userProject) => {
      const key = getProjectKey(userProject);
      return (
        projectsByKey.value[key] &&
        (isUserOrganizationAdmin.value
          ? userProject.groups.length > 0
          : userProject.explicit_groups.length > 0) &&
        organizationProjectKeys.has(key)
      );
    })
    .map((userProject) => projectsByKey.value[getProjectKey(userProject)]);
});
</script>

<style scoped>
.disclosureButton {
  grid-template-columns: 2fr 2fr 2fr 1fr 40px;
}

.disclosureButton.isOrganizationAdmin {
  grid-template-columns: 2fr 2fr 2fr 1fr 1fr 40px;
}

@media (min-width: 1024px) {
  .disclosureButton {
    grid-template-columns: 1fr 1fr 1fr 70px 40px;
  }

  .disclosureButton.isOrganizationAdmin {
    grid-template-columns: 1fr 1fr 1fr 90px 70px 40px;
  }
}

.disclosureButton:hover .chevronIcon {
  visibility: visible;
}
</style>
