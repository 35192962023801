<template>
  <Listbox as="div" class="relative" :modelValue="currentValue" :disabled="isLoading">
    <ListboxButton
      class="flex gap-1 items-center min-w-0"
      :class="isLoading ? 'text-gray-300' : 'text-gray-900'"
    >
      <span class="truncate">{{ t(`public_tokens.live_options.${currentValue}`) }}</span>
      <ChevronDownIcon class="h-4 w-4" />
    </ListboxButton>
    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <ListboxOptions
        class="min-w-[100px] absolute z-50 mt-1 rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-yellow-500/5 focus:outline-none sm:text-sm"
      >
        <ListboxOption
          as="template"
          v-for="option in liveCameraOptions"
          :key="option.value"
          :value="option.value"
          v-slot="{ active, selected }"
        >
          <li
            class="relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 text-xs truncate"
            :class="(active || selected) && 'bg-yellow-200'"
            @click="handleOptionChange(option.value)"
          >
            {{ option.name }}
          </li>
        </ListboxOption>
      </ListboxOptions>
    </transition>
  </Listbox>
</template>

<script setup lang="ts">
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/24/outline";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { PublicToken, PublicTokenToUpdate } from "shared/types/PublicToken";
import { useUpdatePublicToken } from "@/composables/publicToken";

type LiveCameraOption = "live_video" | "live_image";

const props = defineProps<{ publicToken: PublicToken }>();

const { t } = useI18n();
const { updatePublicToken, isLoading } = useUpdatePublicToken();

const liveCameraOptionList: LiveCameraOption[] = ["live_video", "live_image"];

const liveCameraOptions: { name: string; value: LiveCameraOption }[] = liveCameraOptionList.map(
  (option) => ({
    name: t(`public_tokens.live_options.${option}`),
    value: option,
  }),
);

const currentValue = computed<LiveCameraOption>(() =>
  props.publicToken.claims.includes("camera_live_image") ? "live_image" : "live_video",
);

const handleOptionChange = (option: LiveCameraOption) => {
  const updateTokenToUpdate: PublicTokenToUpdate = {
    _id: props.publicToken._id,
    camera_ids: props.publicToken.camera_ids,
    expires: props.publicToken.expires,
    claims: option === "live_image" ? ["camera_live_image"] : ["camera_live"],
  };
  if (isLoading.value || props.publicToken.claims[0] === updateTokenToUpdate.claims[0]) {
    return;
  }
  updatePublicToken(updateTokenToUpdate).catch(() => undefined);
};
</script>
